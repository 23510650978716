import React, {useState} from "react";
import ReusableTabs, {TabPanel} from "../Utils/Dialogs/ReusableTabs";


function EditTreatmentDialog({editTreatment, history}) {
    const tabHeading = ['Edit Treatment', 'History'];
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return(
        <ReusableTabs value={value} handleChange={handleChange} tabHeading={tabHeading}>
            <TabPanel value={value} index={0}>
                {editTreatment}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {history}
            </TabPanel>
        </ReusableTabs>
    )
}
export default EditTreatmentDialog;