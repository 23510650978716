import React, {useEffect, useState} from "react";
import TextField from "../Utils/FormInputs/TextField";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../actions/snackbarActions';
import SmsSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {DataCell} from "../Utils/Lists/CollapsibleList";
import {useViewPaymentDetails} from "./CustomHooks/useViewPaymentDetails";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages} from "../../Helpers/ErrorMessages";



const EditTreatment = ({actions, snackbar, treatmentDetails,setOpenDialog,isSubmitted,setIsSubmitted}) => {
    const [prescribed_drug, setPrescribedDrug] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [itemCost, setItemCost]  = useState({});
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;


    useEffect(() => {
        if (!treatmentDetails){
            return
        }

        setPrescribedDrug({...prescribed_drug, dosage:treatmentDetails.dosage, rate:treatmentDetails.rate,
            quantity_to_bill:treatmentDetails.quantity_to_bill_insurance, quantity_administered:treatmentDetails.quantity,
        quantity:treatmentDetails.quantity, comment:treatmentDetails.instructions,time: treatmentDetails.intake_time,
        route:treatmentDetails.route, product_name:treatmentDetails.product_name, date:treatmentDetails?.date_prescribed?.split('T')[0],
            quantity_prescribed:treatmentDetails.quantity_prescribed});
    }, [treatmentDetails]);


    useEffect(()=>{
        if (!treatmentDetails.product_id) {return;}
        const params = {visit_id:parseInt(treatmentDetails.visit_id), item_id:treatmentDetails.product_id, item_type:'product'};
        axios.post(`${config.smsUrl}/cbilling/item_cost`, params).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const actual_dt = dt.data ?? {};
            setItemCost(actual_dt);
        }).catch(err=>{
            errorMessages(err, null, actions)
        })
    },[treatmentDetails.product_id]);


    const handleChange = (event) => {
        const {name, value} = event.target;
        setPrescribedDrug({...prescribed_drug, [name]:value});

    };




    const handleSubmitTreatment = (event) =>{
        event.preventDefault();
        const arr = prescribed_drug.time  && prescribed_drug.quantity
            && (
                (prescribed_drug.quantity > 0) && (prescribed_drug.quantity <= (prescribed_drug.quantity_given - prescribed_drug.quantity_administered) )
            )

        setSubmitted(true)

        if (prescribed_drug.quantity){
            setIsSubmitted('pending');
            const params = {
                bill_id:treatmentDetails.bill_id,
                prescribed_by:user_roles_id,
                quantity:parseInt(prescribed_drug.quantity),
                quantity_to_bill_insurance: +prescribed_drug.quantity_to_bill,
                is_provided:+itemCost.is_provided,
                prescription_id:+treatmentDetails.prescription_id,
                prescription_detail_id:+treatmentDetails.prescription_detial_id

            }
            axios.post(`${config.smsUrl}/cmedicationround/update_treatment`, {...params}).then(()=>{
                actions.snackbarActions.snackSuccess('Treatment updated successfully')
                setIsSubmitted('resolved')
                setOpenDialog(false)
            }).catch(err=>{
                errorMessages(err, null,actions)
                setIsSubmitted('rejected')
            })
        }

    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }


    const {openBar, type, message} = snackbar;


    const {visitDetails} = useViewPaymentDetails(treatmentDetails.visit_id)


    return (
        <div className="table-card mt-4">
            <SmsSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
            {/*<Card className="general-card p-3">*/}
            <h6 className='text-center'>RECORD TREATMENT</h6>
            <form onSubmit={handleSubmitTreatment}>
                <div className='table-responsive'>
                    <table className=" table table-bordered table-sm profile-table treatment-table">
                        <thead>
                        <tr>
                            <td>Date</td>
                            <td width='200px'>Drug</td>
                            <td>Dosage</td>
                            <td>Route</td>
                            <td>Quantity prescribed</td>
                            <td>Quantity already administered</td>
                            {visitDetails?.is_on_insurance === 1 && itemCost.is_provided === 1 ?  <td>Quantity to bill</td>: null}
                            <td>Quantity to be administered</td>
                            <td>Time<span className='help-block'>*</span></td>
                            <td>Comments</td>
                            {/*<td/>*/}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <DataCell>
                                {prescribed_drug.date}
                            </DataCell>
                            <DataCell>
                                {prescribed_drug.product_name}
                            </DataCell>
                            <DataCell>
                                {prescribed_drug.dosage}
                            </DataCell>
                            <DataCell variant='150'>
                                {prescribed_drug.route}
                            </DataCell>
                            <DataCell>
                                {prescribed_drug.quantity_prescribed}
                            </DataCell>
                            <DataCell>
                                {prescribed_drug.quantity_administered}
                            </DataCell>
                            {visitDetails?.is_on_insurance === 1 && itemCost.is_provided === 1 ? <DataCell variant='150'>
                                <TextField type="number" value={prescribed_drug.quantity_to_bill} name='quantity_to_bill'
                                           onChange={e => handleChange(e)}/>
                            </DataCell>: null}
                            <DataCell variant='150'>
                                <TextField type="number" value={prescribed_drug.quantity} name='quantity'
                                           onChange={e => handleChange(e)}/>
                                {(submitted && (!prescribed_drug.quantity || prescribed_drug.quantity === 0)) &&
                                    <div className='help-block'>Quantity is required</div>}
                                {(submitted && ((prescribed_drug.quantity <= 0) || (prescribed_drug.quantity > (prescribed_drug.quantity_given - prescribed_drug.quantity_administered)))) ?
                                    <div className='help-block'>Quantity is more than available or equal to zero</div>
                                    : null}
                            </DataCell>
                            <DataCell variant='150'>
                                {prescribed_drug.time}
                            </DataCell>
                            <DataCell variant='150'>
                                {prescribed_drug.comment}
                            </DataCell>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <button type='submit' className='btn btn-sm sms-info-btn'
                        disabled={isSubmitted === 'pending'}>{isSubmitted === 'pending' ? 'updating...' : 'Update'}</button>
            </form>
            {/*</Card>*/}
        </div>
    );
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTreatment);
