/** @jsxImportSource @emotion/react */
import {jsx,css} from '@emotion/react'
import React, {useEffect, useState} from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate} from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import { titleCase } from '../../Users/addUser';
import Card from '@material-ui/core/Card';
import { history } from '../../../Helpers/history';
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import {InpatientBillRow, HeadCell, FooterCell, TotalCell} from "./InpatientBillRow";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {formatDate, formatDateTime} from "../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";

import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";
import {groupProductId, groupServiceId} from "../utils/SanitizeArr";
import {useCurrency} from "../../../Context/currency-context";
import {useVersionsContext} from "../../../Context/versions-context";
import {formatDigits, formatSsp} from "../../Utils/formatNumbers";


const textWeight = css`
    font-weight:600;
`



function ViewInpatientBillByReceipt(props) {
    const {visit_id, payment_id} = props.match.params;
    const {actions} = props
    const {currency:selectedCurrency} = useCurrency(props)



    const [receipt, setReceipt] = useState({});
    const [items, setItems] = useState([]);
    const [total_amount, setTotalAmount] = useState({currency_total:0, usd_total:0,
        currency_paid_amount:0,paid_amount: 0, due_amount: 0,currency_due_amount:0, deposit_used:0, currency_deposit_used:0, total_amount_paid:0,
    total_currency_amount_paid:0})
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)
    const [currency, setCurrency] = useState('')
    const [currencies, setCurrencies] = useState([])
    const [recordedCurrency, setRecordedCurrency] = useState({})
    const [services, setServices] = useState([])


    useEffect(()=>{
        if (recordedCurrency.currency_symbol){
            return
        }
        setCurrency(`${selectedCurrency?.currency_symbol}-${selectedCurrency?.is_base_currency}`)
    },[recordedCurrency.currency_symbol])

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.currency_symbol}-${item.is_base_currency}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/view_receipt_by_payment_id`, {params:{visit_id:+visit_id,payment_id:+payment_id}})
            .then(res => {
                const data = res.data;
                const all_data = !data ? {} : data;
                const info = !all_data.patient_info ? {} : all_data.patient_info;
                const bills = !all_data.bill ? [] : all_data.bill;
                const payment = all_data.payment_details ? all_data.payment_details : {}

                const exchange_rate = payment.currency_rate ? payment.currency_rate : 0;

                const ex_rate = exchange_rate ?  Math.pow(exchange_rate, -1) : 0;

                const symbol = payment.currency_symbol ? payment.currency_symbol : selectedCurrency?.currency_symbol
                const is_base = !(payment.is_base_currency == null || undefined) ? payment.is_base_currency : selectedCurrency?.is_base_currency

                setCurrency(`${symbol}-${is_base}`)
                setCurrencyExchangeRate(ex_rate)
                setReceipt({...info, ...payment});
                setRecordedCurrency(payment)

                convertList(exchange_rate, payment.amount_paid, payment.balance, bills, payment.deposit_top_up)
            }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, []);

    const totalCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.amount)
        }, 0)
    }

    const totalNonCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.currency_total)
        }, 0)
    }

    const convertList = (ex_rate,paidAmount,balance=0,arr=[], depositUsed=0) =>{
        const paid_amt =  +paidAmount / ex_rate
        const currency_deposit =  +depositUsed / ex_rate
        const currency_balance = ex_rate ?  balance / ex_rate :  balance

        const productArr = groupProductId(arr.filter(item=>item.product_id))
        const serviceArr = groupServiceId(arr.filter(item=>item.service_id))
        const invArr = [...serviceArr, ...productArr].map(item=>{
            const qty = item.quantity ? item.quantity : 0;
            const rate = item.rate ? item.rate : 0
            const total = rate * qty
            return {
                ...item,
                name: item.service_id ? item.service_name : item.product_id ? item.product_name : '',
                currency_rate: ex_rate ?  item.rate / ex_rate : item.rate,
                rate:item.rate,
                amount : total,
                currency_total: (ex_rate ?  item.rate / ex_rate : item.rate) *  (!item.quantity ? 0 : item.quantity),
            }})
            const formatedServices = invArr.map(item=>({
            ...item,
            rate: formatDigits(item.rate),
            currency_total: formatSsp(item.currency_total),
            amount: formatDigits(item.amount),
            currency_rate: formatSsp(item.currency_rate)
        }))

        const bedArr = arr.filter(bd => bd.bed_assignment_id).map(item=>{
            return {
                bill_id: item.bill_id,
                name: `${item.ward_name} -> ${item.room_name} -> ${item.bed_number}`,
                quantity: `${item.quantity} day${item.quantity > 1 ? 's': ''}`,
                rate: item.rate,
                bed_quantity:item.quantity,
                currency_rate: ex_rate ?  +item.rate / +ex_rate : item.rate,
                currency_total: ex_rate ? (+item.rate * +item.quantity) / +ex_rate : +item.rate * +item.quantity,
                amount: item.rate * +item.quantity,
            }
        })
        const formattedBeds = bedArr.map(item=>({
            ...item,
            rate: formatDigits(item.rate),
            currency_total: formatSsp(item.currency_total),
            amount: formatDigits(item.amount),
            currency_rate: formatSsp(item.currency_rate)
        }))
        const all_items = [{name:'Services and Medication', id:'services_and_medication', open:true, values:formatedServices},
            {name:'Bed Payment', id:'bed_payment',open:true, values:formattedBeds}]


        setItems(all_items)

        const t_amount = totalCurrency([...invArr, ...bedArr])
        const t_currency = totalNonCurrency([...invArr, ...bedArr])

        setServices([...invArr, ...bedArr])


        setTotalAmount({usd_total: t_amount, currency_total: t_currency,
            currency_paid_amount: paid_amt, currency_due_amount: currency_balance,
            paid_amount: paidAmount, due_amount: balance,deposit_used: depositUsed, currency_deposit_used: currency_deposit,
        total_amount_paid: +paidAmount + +depositUsed, total_currency_amount_paid: +currency_deposit + +paid_amt})

    }

    //Change currency
    const handleChangeCurrency = (e) =>{
        setCurrency(e.target.value)


    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setCurrencyExchangeRate(event.target.value)
        const currency_ex_rate = +event.target.value > 0 ?  1 / +event.target.value : 0
        const {paid_amount, due_amount, deposit_used} = total_amount
        convertList(+currency_ex_rate, +paid_amount, +due_amount , services, deposit_used)

    }


    const {currency_total, usd_total,paid_amount,due_amount,currency_due_amount, currency_paid_amount,
        deposit_used, currency_deposit_used, total_amount_paid, total_currency_amount_paid} = total_amount


    const isBaseCurrency = currency && currency.split('-')[1] === 'true'
    const currency_symbol = currency && currency.split('-')[0]

    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key: isBaseCurrency ? 'rate':'currency_rate',value:`Rate(${currency_symbol})`},
        {key: isBaseCurrency ? 'amount':'currency_total',value:`Amount(${currency_symbol})`}]

    const {componentRef, handlePrint,  fontSize}  = usePrint(`Cleared bill receipt`);


    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Admission Date: </span> <span css={[textWeight]}>{formatDateTime(receipt.date)}</span></td>
            <td><span>Patient Name: </span> <span
                css={[textWeight]}>{`${!receipt.patient_firstname ? "" : receipt.patient_firstname} 
                                ${!receipt.patient_lastname ? "" : receipt.patient_lastname}`}
                </span></td>

        </tr>
        <tr>
            <td><span>Patient No: </span> <span css={[textWeight]}>{receipt.patient_number}</span></td>
            <td><span>Admission No:</span> <span css={[textWeight]}>{receipt.patient_admission_id}</span></td>
        </tr>
        <tr>
            <td><span>Contact: </span> <span css={[textWeight]}>{receipt.phone_no}</span></td>
            <td><span>Address:</span> <span css={[textWeight]}>{receipt.address}</span></td>
        </tr>
        <tr>
            <td><span>Gender: </span> <span css={[textWeight]}>{receipt.gender}</span></td>
            <td><span>Cashier: </span>
                <span><strong>{`${receipt.first_name ? receipt.first_name : ''} ${receipt.last_name ? receipt.last_name : ''}`}</strong></span>
            </td>
        </tr>

        </tbody>
    )


    const itemDetails = (
        <TableContainer >
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {headData.map((item, index)=>(<HeadCell style={{width: index === 0 ? '500px':'300px'}} key={item.key}>{item.value}</HeadCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => (
                        <InpatientBillRow key={row.id} row={row} headData={headData}/>
                    ))}
                    <TableRow>

                        <FooterCell></FooterCell>
                        <FooterCell></FooterCell>
                        <FooterCell >Total Bill({currency_symbol})</FooterCell>
                        <FooterCell>{isBaseCurrency ?  formatDigits(usd_total) : formatSsp(currency_total)}</FooterCell>
                    </TableRow>

                    <TableRow>
                        <TotalCell></TotalCell>
                        <TotalCell></TotalCell>
                        <TotalCell >Deposit Used({currency_symbol})</TotalCell>
                        <TotalCell>{isBaseCurrency ? formatDigits(deposit_used) :  formatSsp(currency_deposit_used)}</TotalCell>
                    </TableRow>

                    <TableRow>
                        <TotalCell></TotalCell>
                        <TotalCell></TotalCell>
                        <TotalCell >Cash Received({currency_symbol})</TotalCell>
                        <TotalCell>{isBaseCurrency ? formatDigits(paid_amount) :  formatSsp(currency_paid_amount)}</TotalCell>
                    </TableRow>

                    <TableRow>

                        <TotalCell></TotalCell>
                        <TotalCell></TotalCell>
                        <TotalCell>Amount Paid({currency_symbol})</TotalCell>
                        <TotalCell>{isBaseCurrency ?  formatDigits(total_amount_paid) : formatSsp(total_currency_amount_paid)}</TotalCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )


    return (
        <div>
            <PageTitle title="Billing Receipt"/>
            <SubHeader title="Billing receipt" subTitle="Billing receipt">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <MiniCenteredDiv>
                <BackButton to='/inpatient-paid-bills-by-receipt' text='Cleared bills by receipt' data-testid="view-inpatient-bill-back-button"/>
                <RightAlignedContainer>
                    <Button variant='orange' onClick={handlePrint}>Print</Button>
                </RightAlignedContainer>
                <Card className='mt-2'>
                    {isPremium ? <div style={{padding:'8px 10px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                        <RightAlignedContainer>
                            <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />
                            {!isBaseCurrency &&
                                <>
                                    <FxRate currency={selectedCurrency?.currency_symbol} value={currencyExchangeRate}
                                            handleChangeRate={handleChangeExchangeRate} nonBaseCurrency={currency_symbol}/>
                                </>}
                        </RightAlignedContainer>
                    </div>: null}
                    <div ref={componentRef} style={{padding:10}}>
                        <style>
                            {fontSize()}
                        </style>
                        <PrintTemplate title='RECEIPT' {...{patientDetails,itemDetails}}/>
                    </div>
                </Card>
            </MiniCenteredDiv>
        </div>
    )
}

export default ViewInpatientBillByReceipt;