import React, {useEffect,  useState} from "react";
import axios from "axios";
import { config } from "../Helpers/env";



const VersionsContext = React.createContext()
VersionsContext.displayName = 'VersionsContext'


function VersionsProvider(props) {
    // const [subscriber, setSubscriber] = useState({})
    const [subscription, setSub] = useState({})

//     useEffect(()=>{
//     axios.get(`${config.smsUrl}/cuser/view_company_info`).then(res => {
//         const data = res.data;
//         const dt = data ? data : {};
//         setSubscriber(dt)
//     }).catch(err => {
//         const error = errorMessage(err)
//         setAlerts({type: 'alert-danger', message: error})
//     })
// },[])



useEffect(()=>{
    axios.get(`${config.smsUrl}/cuser/get_subscription`).then(res => {
        const data = res.data;
        const dt = data ? data : {};
        setSub(dt)
    }).catch(err => {
        // const error = errorMessage(err)
        // setAlerts({type: 'alert-danger', message: error})
    })
},[])


    const value = {subscription}
    return <VersionsContext.Provider value={value}  {...props}/>
}

function useVersionsContext() {
    const context = React.useContext(VersionsContext)
    if (context === undefined) {
        throw new Error(`useVersions must be used within a Version Provider`)
    }
    return context
}

export {VersionsProvider, useVersionsContext}









