import { useState } from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {
  errorMessages,
  logoutErrorMessage,
} from "../../../Helpers/ErrorMessages";
import { convDate, isValidDate } from "../../Utils/ConvertDate";

const useReceiveStock = (actions) => {
  const [invoice, setInvoice] = useState("");
  const [products, setProducts] = useState([
    {
      product_id: "",
      quantity: "",
      product_purchase_details_id: "",
      expiry_date: "",
      batch_id: "",
      received_stock:""
    },
  ]);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const [openDialog, setOpenDialog] = useState(false);
  const [search, setSearch] = useState(false);
  const [isSearch, setIsSearch] = useState("idle");
  const [product_purchase_id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isBlockDialog, setIsBlockDialog] = useState(false);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const userObj = !user ? {} : user;
  const token = !userObj.token ? [] : userObj.token;
  const _token = !token[1] ? {} : token[1];
  const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

  const handleChangeInvoice = (event) => {
    setInvoice(event.target.value);
    setIsBlocking(true);
  };

  const viewReceivedStock = () => {
    const formData = new FormData();
    formData.append("invoice_id", invoice);
    if (invoice) {
      setIsSearch("pending");
      axios
        .post(`${config.smsUrl}/cinventory/purchase_info_form`, formData)
        .then((res) => {
          const data = res.data;
          const all_data = !data ? {} : data;
          const prods = all_data.purchase_info ? all_data.purchase_info : [];
          const arr = prods.map((item) => ({
            ...item,
            expiry_date: item.expiry_date ? convDate(item.expiry_date) : "",
            received_stock: item.recieved_stock ? item.recieved_stock : "",
            batch_id: item.batch_id ? item.batch_id : "",
          }));
          const id = all_data.product_purchase_id
            ? all_data.product_purchase_id
            : "";
          const purchase_status = all_data.purchase_status
            ? all_data.purchase_status
            : "";
          setStatus(purchase_status);
          setProducts(arr);
          setIsSearch("resolved");
          setId(id);
        })
        .catch((e) => {
          errorMessages(e, null, actions);
          // actions.snackbarActions.snackError(e?.response?.data?.message)
          setIsSearch("rejected");
        });
    }
  };

  const handleSearchInvoice = () => {
    setSearch(true);
    viewReceivedStock();
  };

  const handleChangeproduct = (event, index) => {
    const { name, value } = event.target;
    const arr = products.map((item, idx) => {
      if (idx === index) {
        return { ...item, [name]: value };
      }
      return item;
    });

    setProducts(arr);
    setIsBlocking(true);
  };

  const handleSaveDraft = () => {
    const prods = products.map((item) => ({
      product_id: +item.product_id,
      quantity: +item.received_stock,
      product_purchase_details_id: +item.product_purchase_details_id,
      expiry_date: item.expiry_date,
      batch_id: item.batch_id,
    }));
    setIsSubmitted("pendingDraft");
    setIsBlocking(false);
    axios
      .post(`${config.smsUrl}/cpharmacy/receive_medicine_draft`, {
        products: prods,
        user_roles_id,
        invoice_no: invoice,
        product_purchase_id,
        to_destination: 1,
      })
      .then(() => {
        actions.snackbarActions.snackSuccess("Draft saved Successfully");
        setIsSubmitted("resolvedDraft");
        viewReceivedStock();
      })
      .catch((err) => {
        errorMessages(err, null, actions);
        setIsSubmitted("rejectedDraft");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    const arr = products.every(
      (item) =>
        item.product_id &&
        item.expiry_date &&
        isValidDate(item.expiry_date) &&
        +item.received_stock > 0 &&
        item.received_stock &&
        item.batch_id
    );
    const prods = products.map((item) => ({
      product_id: +item.product_id,
      quantity: +item.received_stock,
      product_purchase_details_id: +item.product_purchase_details_id,
      expiry_date: item.expiry_date,
      batch_id: item.batch_id,
    }));
    setIsBlocking(false);
    if (invoice && arr) {
      setIsSubmitted("pending");
      axios
        .post(`${config.smsUrl}/cpharmacy/receive_medicine`, {
          products: prods,
          user_roles_id,
          invoice_no: invoice,
          product_purchase_id,
          to_destination: 1,
        })
        .then(() => {
          setSubmitted(false);
          actions.snackbarActions.snackSuccess("Items received successfully");
          setOpenDialog(false);
          setIsSubmitted("resolved");
          setInvoice("");
          setProducts([]);
          setOpenConfirm(false);
        })
        .catch((err) => {
          setIsSubmitted("rejected");
          errorMessages(err, null, actions);
        });
    }
  };

  const handleOpenConfirm = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsBlocking(false);
    const arr = products.every(
      (item) =>
        item.product_id &&
        item.expiry_date &&
        isValidDate(item.expiry_date) &&
        +item.received_stock > 0 &&
        item.received_stock && +item.received_stock === +item.quantity &&
        item.batch_id
    );
    if (invoice && arr) {
      setOpenConfirm(true);
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const handleResetForm = () => {
    setIsBlockDialog(false);
    setIsBlocking(false);
    setOpenDialog(false);
    setSubmitted(false);
    setInvoice("");
    setProducts([]);
  };

  const isPending = isSubmitted === "pending";
  const isResolved = isSubmitted === "resolved";
  const isRejected = isSubmitted === "rejected";

  const isSearching = isSearch === "pending";
  const isSearchSuccess = isSearch === "resolved";
  const isSearchError = isSearch === "rejected";

  const isPendingDraft = isSubmitted === "pendingDraft";

  const stockProps = {
    products,
    submitted,
    handleSubmit,
    closeSnackbar,
    invoice,
    search,
    handleSearchInvoice,
    handleChangeInvoice,
    handleChangeproduct,
    isPending,
    isRejected,
    isResolved,
    isSearching,
    isSearchSuccess,
    isSearchError,
    status,
    isPendingDraft,
    handleSaveDraft,
    openConfirm,
    handleOpenConfirm,
    handleCloseConfirm,
    isBlockDialog,
    isBlocking,
    setIsBlockDialog,
    handleResetForm,
  };

  return { openDialog, setOpenDialog, isResolved, stockProps, setInvoice };
};

export { useReceiveStock };
