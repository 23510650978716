import React, {useEffect, useState} from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CustomTable, {CustomTablePagination, useHiddenColumns} from "../../Utils/Tables/CustomTable";
import {titleCase} from "../../Users/addUser";
import {dateConvert, formatDateTime} from "../../Utils/ConvertDate";
import {useTheme} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useVersionsContext} from "../../../Context/versions-context";
import {history} from "../../../Helpers/history";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {Subheading} from "./UnpaidBillingList";
import {DateFilter} from "../utils/DateFilter";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import * as billingActions from "../../../actions/BillingActions/BillingActions";
import {connect} from "react-redux";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import {BillingSubheading} from "../utils/BillingSubheading";

const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Bill Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'refunded_by', numeric: false, disablePadding: false, label: 'Refunded by', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false}
];

const RefundedBills = ({actions, snackbars}) => {
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [term, setTerm] = useState('');
    const [billingList, setBillingList] = useState([])
    const [status, setStatus] = useState('idle');
    const [total_count, setTotalCount] = useState(0);
    const [date, setDate] = useState({
        start_date: dateConvert(),
        end_date: dateConvert(),
    });

    const {start_date, end_date} = date


    useEffect(() => {
        const formData = new FormData();
        formData.append('search', term);
        formData.append('status', 1);
        formData.append('patient_type', 1);
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        setStatus('pending')
        axios.post(`${config.smsUrl}/cbilling/refund_list/${activePage}`, formData).then(res=>{
            const data = res.data;
            const bills = !data ? {} : data;
            const all_bills = !bills.bill_list ? [] : bills.bill_list;
            const count = !bills.total_count ? 0 : bills.total_count;
            setBillingList(all_bills);
            setTotalCount(count)
            setStatus('success')
        }).catch(err=>{
            logoutErrorMessage(err,null,actions)
            setStatus('error')
        })

    }, [activePage, term, rowsPerPage, start_date, end_date])

    const handleChangeDate = (event) => {
        const { name, value } = event.target;
        setDate({ ...date, [name]: value });
        setActivePage(1);
        setPage(0);
    };

    const handleChangeActivePage = (page) => {
        setActivePage(page)
    };

    const handler = (event) => {
        setTerm(event.target.value)
        setActivePage(1)
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const closeSnackBar = () => {
        actions.snackbarAction.hideSnackbar();
    };


    const isLoading =  status === 'pending';
    const isSuccess = status === 'success';
    const isError = status === 'erroe'

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const all_bills = billingList.map((bill, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        return {
            ...bill,
            count,
            date:formatDateTime(bill.begin_datetime),
            patient_name: titleCase(`${!bill.patient_firstname ? "" : bill.patient_firstname} 
             ${!bill.patient_lastname ? "" : bill.patient_lastname}`),
            refunded_by: `${!bill.refunded_by_first_name ? "" : bill.refunded_by_first_name} 
             ${!bill.refunded_by_last_name ? "" : bill.refunded_by_last_name}`,
            action: (
                <Link to={{pathname: `/refundreceipt/${bill.visit_id}/${activePage}/${bill.patient_number}`}}
                      style={{
                          textDecoration: "none",
                          color: palette.type === "dark" ? "#fff" : "#3d3d3d",
                          marginRight:'20px'
                      }}>
                    <button className="btn btn-sm sms-info-btn mr-1">View</button>
                </Link>
            )
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);


    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const {subscription} = useVersionsContext();
    const version = subscription?.version;

    const components = [
        {
            label: "Out-patient",
            path: "/refundedoutpatientbills",
            tier: ["Basic", "Standard", "Premium"],
        },
        {
            label: "In-patient",
            path: "/patientbillinglist",
            tier: ["Standard", "Premium"],
        },
        {label: "Invoiced Bills", path: "/invoicedbills", tier: ["Premium"]},
    ];

    const links = [
        {
            label:'Unpaid Bills',
            active:false,
            route:'/paymentList',
            onClick:()=> history.push('/paymentList')
        },
        {
            label:'Paid bills by visit',
            active:false,
            route:'/paidoutpatientbills',
            onClick:()=> history.push('/paidoutpatientbills')
        },
        {
            label:'Paid bills by receipt',
            active:false,
            route:'/outpatientreceipts',
            onClick:()=> history.push('/outpatientreceipts')
        },
        {
            label:'Refunded bills',
            active:true,
            route:'/refundedoutpatientbills',
            onClick:()=> history.push('/refundedoutpatientbills')
        }
    ]

    const { openBar, type, message } = snackbars;



    const obj = {
        file_name:'Refunded bills',
        params: {
            search: term,
            status: 2,
            patient_type: 1,
            start_date: start_date,
            end_date: end_date,
        },
        url: "/cbilling/refund_list/export"
    };

    const { exportExcel, isLoading: isLoadingExcel } = useExcelReports(
        actions,
        {...obj, params:{...obj.params,  is_pdf: "false"}}
    );
    const { exportPdf, isLoading: isLoadingPdf } = usePdfDownload(
        actions,
        {...obj, params:{...obj.params,  is_pdf: "true"}}
    );

    return (
        <div className="journals">
            <PageTitle title="Billing List"/>
            <SubHeader title="Billing" subTitle="Billing list">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <ReusableRouteTabs
                value={history.location.pathname}
                tabHeading={components}
                version={version}
            />
            <BillingSubheading links={links}/>
            <div className="mui-tables">
                <DateFilter
                    {...{
                        start_date,
                        end_date,
                        handleChangeDate,
                        isSubmit: true,
                    }}
                />
                <RightAlignedContainer>
                    <div className='mb-2'>
                        <DataExportButton
                            isLoading={isLoadingExcel || isLoadingPdf}
                            downLoadPdf={exportPdf}
                            exportCsv={exportExcel}
                        />
                    </div>
                </RightAlignedContainer>
                <MainSnackbar
                    handleCloseBar={closeSnackBar}
                    message={message}
                    open={openBar}
                    variant={type}
                />
            <CustomTable colSpan={headCells.length} data={all_bills}
                            headData={headCells} {...{all_hidden, handleAllHeadCells, handleHeadCells, pagination}}
                            activePage={activePage} handler={handler}
                            handleChangeNextPage={handleChangeActivePage} total_count={total_count}
                            records={10} customPage term={term}>
                <TableBody>
                    {isLoading ? <TableRow>
                        <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                            alt="loader"/></TableCell>
                        </TableRow> : null}
                    {isSuccess ?  all_bills.length > 0 ? all_bills.slice(0, 10)
                        .map((bill) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={bill.bill_id}
                                    className='mui-table-row'>
                                    {headCells.map((head)=>(
                                        <TableCell id={head.id}  key={head.id}
                                                   hidden={head.hidden}>{bill[head.id]}</TableCell>
                                    ))}
                                </TableRow>
                            );
                        }): <TableRow>
                        <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                    </TableRow>:null}
                    {isError ? <TableRow>
                        <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                    </TableRow>:null}
                </TableBody>
            </CustomTable>
            </div>
        </div>

    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar,
        billing: state.billing,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarAction: bindActionCreators(snackbarActions, dispatch),
            billingActions: bindActionCreators(billingActions, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundedBills);
