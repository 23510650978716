import React, { useEffect } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { Container } from "../../Utils/styledComponents";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import {
  CustomTableCell,
  MuiPaper,
  StickyHeadTable,
} from "../../Utils/Tables/StickyHeaderTable";
import { LoadingGif } from "../../Utils/Loader";
import PageTitle from "../../Utils/smsTitle";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import { ReusableRouteTabs } from "../../Utils/Dialogs/ReusableTabs";
import { history } from "../../../Helpers/history";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import { CustomYearPicker } from "../../MedicationandVisits/CustomTimePicker";
import TextField from "../../Utils/FormInputs/TextField";
import { DepreciateAssetDetails } from "./DepreciateAssetDetails";
import { CustomTablePagination } from "../../Utils/Tables/CustomTable";
import { extractDate } from "../../Utils/ConvertDate";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    minHeight: 0,
  },
  spacer: {
    flex: "1 1 100%",
  },
}));

const headData = [
  { id: "asset", numeric: false, disablePadding: false, label: "Asset#" },
  {
    id: "asset_name",
    numeric: false,
    disablePadding: false,
    label: "Asset name",
  },
  {
    id: "purchase_date",
    numeric: false,
    disablePadding: false,
    label: "Purchase Date",
  },
  {
    id: "asset_value",
    numeric: false,
    disablePadding: false,
    label: "Asset Value",
  },
  {
    id: "total_months",
    numeric: false,
    disablePadding: false,
    align: "center",
    label: "Month",
  },
  {
    id: "depreciation_rate",
    numeric: false,
    disablePadding: false,
    label: "Depreciation Rate",
  },
  {
    id: "monthly_expense",
    numeric: false,
    disablePadding: false,
    label: "Monthly Expense",
    align: "center",
  },
  {
    id: "annual_expense",
    numeric: false,
    disablePadding: false,
    label: "Annual Expense",
    align: "center",
  },
  {
    id: "current_value",
    numeric: false,
    disablePadding: false,
    label: "Current Value",
    align: "center",
  },
  {
    id: "accumulated_depreciation",
    numeric: false,
    disablePadding: false,
    label: "Accumulate Depr",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    align: "center",
  },
];
const isMonth = (date) => {
  const date_now = new Date().getMonth();
  const current_date = new Date(date).getMonth();
  return date_now === current_date;
};
const DepreciateFixedAssets = ({ actions, snackbars }) => {
  const [data, setData] = React.useState({
    assets: [],
    total_count: 0,
    loading: "idle",
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [activePage, setActivePage] = React.useState(1);
  const [openDepreciation, setOpenDepreciation] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [asset_id, setAssetId] = React.useState("");
  const [isDeprecate, setIsDeprecate] = React.useState("idle");
  const [assetDetails, setAssetDetails] = React.useState({});
  const [year, setYear] = React.useState(new Date());
  const [search, setSearch] = React.useState("");
  const [depreciation_status, setDepreciationStatus] = React.useState(null);
  const { assets, loading, total_count } = data;

  const isDepreciateResolved = isDeprecate === "resolved";

  useEffect(() => {
    setData({ ...data, loading: "pending" });
    const selected_year = year.getFullYear();
    axios
      .get(`${config.smsUrl}/cinventory/fixed_asset/schedule`, {
        params: {
          search,
          page: activePage,
          per_page: rowsPerPage,
          year: selected_year,
        },
      })
      .then((res) => {
        const response = res.data;
        const dt = response ? response : {};
        const arr = dt.data ? dt.data : [];
        const total = dt.total_assets ? dt.total_assets : 0;
        const dataArr = arr.map((item) => ({
          ...item,
          id: item.asset,
          action: (
            <button
              onClick={() => handleOpenViewDialog(item)}
              type="button"
              className="btn btn-sm sms-info-btn small-btn mr-3 mb-2"
            >
              View details
            </button>
          ),
        }));
        setData({ assets: dataArr, total_count: total, loading: "success" });
      })
      .catch((err) => {
        errorMessages(err, null, actions);
        setData({ ...data, loading: "error" });
      });
  }, [isDepreciateResolved, year, search, activePage, rowsPerPage]);

  // useEffect(() => {
  //   axios.get(`${config.smsUrl}/accounting/depreciation_status`).then(res=>{
  //     const response = res.data ? res.data : {}
  //     const dep_status = response.dep_status ? response.dep_status : null
  //     setDepreciationStatus(dep_status)
  //   })
  // }, []);

  useEffect(() => {
    axios
      .get(`${config.smsUrl}/accounting/settings/status`)
      .then((res) => {
        const data = res.data;
        const dt = data ?? {};
        const st = dt.message ?? {};
        const end_date = st.end_date ?? {};
        setDepreciationStatus(isMonth(end_date));
      })
      .catch((err) => {
        errorMessages(err, null, actions);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setActivePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setActivePage(1);
  };

  const handleOpenViewDialog = (item) => {
    setAssetDetails(item);
    setOpenView(true);
  };

  const handleCloseViewDialog = () => {
    setOpenView(false);
  };

  const handleOpenDepreciationDialog = () => {
    setOpenDepreciation(true);
  };

  const handleCloseDepreciationDialog = () => {
    setOpenDepreciation(false);
  };

  const handleDepreciateAsset = () => {
    setIsDeprecate("pending");
    axios
      .post(`${config.smsUrl}/cinventory/fixed_asset/depreciation/add`, {
        isDeprecate: true,
      })
      .then(() => {
        setIsDeprecate("resolved");
        actions.snackbarActions.snackSuccess("Asset depreciation recorded");
        setOpenDepreciation(false);
      })
      .catch((err) => {
        errorMessages(err, null, actions);
        setIsDeprecate("rejected");
      });
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const isLoading = loading === "pending";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  const { openBar, type, message } = snackbars;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, assets.length - page * rowsPerPage);

  const isDeprecatePending = isDeprecate === "pending";

  const months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const month = new Date(year).getMonth() + 1;

  const current_year = `${months[month]}-${new Date(year).getFullYear()}`;
  const buttons = (
    <>
      <button
        type="button"
        onClick={handleDepreciateAsset}
        disabled={isDeprecatePending}
        className="btn btn-sm sms-info-btn mr-4"
      >
        {isDeprecatePending ? "Saving..." : "Depreciate"}
      </button>
      <button
        type="button"
        onClick={handleCloseDepreciationDialog}
        className="btn btn-sm sms-gray-btn"
      >
        Cancel
      </button>
    </>
  );

  const components = [
    { label: "Fixed Asset List", path: "/accounting/fixedassets" },
    { label: "Depreciate Assets", path: "/accounting/depreciateassets" },
  ];

  const classes = useToolbarStyles();

  const pagination = (
    <CustomTablePagination
      colSpan={3}
      activePage={activePage}
      page={page}
      rowsPerPage={rowsPerPage}
      count={total_count}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isTotalCount
    />
  );

  return (
    <div>
      <PageTitle title="Depreciate Fixed Assets" />
      <SubHeader title="Accounting" subTitle="Depreciate Fixed Assets">
        <FontAwesomeIcon icon={faClipboardList} />
      </SubHeader>

      <CustomDialog
        buttons={buttons}
        open={openDepreciation}
        title="Depreciate Asset"
        handleClose={handleCloseDepreciationDialog}
      >
        <p>
          You are about to depreciate these fixed assets for the period{" "}
          <strong>{current_year}</strong>. Would you like to continue?
        </p>
      </CustomDialog>

      <CustomDialog
        open={openView}
        title="View asset details"
        handleClose={handleCloseViewDialog}
        maxWidth="md"
      >
        <DepreciateAssetDetails {...{ details: assetDetails }} />
      </CustomDialog>
      <Container>
        <ReusableRouteTabs
          value={history.location.pathname}
          tabHeading={components}
        />
        <MainSnackbar
          open={openBar}
          message={message}
          variant={type}
          handleCloseBar={closeSnackbar}
        />
        <div className=" col-md-6 col-lg-6 col-sm-12 px-0">
          <Form.Group as={Row}>
            <Col xl={2} lg={2} md={2} sm={12} xs={12}>
              <Label name="Year" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <CustomYearPicker year={year} onChange={setYear} />
            </Col>
          </Form.Group>
        </div>
        <MuiPaper>
          <Toolbar className={clsx(classes.root)}>
            <div className=" col-md-4 col-lg-4 col-sm-12">
              <TextField
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                value={search}
                placeholder="Search asset..."
              />
            </div>
            <div className={classes.spacer}></div>
            <div className=" col-md-4 col-lg-4 col-sm-12">
              {depreciation_status ? (
                <button
                  type="button"
                  onClick={handleOpenDepreciationDialog}
                  className="btn btn-sm sms-info-btn"
                >
                  Depreciate assets
                </button>
              ) : null}
            </div>
          </Toolbar>
          <StickyHeadTable
            {...{
              width: "100%",
              page,
              rowsPerPage,
              handleChangePage,
              handleChangeRowsPerPage,
              data: assets,
              columns: headData.filter((item) => Boolean(item)),
              pagination,
            }}
          >
            {isLoading ? (
              <TableRow>
                <TableCell align="center" {...{ colSpan: headData.length }}>
                  <LoadingGif />
                </TableCell>
              </TableRow>
            ) : null}
            {isSuccess ? (
              assets.length > 0 ? (
                <>
                  {assets.map((item, index) => {
                    return (
                      <TableRow key={item.id}>
                        {headData.map((col) => (
                          <CustomTableCell key={col}>
                            {item[col.id]}
                            {/* console.log() */}
                          </CustomTableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 10 * emptyRows }}>
                      <TableCell colSpan={headData.length} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell align="center" {...{ colSpan: headData.length }}>
                    No records found
                  </TableCell>
                </TableRow>
              )
            ) : null}
            {isError ? (
              <TableRow>
                <TableCell align="center" {...{ colSpan: headData.length }}>
                  The server did not return a valid response
                </TableCell>
              </TableRow>
            ) : null}
          </StickyHeadTable>
        </MuiPaper>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return { snackbars: state.snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepreciateFixedAssets);
