import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SubHeader } from "../../../Containers/SubHeader";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { ReusableRouteTabs } from "../../Utils/Dialogs/ReusableTabs";
import { history } from "../../../Helpers/history";
import {useVersionsContext} from "../../../Context/versions-context";
import InpatientBillingListTable from "./InpatientBillingTable";
import {BillingSubheading} from "../utils/BillingSubheading";




/**
 * Component that renders the List of inpatient pending clearance
 * @param {*} param0
 * @returns
 */

const headData = [
    {
        id: "count",
        numeric: false,
        disablePadding: false,
        label: "Sl",
        hidden: false,
    },
    {
        id: "date",
        numeric: false,
        disablePadding: false,
        label: "Date",
        hidden: false,
    },
    {
        id: "patient_admission_id",
        numeric: false,
        disablePadding: false,
        label: "Admission#",
        hidden: false,
    },
    {
        id: "patient_name",
        numeric: false,
        disablePadding: false,
        label: "Patient Name",
        hidden: false,
    },
    {
        id: "phone_no",
        numeric: false,
        disablePadding: false,
        label: "Phone",
        hidden: false,
    },
    {
        id: "kin_phone_no",
        numeric: false,
        disablePadding: false,
        label: "NOK Phone",
        hidden: false,
    },
    {
        id: "action",
        numeric: false,
        disablePadding: true,
        label: "Action",
        hidden: false,
    },
];



const PendingClearance = (props) => {


    const {subscription} = useVersionsContext();
    const version = subscription?.version;

    const components = [
        { label: "Out-patient", path: "/paymentList",  tier: ["Basic", "Standard", "Premium"] },
        { label: "In-patient", path: "/inpatient-pending-clearance", tier: ["Standard", "Premium"] },
        { label: "Invoiced Bills", path: "/invoicedbills",  tier: ["Premium"] },
    ];

    const links = [
        {
            label:'Deposits',
            active:false,
            route:'/patientbillinglist',
            onClick:()=> history.push('/patientbillinglist')
        },
        {
            label:'Pending clearance',
            active:true,
            route:'/inpatient-pending-clearance',
            onClick:()=> history.push('/inpatient-pending-clearance')
        },
        {
            label:'Cleared bills by visit',
            active:false,
            route:'/inpatient-paid-bills-by-visit',
            onClick:()=> history.push('/inpatient-paid-bills-by-visit')
        },
        {
            label:'Cleared bills by receipt',
            active:false,
            route:'/inpatient-paid-bills-by-receipt',
            onClick:()=> history.push('/inpatient-paid-bills-by-receipt')
        },
    ]




    return (
        <div className="journals">
            <PageTitle title="Pending clearance"/>
            <SubHeader title="Pending clearance" subTitle="Pending clearance">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <ReusableRouteTabs
                value={history.location.pathname}
                tabHeading={components}
                version={version}
            />
            <BillingSubheading links={links}/>
            <div className="mui-tables">
                <InpatientBillingListTable {...{headData, payStatus:0, admissionStatus:2}}/>
            </div>
        </div>
    );
};


export default PendingClearance;
