/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import React, {useEffect, useState} from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import Card from "@material-ui/core/Card";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import { usePrint } from '../../Utils/Templates/usePrint';
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import { formatDateTime } from '../../Utils/ConvertDate';
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import { CurrencyToggle, FxRate } from '../../Utils/CurrencyToggle';
import {groupProductId, groupServiceId} from "../utils/SanitizeArr";
import {useCurrency} from "../../../Context/currency-context";
import {useVersionsContext} from "../../../Context/versions-context";
import {formatDigits, formatSsp} from "../../Utils/formatNumbers";


const textWeight = css`
    font-weight:600;
`


export function ViewBill({actions, snackbars,match}) {
    const {currency:selectedCurrency} = useCurrency({actions, snackbars,match})
    const {visit_id} = match.params
    const [totals, setTotals] = useState({paid_amount: 0, due_amount_total: 0,
        total_bill_amount:0, total_non_currency_amount:0})
    const [currencies, setCurrencies] = useState([])
    const [currency, setCurrency] = useState('')
    const [patientInfo, setPatientInfo] = useState({})
    const [fxRate, setFxRate] = useState(0)
    const [exchangeRate, setExchangeRate] = useState(0)
    const [recordedCurrency, setRecordedCurrency] = useState(null)
    const [bills, setBills] = useState([])

    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'

    useEffect(()=>{
        if (recordedCurrency){
            return
        }
        setCurrency(`${selectedCurrency?.currency_symbol}-${selectedCurrency?.is_base_currency}`)
    },[recordedCurrency])

    const calculateTotalAmount = (arr) => {
        const t_amount =totalCurrency(arr)
        const non_currency = totalNonCurrency(arr)
        const p_total = arr.reduce((sum, item) => {
            return +item.total_paid + +sum
        }, 0)

        const due_amt = t_amount - p_total
        setTotals({paid_amount: p_total, due_amount_total:due_amt,  total_bill_amount: t_amount,
            total_non_currency_amount:non_currency })
    }

    const convertList = (dataArr, ex_rate) =>{
        const arr = dataArr.map(item=>{
            const { quantity, rate} = item;
            return({...item,
                rate: rate,
                non_currency_rate: ex_rate ? rate / ex_rate : rate,
                total_mount: quantity * rate,
                non_currency_total: (ex_rate ? rate / ex_rate:rate) * quantity
            })

        })
        setBills(arr)
        calculateTotalAmount(arr)
    }

    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        formData.append('pay_status', 0)
        axios.post(`${config.smsUrl}/cbilling/bill_update_form`, formData)
            .then(res => {
                const data = res.data
                const dt = data ? data : {}
                const p_info =  dt.patient_info
                const is_base_obj = dt.base_currency ?? {}
                const bill =  dt.bill ? dt.bill : []
                const billObj = bill[0] ? bill[0] : {}
                const ex_rate = billObj.currency_rate ? billObj.currency_rate : 0
                const symbol = is_base_obj.currency_symbol ? is_base_obj.currency_symbol : selectedCurrency?.currency_symbol
                const is_base = !(is_base_obj.is_base_currency == null || undefined) ? is_base_obj.is_base_currency : selectedCurrency?.is_base_currency
                const rate = ex_rate > 0 ?  Math.pow(ex_rate, -1) : 0
                setFxRate(ex_rate)
                setExchangeRate(rate)
                setCurrency(`${symbol}-${is_base}`)
                setRecordedCurrency(symbol)
                convertList(bill, ex_rate)
                setPatientInfo(p_info)
            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });

    }, []);

      // get currencies
      useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.currency_symbol}-${item.is_base_currency}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

 
    const handleChangeCurrency = (e) =>{
       setCurrency(e.target.value)
       // setFxRate(0)

    }

    const totalCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + +item.total_mount)
        }, 0)
    }

    const totalNonCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + +item.non_currency_total)
        }, 0)
    }



    const handleChangeRate = (e) =>{
        const ex_rate = +e.target.value > 0  ?   1 / +e.target.value : 0
        setFxRate(ex_rate)
        setExchangeRate(e.target.value)
        convertList(bills,+ex_rate)
     }
        
    const {patient_firstname, patient_lastname, patient_number, begin_datetime} = patientInfo;
    const { total_bill_amount, total_non_currency_amount} = totals;
    const {componentRef, handlePrint, getPageMargins, fontSize}  = usePrint(`AllReceipts${patient_number}`)

    const isBaseCurrency = currency && currency.split('-')[1] === 'true'
    const currency_symbol = currency && currency.split('-')[0]

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key:'rate',value:`Rate(${currency_symbol})`}, {key:'amount',value:`Amount(${currency_symbol})`}]

        const patientDetails = (
            <tbody>
            <tr>
                <td><span>Date: </span> <span css={[textWeight]}>{formatDateTime(begin_datetime)}</span></td>
                <td><span>Patient Number: </span> <span css={[textWeight]}>{patient_number}</span></td>
            </tr>
            <tr>
            <td><span>Patient Name: </span> <span css={[textWeight]}>{`${!patient_firstname ? "" : patient_firstname} ${!patient_lastname ? "" : patient_lastname}` }
                </span> </td>
            <td><span>Patient Contact: </span> <span css={[textWeight]}>{}</span></td>
            </tr>
            <tr>    
                
                <td></td>
            </tr>

            </tbody>
        )

    const productArr = groupProductId(bills.filter(item=>item.product_id))
    const serviceArr = groupServiceId(bills.filter(item=>item.service_id))
      const itemDetails = (
        <ItemsTable {...{headData}}>
          <tbody>
          {[...productArr, ...serviceArr].map((item) => {
                return (
                    <tr key={item.bill_id}>
                        <td><span>{item.service_id ? item.service_name : item.product_id ? item.product_name : ''}</span></td>
                        <td><span>{item.quantity}</span></td>
                        <td><span>{isBaseCurrency  ?  formatDigits(Number(item.rate)) : formatSsp(Number(item.non_currency_rate))}</span></td>
                        <td><span>{isBaseCurrency ? formatDigits(Number(item.total_mount)) : formatSsp(Number(item.non_currency_total))}</span></td>
                    </tr>
                )
            })}
    
              <tr>
                  <td colSpan={headData.length - 1} align='right'><span css={[textWeight]}>Total Amount({currency_symbol})</span></td>
                  <td ><span css={[textWeight]}>{isBaseCurrency ?  formatDigits(Number(total_bill_amount)) : formatSsp((total_non_currency_amount))}</span></td>
              </tr>
            
          </tbody>
        </ItemsTable>
      )
    
    return (
        <div data-testid="billing-receipt">
            <PageTitle title="Receipts"/>
            <SubHeader title="Billing" subTitle="Bill details">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <MiniCenteredDiv>
                <BackButton to='/paymentList' text='Billing List'/>
                <RightAlignedContainer>

                <Button variant='orange' onClick={handlePrint}>Print</Button>
                </RightAlignedContainer>
                <Card className='mt-2 p-3'>
                    {isPremium ? <div style={{padding:'8px 30px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                        <RightAlignedContainer>
                            <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />
                            {!isBaseCurrency &&
                                <>
                                    <FxRate currency={selectedCurrency?.currency_symbol} value={exchangeRate}
                                            handleChangeRate={handleChangeRate} nonBaseCurrency={currency_symbol}/>
                                </>}
                        </RightAlignedContainer>
                    </div>: null}
                    <div ref={componentRef} className="requisition-receipt">
                        <style>
                            {getPageMargins()}
                            {fontSize()}
                        </style>
                        <PrintTemplate title='BILL DETAILS' {...{patientDetails,itemDetails}}/>
                    </div>
                </Card>
            </MiniCenteredDiv>
        </div>
    )

}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewBill);
