import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import React from "react";

export function DateFilter(props) {
    return (
        <form onSubmit={props?.handleSubmit} className='mt-3'>
            <Form.Group as={Row} style={{marginBottom:0}}>
                <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                    <Form.Group as={Row} style={{marginBottom:0}}>
                        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Label name='From'/>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                            <TextField type='date'
                                       onChange={props?.handleChangeDate}
                                       value={props?.start_date} name='start_date' data-testid = "start_date"/>
                        </Col>
                    </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                    <Form.Group as={Row} style={{marginBottom:0}}>
                        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Label name='To' type/>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>

                            <TextField type='date' value={props?.end_date} onChange={props?.handleChangeDate}
                                       name='end_date' data-testid = "end_date"/>
                        </Col>
                        {!props.isSubmit ? <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <button type='submit'
                                    className="btn sms-btn-outline btn-sm px-2 btn-sm mt-2">{props?.buttonText ? props.buttonText :'Filter'}</button>
                        </Col> : null}
                    </Form.Group>
                </Col>
            </Form.Group>
        </form>
    )
}