/** @jsxImportSource @emotion/react */
import * as React from "react"
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {detailsTable, table, tableBordered, textWeight} from "../../styles/tableStyles";
import {RightAlignedContainer} from "../Utils/styledComponents";
import {history} from "../../Helpers/history";

export function ViewMedicineReturn({patient_admission_id,actions, setOpenDialog,isDialog = false,treatment=[]}) {
    const [isSubmitted, setIsSubmitted] = React.useState('idle')
    const handleReturnMedicine = () => {
        const data = treatment.map(item=>({
            quantity_to_returned:item.quantity_to_be_returned,
            prescription_detail_id:item.prescription_detail.prescription_detial_id
        }))
        setIsSubmitted('pending')
        axios.post(`${config.smsUrl}/cpharmacy/medicine-return/patient-admission/${patient_admission_id}`, {items:data}).then(() => {
            actions.snackbarActions.snackSuccess('Remaining medicine balance sent to the pharmacy')
            setIsSubmitted('resolved')
            if (isDialog){
                setOpenDialog(false)
            }else {
                history.push('/patientroundlist')
            }
        }).catch(err => {
            setIsSubmitted('rejected')
            errorMessages(err, null, actions)
        })

    }
    const isPending = isSubmitted === 'pending'
    const buttons = (
        <div className='mt-4'>
            <button disabled={isPending} className='btn btn-sm sms-info-btn  mr-3' onClick={handleReturnMedicine}>{isPending ? 'Sending...' : 'Send to pharmacy'}</button>
            {isDialog ? <button className='btn btn-sm sms-gray-btn' onClick={() => setOpenDialog(false)}>Cancel</button>
            : null}
        </div>
    )
    const headData = [
        {key: "drug", value: "Drug"},
        {key: "dosage", value: "Dosage" },
        { key: "quantity_prescribed", value: `Quantity prescribed` },
        { key: "quantity_administered", value: `Quantity administered` },
        { key: "remaining_quantity", value: `Quantity remaining` },
    ];

    const message = "Please note that there are still medications that need to be returned to the pharmacy for this admission. Kindly ensure the medicines are all returned and received from the pharmacy before discharging the patient. Thank you!"
    const returnMessage = "The medicine has been sent to the pharmacy. Please wait until the pharmacist confirms they have received it before discharging the patient. Thank you!"

    const isReturned = treatment.every(item=>item?.medicine_return && item?.medicine_return?.status === 0)
    return (
        <div>

                <p style={{margin: '0 0 20px 0', lineHeight: 1.5}}>
                    {isReturned ? returnMessage : message}
                </p>
                <table css={[detailsTable, tableBordered, table]}>
                    <thead>
                    <tr>
                        {headData.map((item, index) => (
                            <th css={[textWeight]} key={index} className='table-items'>
                                <span><strong>{item.value}</strong></span></th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {treatment.map(item => {
                        return (
                            <tr key={item?.product?.product_id}>
                                <td>{item?.product?.product_name}</td>
                                <td>{item?.prescription_detail?.dosage}</td>
                                <td>{item?.prescription_detail?.quantity_prescribed}</td>
                                <td>{item?.total_quantity_administered}</td>
                                <td>{item?.quantity_to_be_returned}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            {!isReturned ? <RightAlignedContainer>
                    {buttons}
                </RightAlignedContainer> :  null}
        </div>
    )
}