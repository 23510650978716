/** @jsxImportSource @emotion/react */
import {jsx,css} from '@emotion/react'
import React, {useEffect, useState} from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate} from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import Card from '@material-ui/core/Card';
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import {formatDateTime} from "../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {detailsTable, tableBordered, table, thickBorder,} from "../../../styles/tableStyles";
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";
import {useCurrency} from "../../../Context/currency-context";
import {useVersionsContext} from "../../../Context/versions-context";
import {formatDigits, formatSsp} from "../../Utils/formatNumbers";


const textWeight = css`
    font-weight:600;
`



function DepositReceipt(props) {
    const {visit_id, payment_id} = props.match.params;
    const {actions} = props
    const {currency:selectedCurrency} = useCurrency(props)



    const [receipt, setReceipt] = useState({});
    const [total_amount, setTotalAmount] = useState({currency_paid_amount:0,paid_amount: 0})
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)
    const [currency, setCurrency] = useState('')
    const [currencies, setCurrencies] = useState([])
    const [recordedCurrency, setRecordedCurrency] = useState({})


    useEffect(()=>{
        if (recordedCurrency.currency_symbol){
            return
        }
        setCurrency(`${selectedCurrency?.currency_symbol}-${selectedCurrency?.is_base_currency}`)
    },[recordedCurrency.currency_symbol])

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.currency_symbol}-${item.is_base_currency}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/view_receipt_by_payment_id`, {params:{visit_id:+visit_id,payment_id:+payment_id}})
            .then(res => {
                const data = res.data;
                const all_data = !data ? {} : data;
                const info = !all_data.patient_info ? {} : all_data.patient_info;
                const bills = !all_data.bill ? [] : all_data.bill;
                const payment = all_data.payment_details ? all_data.payment_details : {}

                const exchange_rate = payment.currency_rate ? payment.currency_rate : 0;

                const ex_rate = exchange_rate ?  Math.pow(exchange_rate, -1) : 0;

                const symbol = payment.currency_symbol ? payment.currency_symbol : selectedCurrency?.currency_symbol
                const is_base = !(payment.is_base_currency == null || undefined) ? payment.is_base_currency : selectedCurrency?.is_base_currency

                setCurrency(`${symbol}-${is_base}`)
                setCurrencyExchangeRate(ex_rate)
                setReceipt({...info, ...payment});
                setRecordedCurrency(payment)

                calculatePaidAmount(exchange_rate, payment.amount_paid)
            }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, []);


    const calculatePaidAmount = (ex_rate,paidAmount) =>{
        const paid_amt =  paidAmount / ex_rate
        setTotalAmount({currency_paid_amount: paid_amt, paid_amount: paidAmount})

    }

    //Change currency
    const handleChangeCurrency = (e) =>{
        setCurrency(e.target.value)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setCurrencyExchangeRate(event.target.value)
        const currency_ex_rate = +event.target.value > 0 ?  1 / +event.target.value : 0
        const {paid_amount, due_amount} = total_amount
        calculatePaidAmount(+currency_ex_rate, +paid_amount)

    }

    const {paid_amount, currency_paid_amount} = total_amount

    const isBaseCurrency = currency && currency.split('-')[1] === 'true'
    const currency_symbol = currency && currency.split('-')[0]

    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'


    const {componentRef, handlePrint,  fontSize}  = usePrint(`Cleared bill receipt`);


    const patientDetails = (
        <table css={{table,detailsTable}}>
            <tbody>
            <tr>
                <td><span>Admission Date: </span> <span css={[textWeight]}>{formatDateTime(receipt.date)}</span></td>
            </tr>
            <tr>
                <td><span>Patient Name: </span> <span
                    css={[textWeight]}>{`${!receipt.patient_firstname ? "" : receipt.patient_firstname} 
                                ${!receipt.patient_lastname ? "" : receipt.patient_lastname}`}
                </span></td>
            </tr>
            <tr>
                <td><span>Gender: </span> <span css={[textWeight]}>{receipt.gender}</span></td>
            </tr>
            <tr>
                <td><span>Contact: </span> <span css={[textWeight]}>{receipt.phone_no}</span></td>
            </tr>
            </tbody>
        </table>
    )

    const depositDetails = (
        <table css={[table, detailsTable, thickBorder]}>
            <tr>
                <td><span>Date</span></td>
                <td css={[textWeight]}><span>{receipt.date ? formatDateTime(receipt.date) : ''}</span></td>
            </tr>
            <tr>
                <td><span>Amount Deposited</span></td>
                <td css={[textWeight]}><span>{isBaseCurrency ? `${receipt.currency_symbol} ${formatDigits(paid_amount)} `:  `${receipt.currency_symbol} ${formatSsp(currency_paid_amount)}`}</span></td>
            </tr>
            <tr>
                <td><span>Cashier</span></td>
                <td css={[textWeight]}><span>{`${receipt.first_name ? receipt.first_name : ''} ${receipt.last_name ? receipt.last_name : ''}`}</span></td>
            </tr>

        </table>
    )


    const itemDetails = (
         <table css={[table, detailsTable]}>
             <tbody>
                <tr>
                    <td>
                        {patientDetails}
                    </td>
                    <td>
                        {depositDetails}
                    </td>
                </tr>
             </tbody>
         </table>
    )


    return (
        <div>
            <PageTitle title="Deposit slip"/>
            <SubHeader title="Deposit slip" subTitle="Deposit slip">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <MiniCenteredDiv>
                <BackButton to='/patientbillinglist' text='Deposits' data-testid="view-inpatient-bill-back-button"/>
                <RightAlignedContainer>
                    <Button variant='orange' onClick={handlePrint}>Print</Button>
                </RightAlignedContainer>
                <Card className='mt-2'>
                    {isPremium ? <div style={{padding:'8px 10px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                        <RightAlignedContainer>
                            <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />
                            {!isBaseCurrency &&
                                <>
                                    <FxRate currency={selectedCurrency?.currency_symbol} value={currencyExchangeRate}
                                            handleChangeRate={handleChangeExchangeRate} nonBaseCurrency={currency_symbol}/>
                                </>}
                        </RightAlignedContainer>
                    </div>: null}
                    <div ref={componentRef} style={{padding:10}}>
                        <style>
                            {fontSize()}
                        </style>
                        <PrintTemplate title='Deposit Slip' {...{itemDetails}}/>
                    </div>
                </Card>
            </MiniCenteredDiv>
        </div>
    )
}

export default DepositReceipt;