import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {
  errorMessages,
  logoutErrorMessage,
} from "../../../../Helpers/ErrorMessages";
import { exportPDF } from "../../../Utils/downloadPDF";
import { dateStringConvert } from "../../../Utils/ConvertDate";
import { history } from "../../../../Helpers/history";
import { dateConvert } from "../../../Utils/ConvertDate";

export const usePurchaseList = (props, isDraftPurchase = false) => {
  const [state, setState] = useState({
    start_date: dateConvert(),
    end_date: dateConvert(),
  });
  const { start_date, end_date } = state;

  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("sl");
  const [product_purchase_id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [purchases, setList] = useState([]);
  const [draft, setDraft] = useState({});
  const [openDelete, setDelete] = useState(false);
  const [openReverse, setOpenReverse] = useState(false);
  const [isDeleted, setIsDeleted] = useState("idle");
  const [activePage, setActivePage] = useState(1);
  const [total_count, setCount] = useState(0);
  const [allRecords, setAllRecords] = useState([]);
  const [draftDialog, setDraftDialog] = useState(false);
  const [productPurchase, setProductPurchase] = useState({
    supplierDetails: {},
    products: [],
  });
  const [loadingPurchase, setLoadingPurchase] = useState("idle");
  const csvRef = useRef();
  const { actions, status } = props;

  const isDeleteSuccess = isDeleted === "success";

  useEffect(() => {
    if (isDraftPurchase) {
      return;
    }
    const formData = new FormData();
    formData.append("draw", 1);
    formData.append("columns[0][data]", "sl");
    formData.append("columns[0][name]", "");
    formData.append("columns[0][searchable]", true);
    formData.append("columns[0][orderable]", false);
    formData.append("columns[0][search][value]", "");
    formData.append("columns[0][search][regex]", false);
    formData.append("columns[1][data]", "chalan_no");
    formData.append("columns[1][name]", "");
    formData.append("columns[1][searchable]", true);
    formData.append("columns[1][orderable]", true);
    formData.append("columns[1][search][value]", "");
    formData.append("columns[1][search][regex]", false);
    formData.append("columns[2][data]", "purchase_id");
    formData.append("columns[2][name]", "");
    formData.append("columns[2][searchable]", true);
    formData.append("columns[2][orderable]", false);
    formData.append("columns[2][search][value]", "");
    formData.append("columns[2][search][regex]", false);
    formData.append("columns[3][data]", "manufacturer_name");
    formData.append("columns[3][name]", "");
    formData.append("columns[3][searchable]", true);
    formData.append("columns[3][orderable]", false);
    formData.append("columns[3][search][value]", "");
    formData.append("columns[3][search][regex]", false);
    formData.append("columns[4][data]", "purchase_date");
    formData.append("columns[4][name]", "");
    formData.append("columns[4][searchable]", true);
    formData.append("columns[4][orderable]", false);
    formData.append("columns[4][search][value]", "");
    formData.append("columns[4][search][regex]", false);
    formData.append("columns[5][data]", "total_amount");
    formData.append("columns[5][name]", "");
    formData.append("columns[5][searchable]", true);
    formData.append("columns[5][orderable]", false);
    formData.append("columns[5][search][value]", "");
    formData.append("columns[5][search][regex]", false);
    formData.append("columns[6][data]", "button");
    formData.append("columns[6][name]", "");
    formData.append("columns[6][searchable]", true);
    formData.append("columns[6][orderable]", false);
    formData.append("columns[6][search][value]", "");
    formData.append("columns[6][search][regex]", false);
    formData.append("order[0][column]", 4);
    formData.append("order[0][dir]", "desc");
    formData.append("start", activePage);
    formData.append("length", 10);
    formData.append("search[value]", searchValue);
    formData.append("search[regex]", false);
    formData.append("fromdate", start_date);
    formData.append("todate", end_date);
    status.forEach((item) => {
      formData.append("status", item);
    });

    setLoading(true);
    axios
      .post(`${config.smsUrl}/cinventory/CheckPurchaseList`, formData)
      .then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        const pur = !dt.aaData ? [] : dt.aaData;
        let count = 0;
        if (searchValue) {
          count = !dt.iTotalDisplayRecords ? 0 : dt.iTotalDisplayRecords;
        } else {
          count = !dt.iTotalRecords ? 0 : dt.iTotalRecords;
        }
        setList(pur);
        setCount(count);
        setLoading(false);
      })
      .catch((err) => {
        logoutErrorMessage(err, null, actions);
        setLoading(false);
      });
  }, [searchValue, activePage, isDeleteSuccess, isDraftPurchase, state]);

  const handle_submit = () => {
    return;
  };

  useEffect(() => {
    if (!isDraftPurchase) {
      return;
    }
    const formData = new FormData();
    formData.append("search", searchValue);
    formData.append("page", activePage);
    setLoading(true);
    axios
      .get(`${config.smsUrl}/cinventory/get_all_draft_purchases`, {
        params: { search: searchValue, page: activePage },
      })
      .then((res) => {
        const data = res.data;
        const dt = !data ? [] : data;
        // let count = 0;
        // if (searchValue){
        //     count = !dt.iTotalDisplayRecords ? 0 : dt.iTotalDisplayRecords;
        // }else {
        //     count = !dt.iTotalRecords ? 0 : dt.iTotalRecords;
        // }
        setDraft(dt);
        setCount(dt.length);
        setLoading(false);
      })
      .catch((err) => {
        logoutErrorMessage(err, null, actions);
        setLoading(false);
      });
  }, [searchValue, activePage, isDraftPurchase]);

  const retrieveAllPurchases = () => {
    return axios.get(`${config.smsUrl}/cinventory/getallpurchases`);
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setActivePage(1);
  };

  // const handleDraft =()=>{
  //     // if(!product_purchase_id){
  //     //     return
  //     // }
  //     axios.get(`${config.smsUrl}/cinventory/retrieve_draft_purchases`).then(res =>{
  //         const data = res.data;
  //         const dt = !data ? {} : data;
  //         const prods = dt.purchase_info ?? [];
  //         const obj = prods[0] ? prods[0] : {};
  //         const details_obj = {...dt,supplier_name: dt.supplier_name, purchase_date: dt.purchase_date, invoice_no: dt.chalan_no,
  //             grand_total: obj.grand_total, isCurrency:dt.is_base_currency===false,
  //             currency_grand_total: obj.grand_currency_total, currency_id: obj.currency_id}

  //         const arr = prods.map(item=>({
  //             ...item,
  //             currency_rate: item.rate / item.currency_rate  ,
  //             usd_rate: item.rate,
  //             usd_total : item.total_amount,
  //             currency_total:(item.rate / item.currency_rate) * item.quantity

  //         }))
  //         setProductPurchase({supplierDetails:details_obj, products:arr})
  //         setLoadingPurchase('success')
  //     }).catch((err) => {
  //         logoutErrorMessage(err, null, actions)
  // })
  // }

  const handlePurchaseDraft = () => {
    const formData = new FormData();
    formData.append("invoice_no", invoice_no);
    axios
      .get(
        `${config.smsUrl}/cinventory/retrieve_draft_purchase_by_invoice_number`,
        formData
      )
      .then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        const prods = dt.purchase_info ?? [];
        const obj = prods[0] ? prods[0] : {};
        const details_obj = {
          ...dt,
          supplier_name: dt.supplier_name,
          purchase_date: dt.purchase_date,
          invoice_no: dt.chalan_no,
          grand_total: obj.grand_total,
          isCurrency: dt.is_base_currency === false,
          currency_grand_total: obj.grand_currency_total,
          currency_id: obj.currency_id,
        };

        const arr = prods.map((item) => ({
          ...item,
          currency_rate: item.rate / item.currency_rate,
          usd_rate: item.rate,
          usd_total: item.total_amount,
          currency_total: (item.rate / item.currency_rate) * item.quantity,
        }));
        setProductPurchase({ supplierDetails: details_obj, products: arr });
        setLoadingPurchase("success");
      })
      .catch((err) => {
        logoutErrorMessage(err, null, actions);
      });
  };

  const handleChangePage = (page) => {
    setActivePage(page);
  };

  const handleOpenDelete = (id) => {
    setDelete(true);
    setId(id);
    const formData = new FormData();
    formData.append("product_purchase_id", id);
    setLoadingPurchase("loading");
    axios
      .post(`${config.smsUrl}/cinventory/purchase_update_form`, formData)
      .then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        const prods = dt.purchase_info ?? [];
        const obj = prods[0] ? prods[0] : {};
        const details_obj = {
          ...dt,
          supplier_name: dt.supplier_name,
          purchase_date: dt.purchase_date,
          invoice_no: dt.chalan_no,
          grand_total: obj.grand_total,
          isCurrency: dt.is_base_currency === false,
          currency_grand_total: obj.grand_currency_total,
          currency_id: obj.currency_id,
        };

        const arr = prods.map((item) => ({
          ...item,
          currency_rate: item.rate / item.currency_rate,
          usd_rate: item.rate,
          usd_total: item.total_amount,
          currency_total: (item.rate / item.currency_rate) * item.quantity,
        }));
        setProductPurchase({ supplierDetails: details_obj, products: arr });
        setLoadingPurchase("success");
      })
      .catch((err) => {
        logoutErrorMessage(err, null, actions);
        setLoadingPurchase("error");
      });
  };

  const handleCloseDelete = () => {
    setDelete(false);
  };

  const handleDelete = (is_draft = false) => {
    const formData = new FormData();
    setIsDeleted("pending");
    formData.append("product_purchase_id", product_purchase_id);
    axios
      .post(`${config.smsUrl}/cinventory/delete_purchase`, formData)
      .then(() => {
        actions.snackbarActions.snackSuccess("Purchase deleted successfully");
        setIsDeleted("success");
        setDraftDialog(false);
        if (is_draft) {
          sessionStorage.setItem(
            product_purchase_id,
            JSON.stringify(productPurchase)
          );
          history.push(`/addpurchase/${product_purchase_id}/purchase_draft`);
        }
      })
      .catch((err) => {
        errorMessages(err, null, actions);
        setIsDeleted("error");
      });
  };

  const handleCancelPurchase = () => {
    handleDelete(true);
  };

  const handleCancelDraft = () => {
    handleDelete();
    handleCloseDelete();
  };

  const handleOpenDraftDialog = () => {
    setDraftDialog(true);
  };

  const handleCloseDraftDialog = () => {
    setDraftDialog(false);
  };

  const handleOpenReverse = (id) => {
    setOpenReverse(true);
    setId(id);
  };

  const handleCloseReverse = () => {
    setOpenReverse(false);
  };

  const handleReverse = () => {
    setIsDeleted("pending");
    axios
      .get(
        `${config.smsUrl}/cinventory/purchase/reversal/${product_purchase_id}`
      )
      .then(() => {
        actions.snackbarActions.snackSuccess("Purchase reversed successfully");
        setIsDeleted("success");
        setOpenReverse(false);
      })
      .catch((err) => {
        errorMessages(err, null, actions);
        setIsDeleted("error");
      });
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const downloadPdf = () => {
    const formData = new FormData();
    formData.append("search", searchValue);
    retrieveAllPurchases()
      .then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        const items = !dt.purchase_list ? [] : dt.purchase_list;
        exportPDF(
          "Purchases",
          [["SL", "Purchase Date", "Purchase ID", "Supplier", "Total Amount"]],
          items.map((item, index) => [
            (index += 1),
            dateStringConvert(item.purchase_date),
            item.product_purchase_id,
            item.supplier_name,
            item.total_amount,
          ]),
          "Purchases.pdf"
        );
        setAllRecords(items);
      })
      .catch((err) => {
        logoutErrorMessage(err, null, actions);
      });
  };

  const downloadCsv = () => {
    retrieveAllPurchases()
      .then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        const items = !dt.purchases ? [] : dt.purchases;
        setAllRecords(items);
        csvRef.current.link.click();
      })
      .catch((err) => {
        logoutErrorMessage(err, null, actions);
      });
  };
//   const isSubmit =false;
  const [submitted, setSubmitted] = useState(false);


  const isLoadingPurchase = loadingPurchase === "loading";
  const isSuccessPurchase = loadingPurchase === "success";
  const isErrorPurchase = loadingPurchase === "error";

  return {
    searchValue,
    order,
    orderBy,
    loading,
    purchases,
    openDelete,
    activePage,
    total_count,
    handleSearch,
    handleChangePage,
    handleOpenDelete,
    handleCloseDelete,
    handleDelete,
    downloadPdf,
    closeSnackbar,
    handleRequestSort,
    product_purchase_id,
    csvRef,
    allRecords,
    downloadCsv,
    openReverse,
    handleCloseReverse,
    handleOpenReverse,
    handleReverse,
    draftDialog,
    handlePurchaseDraft,
    productPurchase,
    isErrorPurchase,
    isLoadingPurchase,
    isSuccessPurchase,
    draft,
    handleOpenDraftDialog,
    handleCancelDraft,
    handleCloseDraftDialog,
    handleCancelPurchase,
    state,
    setState,
    submitted,
  };
};
