import {ItemsTable} from "../Utils/Templates/PrintTemplate";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages} from "../../Helpers/ErrorMessages";

export function MedicationHistory({treatmentDetails, actions}) {
    const [treatmentHistory, setTreatmentHistory] = useState([])

    useEffect(()=>{
        if (!treatmentDetails.bill_id) {return;}
        axios.get(`${config.smsUrl}/cmedicationround/get_treatment_history`, {params: {bill_id:parseInt(treatmentDetails.bill_id)}}).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const actual_dt = dt ?? [];
            setTreatmentHistory(actual_dt);
        }).catch(err=>{
            errorMessages(err, null, actions)
        })
    },[treatmentDetails.bill_id]);

    const headData = [{key:'date',value:'Date'},{key:'quantity_to_bill',value:`Quantity to bill`}, {key:'quantity',value:`Quantity`},
        {key:'recorded_by',value:`Recorded by`}]

    return(
        <div className='mt-3'>
            <ItemsTable {...{headData:headData.filter(item=>Boolean(item))}}>
                <tbody>
                {treatmentHistory.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <span>{item?.date}</span>
                            </td>
                            <td>
                                <span>{item.quantity_to_bill_insurance ? item.quantity_to_bill_insurance : 0}</span>
                            </td>
                            <td>
                                <span>{item.quantity}</span>
                            </td>
                            <td>
                                <span>{item.changed_by}</span>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </ItemsTable>
        </div>

    )
}
