import React, {useState} from "react";
import {dateConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import Alert from "@material-ui/lab/Alert";
import {RightAlignedContainer} from "../../Utils/styledComponents";

export function ExcelConsumptionData({actions, setOpenDialog}) {
    const [date, setDate] = useState({
        start_date: dateConvert(),
        end_date: dateConvert(),
    });
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState('idle')
    const { start_date, end_date } = date;

    const handleChangeDate = (event) => {
        const { name, value } = event.target;
        setDate({ ...date, [name]: value });
    };

    const handleConsumptionExcel = (event) => {
        event.preventDefault()
        setSubmitted(true)
        if (start_date && end_date){
            setIsSubmitting('pending')
            axios.get(`${config.smsUrl}/creport/out_of_stock/export`, {params:{start_date, end_date}, responseType: "blob" })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "consumption_data.xlsx"); // or whatever you want the file name to be
                    document.body.appendChild(link);
                    link.click();
                    setIsSubmitting('resolved')
                    setSubmitted(false)
                    setOpenDialog(false)
                }).catch((error) => {
                    errorMessages(error, null, actions)
                    setIsSubmitting('rejected')
                setSubmitted(false)
            });
        }
    };

    const isPending = isSubmitting === 'pending'

    return (
        <React.Fragment>
            {submitted && !(start_date && end_date) ?
                <div className='mb-3'>
                    <Alert severity='error'>Please select start and end date before exporting the data</Alert>
                </div>
                : null}
            <form onSubmit={handleConsumptionExcel}>
                {/*<Form.Group as={Row}>*/}
                {/*    <Col xs={12} sm={12} md={12} lg={5} xl={5}>*/}
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                        <Label name='From'/>
                    </Col>
                    <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                        <TextField type='date'
                                   onChange={handleChangeDate}
                                   value={start_date} name='start_date' data-testid="start_date"/>
                    </Col>
                </Form.Group>
                {/*</Col>*/}
                {/*<Col xs={12} sm={12} md={12} lg={5} xl={5}>*/}
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                        <Label name='To' type/>
                    </Col>
                    <Col xs={12} sm={12} md={10} lg={10} xl={10}>

                        <TextField type='date' value={end_date} onChange={handleChangeDate}
                                   name='end_date' data-testid="end_date"/>
                    </Col>
                </Form.Group>
                <RightAlignedContainer>
                    <button type='submit' disabled={isPending}
                            className="btn sms-btn-outline btn-sm px-2 btn-sm mt-2">
                        {isPending ? 'Exporting...' : 'Export data'}
                    </button>
                </RightAlignedContainer>

                {/*    </Col>*/}
                {/*</Form.Group>*/}
            </form>
        </React.Fragment>

    )
}
