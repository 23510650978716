/** @jsxImportSource @emotion/react */
import {jsx,css} from '@emotion/react'
import * as React from 'react'
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import * as colors from "../../../styles/colors";
import {detailsTable, table, thickBorder} from "../../../styles/tableStyles";
import {formatDateTime} from "../../Utils/ConvertDate";
import {formatDigits, formatSsp} from "../../Utils/formatNumbers";
import Card from "@material-ui/core/Card";



const textWeight = css`
    font-weight:600;
`

export function DepositsMade({open, setOpen, visit_id, actions}) {
    const [deposits, setDeposits] = React.useState([])

    React.useEffect(() => {
        if (!visit_id){
            return
        }
        axios.get(`${config.smsUrl}/cbilling/get_deposits_by_visit_id`, {params:{visit_id:+visit_id}})
            .then(res => {
                const data = res.data;
                const all_data = !data ? {} : data;
                const arr = !all_data.deposits ? [] : all_data.deposits;
                setDeposits(arr)
            }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, [visit_id]);

    return(
        <CustomDialog open={open} handleClose={()=>setOpen(false)} title='Deposits'>
            {deposits.map(receipt=> {
                const isBaseCurrency = receipt.is_base_currency === true
                return (
                <Card key={receipt.id} style={{padding: '10px', border: `1px solid ${colors.gray001}`, marginBottom:'1rem', borderRadius:'10px'}}>
                    <p style={{margin:'0 0 10px 0'}}>Deposit#   <strong>{receipt.id}</strong></p>
                    <table css={[table, detailsTable, thickBorder]}>
                        <tr>
                            <td><span>Date</span></td>
                            <td css={[textWeight]}><span>{receipt.date ? formatDateTime(receipt.date) : ''}</span></td>
                        </tr>
                        <tr>
                            <td><span>Amount Deposited</span></td>
                            <td css={[textWeight]}>
                                <span>{isBaseCurrency ? `${receipt.currency_symbol} ${formatDigits(receipt.amount_paid)} ` : `${receipt.currency_symbol} ${formatSsp(receipt.amount_paid)}`}</span>
                            </td>
                        </tr>
                        <tr>
                            <td><span>Cashier</span></td>
                            <td css={[textWeight]}>
                                <span>{`${receipt.employee_firstname ? receipt.employee_firstname : ''} ${receipt.employee_lastname ? receipt.employee_lastname : ''}`}</span>
                            </td>
                        </tr>

                    </table>
                </Card>
            )
            })}
        </CustomDialog>
    )
}