import React, { useEffect, useState } from "react";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "../../Utils/FormInputs/TextField";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import { connect } from "react-redux";
import SmsSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {
  CustomTableCell,
  MuiPaper,
  StickyHeadTable,
} from "../../Utils/Tables/StickyHeaderTable";
import { LoadingGif } from "../../Utils/Loader";
import { dateConvert, formatDate } from "../../Utils/ConvertDate";
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";
import { Container, RightAlignedContainer } from "../../Utils/styledComponents";
import { NewExpense } from "./NewExpense";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { CustomTablePagination } from "../../Utils/Tables/CustomTable";
import { Link } from "react-router-dom";
import { useCurrency } from "../../../Context/currency-context";
import { formatAmount, formatSsp } from "../../Utils/formatNumbers";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import {useToolbarStyles} from "../FixedAssets/FixedAssetList";
import {useDebounce} from "../../Lab/useDebounce";

const Expenses = ({ actions, snackbar }) => {
  const { currency } = useCurrency({ actions, snackbar });
  const [expenses, setExpenses] = useState([]);
  const [state, setState] = useState({
    start_date: dateConvert(),
    end_date: dateConvert(),
    search: "",
  });
  const [loading, setLoading] = useState("idle");
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const [isSubmitDate, setIsSubmitDate] = useState("idle");
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [expense_id, setExpenseId] = useState("");


  const { start_date, end_date, search } = state;
  const [debouncedValue] = useDebounce(search, 500)

  const isResolved = isSubmitted === "resolved";

  useEffect(() => {
    (async () => {
      setLoading("pending");
      try {
        const res = await axios.get(`${config.smsUrl}/accounting/expenses`, {
          params: {
            start_date,
            end_date,
            search:debouncedValue,
            page: page + 1,
            limit: rowsPerPage,
          },
        });
        let _data = res.data;
        let dt = _data.data ?? [];
        const arr = dt.map((item) => ({
          ...item,
          date: formatDate(item.expense_date),
          amount: item.amount_in_base.toFixed(3),
          amount_in_currency: item.amount_in_currency.toFixed(3),
          action: (
            <>
              <Link to={{ pathname: `/accounting/expensereceipt/${item.id}` }}>
                <button className="btn btn-sm small-btn sms-info-btn">
                  View
                </button>
              </Link>
              <button
                className="btn btn-sm small-btn sms-info-btn"
                onClick={() => handle_Edit(item.id)}
              >
                Edit
              </button>
              <Link
                to={{
                  pathname: `/accounting/expensereceipt_deleting/${item.id}/true`,
                }}
              >
                <button className="btn btn-sm small-btn btn-danger">
                  delete
                </button>
              </Link>
            </>
          ),
        }));
        setExpenses(arr);
        setTotal(_data.total);
        setLoading("success");
      } catch (e) {
        errorMessages(e, null, actions);
        setLoading("error");
      }
    })();
  }, [isResolved, page, rowsPerPage, debouncedValue]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (start_date && end_date) {
      setIsSubmitDate("pending");
      setLoading("pending");
      axios
        .get(`${config.smsUrl}/accounting/expenses`, {
          params: {
            start_date,
            end_date,
            search:debouncedValue,
            page: page + 1,
            limit: rowsPerPage,
          },
        })
        .then((res) => {
          let _data = res.data;
          let dt = _data.data ?? [];
          const arr = dt.map((item) => ({
            ...item,
            date: formatDate(item.expense_date),
            amount: formatAmount(`${item.amount_in_base}`),
            amount_in_currency: formatSsp(`${item.amount_in_currency}`),
            action: (
              <>
                <Link
                  to={{ pathname: `/accounting/expensereceipt/${item.id}` }}
                >
                  <button className="btn btn-sm small-btn sms-info-btn">
                    View
                  </button>
                </Link>
                <button
                  className="btn btn-sm small-btn sms-info-btn"
                  onClick={() => handle_Edit(item.id)}
                >
                  Edit
                </button>
                <Link
                  to={{
                    pathname: `/accounting/expensereceipt_deleting/${item.id}/true`,
                  }}
                >
                  <button className="btn btn-sm small-btn btn-danger">
                    delete
                  </button>
                </Link>
              </>
            ),
          }));
          setExpenses(arr);
          setIsSubmitDate("resolved");
          setLoading("success");
        })
        .catch((err) => {
          errorMessages(err, null, actions);
          setIsSubmitDate("rejected");
          setLoading("error");
        });
    }
  };

  const handle_Edit = (exp_id) => {
    setExpenseId(exp_id);
    setOpenDialog(true);
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const isPending = isSubmitDate === "pending";

  const isLoading = loading === "pending";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  const { openBar, type, message } = snackbar;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, expenses.length);

  const pagination = (
    <CustomTablePagination
      colSpan={3}
      page={page}
      rowsPerPage={rowsPerPage}
      count={total}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isTotalCount
    />
  );

  const headData = [
    { id: "date", numeric: false, disablePadding: false, label: "Date" },
    {
      id: "voucher_no",
      numeric: false,
      disablePadding: false,
      label: "Voucher No",
    },
    {
      id: "head_name",
      numeric: false,
      disablePadding: false,
      label: "Payment Account",
    },
    { id: "remarks", numeric: false, disablePadding: false, label: "Remarks" },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: `Amount(${currency.currency_symbol})`,
    },
    {
      id: "amount_in_currency",
      numeric: false,
      disablePadding: false,
      label: "Amount(SSP)",
    },
    { id: "action", numeric: false, disablePadding: false, label: "Action" },
  ];

  const classes = useToolbarStyles();

  return (
    <div className="journals">
      <SubHeader title="Accounting" subTitle="Expenses">
        <FontAwesomeIcon icon={faMoneyBill} />
      </SubHeader>
      <NewExpense
        {...{
          expense_id,
          isSubmitted,
          setIsSubmitted,
          openDialog,
          setOpenDialog,
          actions,
        }}
      />
      <Container>
        <RightAlignedContainer>
          <div className="my-2">
            <button
              className="btn btn-sm sms-info-btn"
              onClick={() => setOpenDialog(true)}
            >
              Record Expense
            </button>
          </div>
        </RightAlignedContainer>
        <SmsSnackbar
          variant={type}
          handleCloseBar={closeSnackbar}
          open={openBar}
          message={message}
        />
        <DateTimeFilter
          {...{
            start_date,
            end_date,
            handleChange,
            submitted,
            handleSubmit,
            isPending,
            type: "date",
          }}
        />
        <MuiPaper>
          <Toolbar className={clsx(classes.root)}>
            <div className=" col-md-4 col-lg-4 col-sm-12">
              <TextField
                  onChange={handleChange}
                  type="text"
                  value={search}
                  name="search"
                  id="searchValue"
                  placeholder="Search expenses..."
              />
            </div>
            <div className={classes.spacer}></div>
          </Toolbar>
          <StickyHeadTable
            {...{
              width: "100%",
              pagination,
              data: expenses,
              columns: headData.filter((item) => Boolean(item)),
            }}
          >
            {isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={headData.length}>
                  <LoadingGif />
                </TableCell>
              </TableRow>
            ) : null}
            {isSuccess ? (
              expenses.length > 0 ? (
                <>
                  {expenses.map((row) => (
                    <TableRow tabIndex={-1} key={row.expense_id}>
                      {headData.map((col) => (
                        <CustomTableCell key={col.id}>
                          {row[col.id]}
                        </CustomTableCell>
                      ))}
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 43 * emptyRows }}>
                      <TableCell colSpan={headData.length} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={headData.length}>
                    No records found
                  </TableCell>
                </TableRow>
              )
            ) : null}
            {isError ? (
              <TableRow>
                <TableCell align="center" colSpan={headData.length}>
                  The server did not return a valid response
                </TableCell>
              </TableRow>
            ) : null}
          </StickyHeadTable>
        </MuiPaper>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  const { snackbar } = state;
  return { snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);
