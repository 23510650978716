import React, {useEffect, useState} from "react";
import {useTheme} from "@material-ui/core/styles";
import {titleCase} from "../Users/addUser";
import {RightAlignedContainer } from "../Utils/styledComponents";
import { formatDateTime } from "../Utils/ConvertDate";
import { Paragraph2,Paragraph1, TitleContainer } from "../Utils/Menu/MiniSubHeader";
import axios from "axios";
import {config} from "../../Helpers/env";



const ReusablePrescriptionDetails = ({children, text, headData=[],state={}, visit_id, isFillPrescription=false}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {patient_id, patient_name, gender,age,address, phone, date, dispensed_by, dispensed_at} = state;

    const [visitDetails, setVisitDetails] = useState({})
    /**
     * Fetch payment details
     */
    useEffect(()=>{
        if(!visit_id){
            return
        }
        axios.get(`${config.smsUrl}/cvisit/${visit_id}`).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            setVisitDetails(dt)
        }).catch(err=>{
        })
    },[visit_id]);
    return (
        <div>
            <RightAlignedContainer>
                <TitleContainer>
                    <Paragraph1 variant='p1'>{text}</Paragraph1>
                    <Paragraph2 variant='p2'>{date ? formatDateTime(date) : ''}</Paragraph2>
                </TitleContainer>
            </RightAlignedContainer>
        <div className={`patient-information  ${palette.type === 'dark' && 'dark-patient-information'}`} style={{padding:'0 0 25px 0'}}>
                <h6 className='patient-personal-details'>PATIENT DETAILS</h6>
            <div className="row">
                <div className="col-md-4">
                    <p>Patient Number: <strong>{patient_id}</strong></p>
                    <p>Patient Name: <strong>{titleCase(patient_name)}</strong></p>
                    <p>Payment
                        Mode: <strong>{visitDetails.is_on_insurance === 1 ? 'CREDIT' : visitDetails.is_on_insurance === 0 ? 'CASH' : ''}</strong>
                    </p>
                </div>
                <div className="col-md-4">
                    <p>Age: <strong>{age}</strong></p>
                    <p>Gender: <strong>{titleCase(gender)}</strong></p>

                    {visitDetails.is_on_insurance === 1 ?
                        <p>Provider Name: <strong>{visitDetails?.insurance_provider_name}</strong></p> : null}
                </div>
                <div className="col-md-4">
                <p>Phone: <strong>{phone}</strong></p>
                    <p>Address: <strong>{titleCase(address)}</strong></p>
                    {visitDetails.is_on_insurance === 1 ?
                        <p>Provider Name: <strong>{visitDetails?.account_name}</strong></p> : null}
                </div>
            </div>

        </div>
            <table className={"table table-bordered fill-prescription-table"}>
                <thead>
                <tr>
                    {headData.map((item, index) => (
                        item.sub_headings && item.sub_headings.length > 0 ?
                            <th style={{padding: 0}}>
                                <tr style={{display: 'table', width: '100%'}}>
                                    <td width={item.width ? item.width : 100}
                                        className={palette.type === 'dark' && 'dark-theme-color'}
                                        colSpan={item.sub_headings.length} style={{textAlign: 'center'}}>
                                    {item.name}
                                        </td>
                                    </tr>
                                    <tr style={{display:'table', width:'100%'}}>
                                        {item.sub_headings.map((sub_head)=>(
                                            <th className={palette.type === 'dark' && 'dark-theme-color'}>{sub_head}</th>
                                        ))}
                                    </tr>
                                </th>
                                :
                                <td width={item.width ? item.width : 100} className={palette.type === 'dark' && 'dark-theme-color'} >{item.name}</td>
                        ))}
                    </tr>
                </thead>
                {children}
                
            </table>
        </div>
     
    )
};

export default ReusablePrescriptionDetails;
