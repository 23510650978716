import React, {useEffect, useState} from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {CustomTablePagination, EnhancedSearchTableToolbar} from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {useReusableInventory} from "./CustomHooks/useReusableInventory";
import {convertMonth, dateConvert} from "../../Utils/ConvertDate";
import { exportPDF } from "../../Utils/downloadPDF";
import {BackButton, DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import { formatDate } from "../../Utils/ConvertDate";
import {formatDigits} from "../../Utils/formatNumbers";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";
import {useCurrency} from "../../../Context/currency-context";
import {useDebounce} from "../../Lab/useDebounce";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {Link} from "react-router-dom";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import {LoadingGif} from "../../Utils/Loader";


const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'SN.'},
    {id: 'product_name', numeric: false, disablePadding: false, label: 'Product'},
    {id: 'batch_id', numeric: false, disablePadding: false, label: 'Batch#'},
    {id: 'expiry_date', numeric: false, disablePadding: false, label: 'Expiry Date'},
    {id: 'stock_quantity', numeric: false, disablePadding: false, label: 'Stock'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];

const ExpiredProducts = ({actions,snackbars}) => {
    const [date, setDate] = useState({start_date: dateConvert(), end_date: dateConvert()});
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState({expiredItems:[], loading:'idle', total:0});
    const [activePage, setActivePage] = useState(1)
    const [debouncedInputValue] = useDebounce(searchValue, 500)


    const {start_date, end_date} = date;

    const {loading, total, expiredItems} = data




    useEffect(() => {

        setData({...data, loading: 'pending'})
        axios.post(`${config.smsUrl}/creport/expired_drugs/${activePage}`, {
            search:debouncedInputValue,
            start_date: start_date,
            end_date: end_date,
            per_page:rowsPerPage
        }).then(res => {
            const data = res.data;
            const dt = !data.result ? {} : data.result;
            const arr = dt.expired ? dt.expired : [];
            const total_count = dt.count ? dt.count : 0;
            const items = arr.map(item=>({
                ...item,
                expiry_date:formatDate(item.expiry_date)
            }))
            setData({...data, loading: 'success', expiredItems: items, total: total_count})
        }).catch((err) => {
            setData({...data, loading: 'error'})
            logoutErrorMessage(err, null, actions);
        })
    }, [debouncedInputValue, activePage, rowsPerPage, start_date, end_date]);


    const handleChangeDate = (event) => {
        const {value, name} = event.target;
        setDate({...date, [name]: value})
    }


    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    const {openBar, type, message} =snackbars;

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={searchValue} handler={handleSearch}
        />
    )
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, expiredItems.length - page * rowsPerPage);


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,{file_name:`Expired Products`,
        url:`creport/expired_pdf_excel`, params:{download_type:1}})
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,{file_name:`Expired Products`,
        url:`creport/expired_pdf_excel`,params:{download_type:2}})

    return (
        <div className='journals' data-testid="number-of-lab-tests">
            <PageTitle title="Expired Drugs"/>
            <SubHeader title="Reports" subTitle="Expired Drugs">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            
            <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                <div style={{margin: '10px'}}>
                    <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
                </div>
                <div style={{margin: '10px'}}>
                    <DataExportButton downLoadPdf={exportPdf} isLoading={isLoadingExcel || isLoadingPdf} exportCsv={exportExcel}/>
                </div>
            </div>
            <div className="mui-tables mt-2">
            <DateTimeFilter
              {...{
                start_date,
                end_date,
                type: "date",
                isFilter: false,
                handleChange: handleChangeDate
              }}
            />

                {/*<RightAlignedContainer>*/}
                {/*    <div className='mb-2'>*/}
                {/*        <DataExportButton title='Purchase Report' downLoadPdf={exportPdf}*/}
                {/*                          exportCsv={exportExcel} isLoading={isLoadingExcel || isLoadingPdf}/>*/}
                {/*    </div>*/}

                {/*</RightAlignedContainer>*/}
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <MuiPaper>
                    {search_bar}
                    <StickyHeadTable {...{width:'100%',data:expiredItems, columns:headData.filter(item=>Boolean(item)), pagination}}>
                        {isLoading ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                        {isSuccess ? expiredItems.length > 0 ?
                            <>
                                {expiredItems.slice(0, rowsPerPage).map((row, index) => {
                                    const count = ((activePage - 1) * rowsPerPage) + index + 1;
                                    return (
                                        <TableRow  tabIndex={-1} key={index}>
                                            {headData.map(col=>(
                                                col.id === 'count' ? <CustomTableCell>{count}</CustomTableCell>:
                                                    <CustomTableCell>{row[col.id]}</CustomTableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 5 * emptyRows }}>
                                        <TableCell colSpan={headData.length} />
                                    </TableRow>
                                )}
                            </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>:null}
                        {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                    </StickyHeadTable>
                </MuiPaper>

            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpiredProducts);
