import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export const useOutPatientMedicalHistory = (actions, patient_number, current_visit_id='') =>{
    const options =  [{label:'Triage', value:'triage'},{label:' Clinical Notes', value:'notes'},
        {label:'Procedure', value:'procedure'},
        {label:'Laboratory Result(s)', value:'lab'},{label:'Radiology Report(s)', value:'radiology'},
        {label:'Diagnosis', value:'diagnosis'},{label:'Prescription', value:'prescription'}]

    const [patient, setPatient] = useState({});
    const [visits, setVisits] = useState([]);
    const [visitDetails, setVisitDetails] = useState({labResults:[],radiologyResults:[], patientDiagnosis:[],
    medication:[], clinicalNotes:[], patientTriage:[], visit_date:'', visit_type:'', visit_id:'',
        examination: {}, dental_services: []})

    const [openPrint, setOpenPrint] = useState(false);

    const [option, setOption] = useState([{label:'Triage', value:'triage'},{label:' Clinical Notes', value:'notes'},
        {label:'Diagnosis', value:'diagnosis'},{label:'Prescription', value:'prescription'}])

    const tabs = [{value:'Triage', component:'triage',isActive:true},{value:' Clinical Notes', component:'notes'},
        {value:'Procedure', component:'procedure'},
       {value:'Laboratory', component:'lab'},{value:'Radiology', component:'radiology'},
        {value:'Diagnosis', component:'diagnosis'},{value:'Prescription', component:'prescription'}]

    function sortArr(arr=[]) {
        return arr.sort((a, b)=>new Date(b.begin_datetime) - new Date(a.begin_datetime))
    }

    const retrieveVisitDetails = (v_id) =>{
        const formData = new FormData();
        formData.append('visit_id', v_id);
        axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            const {lab_result,scan_result, diagnosis,prescription,other_medicine,exam_notes,triage} = details
            const presc = prescription ? prescription : [];
            const others = other_medicine ? other_medicine : [];
            const procedure = details.procedure ? details.procedure : []
            const other_meds = others.filter(item=>Boolean(item));
            const medicationArr = [...presc, ...other_meds];
            const labArr = lab_result?.map(item=>({...item, open:true}))
            const scanArr = scan_result?.map(item=>({...item, open:true}))
            const arr = visits.map((item)=> {
                if (item.visit_id === v_id) {
                    return {
                        ...item, components: tabs, activeItem: 'triage', open: !item.open,
                        labResults: labArr,
                        radiologyResults: scanArr,
                        patientDiagnosis: diagnosis,
                        medication: medicationArr,
                        patientTriage: triage,
                        procedure,
                        clinicalNotes: exam_notes.map(note=>({
                            ...note, openNote:true
                        }))
                    }
                }
                return item
            })
            setVisits(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }
   
    useEffect(() => {
        if(!patient_number){
            return;
        }
        const formData = new FormData();
        formData.append('patient_number', patient_number);


        if (current_visit_id) {
            setOpenPrint(true)
            const formData = new FormData();
            formData.append('visit_id', current_visit_id);
            axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData).then(res => {

                const data = res.data;
                const details = !data ? {} : data;

                const {
                    lab_result,scan_result, 
                    diagnosis,prescription,
                    other_medicine,exam_notes,
                    triage,visit_detail,
                } = details

                const presc = prescription ? prescription : [];
                const others = other_medicine ? other_medicine : [];
                const other_meds = others.filter(item=>Boolean(item));
                const medicationArr = [...presc, ...other_meds];
                const labArr = lab_result?.map(item=>({...item, open:true}))
                const scanArr = scan_result?.map(item=>({...item, open:true}))
                const dental = details.dental_results ? details.dental_results : [];
                const dental_notes = details.exam_notes ? details.exam_notes : []
                const notes_obj = dental_notes[0] ? dental_notes[0] : {}
                const dental_exam = details.dental_exam ? details.dental_exam : {}
                const dental_diagnosis = diagnosis ? diagnosis : []
                const procedure = details.procedure ? details.procedure : []


                setVisitDetails({
                   ...visit_detail,
                   labResults: labArr,
                   radiologyResults: scanArr,
                   patientDiagnosis: diagnosis,
                   medication: medicationArr,
                   patientTriage: triage,
                    procedure,
                   clinicalNotes: exam_notes.map(note=>({
                       ...note, openNote:true
                   })),
                    dental_services: dental,
                    examination: {...notes_obj,extraOral:dental_exam.extra_oral,
                        softIntraOral:dental_exam.intra_oral_soft_tissue,
                        hardIntraOral:dental_exam.intra_oral_hard_tissue, ...dental_exam,
                        diagnosis:dental_diagnosis, medication:medicationArr,dental_exam},
                    visit_type : parseInt(visit_detail['visit_type']),
                    visit_date: visit_detail['begin_datetime'],
                    visit_id : parseInt(visit_detail['visit_id']),
               })
            }).catch(err => {
                logoutErrorMessage(err, null, actions)
            })
            setOption([{label:'Triage', value:'triage'},{label:' Clinical Notes', value:'notes'}, 
                {label:'Diagnosis', value:'diagnosis'},{label:'Prescription', value:'prescription'}])
        } else {
            axios.post(`${config.smsUrl}/cpatient/get_patient_previous_visits`, formData).then(res => {
                const data = res.data;
                const visit = !data ? {} : data;
                const visitsArr = !visit.patient_visits ? [] : visit.patient_visits;
                const sortedArr = sortArr(visitsArr)
                const singleVisit = sortedArr[0] ? sortedArr[0] :{};
                const v_id = singleVisit.visit_id ? singleVisit.visit_id : ''
                const formData = new FormData();
                formData.append('visit_id', v_id);

                axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData).then(res => {
                    const data = res.data;
                    const details = !data ? {} : data;
                    const {lab_result,scan_result, diagnosis,prescription,other_medicine,exam_notes,triage,visit_detail} = details
                    const presc = prescription ? prescription : [];
                    const others = other_medicine ? other_medicine : [];
                    const other_meds = others.filter(item=>Boolean(item));
                    const medicationArr = [...presc, ...other_meds];
                    const labArr = lab_result?.map(item=>({...item, open:true}))
                    const scanArr = scan_result?.map(item=>({...item, open:true}))
                    const procedure = details.procedure ? details.procedure : []
                    const arr = sortedArr.map((item, index)=>({
                        ...item,components:tabs,activeItem:'triage', open: index === 0,
                        labResults : labArr,
                        radiologyResults : scanArr,
                        patientDiagnosis : diagnosis,
                        medication : medicationArr,
                        patientTriage : triage,
                        procedure,
                        clinicalNotes :  exam_notes.map(note=>({
                            ...note, openNote:true
                        })),
                    }))
                    setVisits(arr)
                }).catch(err => {
                    logoutErrorMessage(err, null, actions)
                })
            }).catch(err => {
                logoutErrorMessage(err, null, actions)
            })
        }

    }, []);

    useEffect(() => {
        if(!patient_number){
            return;
        }
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            setPatient(details)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [patient_number]);


    const handleOpenResults = (visit_id,index,arr,item_name) =>{
        const visitArr = visits.map((item)=>{
            if (item.visit_id === visit_id){
                const openedArr = arr.map((child, idx)=>{
                    if(idx === index){
                        return {...child, open:!child.open}
                    }else {
                        return child
                    }
                })
                return {...item, [item_name]:openedArr}
            }
            return item
        })
        setVisits(visitArr)
    }

    const handleOpenVisit =  (v_id) => {
        retrieveVisitDetails(v_id)
    }

    const handleClickComponent = (index, item_name, v_id) => {
        const arr = visits.map(item=>{
            if(item.visit_id === v_id){
                return  {...item, activeItem:item_name, components: item?.components?.map((component, idx)=>{
                        if (idx === index){
                            return {...component, isActive:true}
                        }
                        return {...component, isActive:false}
                    })}
            }
            return item
        })
        setVisits(arr)
    }

    const handleOpenNote = (v_id,notes_id) => {
        const arr = visits.map(item=>{
            if(item.visit_id === v_id){
                return  {...item,  clinicalNotes: item?.clinicalNotes?.map((note, idx)=>{
                        if (note.examination_notes_id === notes_id){
                            console.log('Notes details',notes_id)
                            return {...note, openNote:!note.openNote}
                        }
                        return note
                    })}
            }
            return item

        })
        setVisits(arr)
    }

    const handleOpenPrintNote = (visit_id, notes_id) => {
        const obj = {...visitDetails,  clinicalNotes: visitDetails?.clinicalNotes?.map((note, idx)=>{
                if (note.examination_notes_id === notes_id){
                    return {...note, openNote:!note.openNote}
                }
                return note
            })
        }
        setVisitDetails(obj)
    }

    const handleOpenPrintResults = (visit_id,index,arr,item_name) =>{
        const openedArr = arr.map((child, idx)=>{
            if(idx === index){
                return {...child, open:!child.open}
            }else {
                return child
            }
        })
        const obj = {...visitDetails, [item_name]:openedArr}
        setVisitDetails(obj)
    }


    const handleOpenPrint = (item) =>{
        setOpenPrint(true)
        const formData = new FormData();
        formData.append('visit_id', item.visit_id);
        axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            const {lab_result,scan_result, diagnosis,prescription,other_medicine,exam_notes,triage} = details
            const presc = prescription ? prescription : [];
            const others = other_medicine ? other_medicine : [];
            const other_meds = others.filter(item=>Boolean(item));
            const medicationArr = [...presc, ...other_meds];
            const labArr = lab_result?.map(item=>({...item, open:true}))
            const scanArr = scan_result?.map(item=>({...item, open:true}))
            const dental = details.dental_results ? details.dental_results : [];
            const dental_notes = details.exam_notes ? details.exam_notes : []
            const notes_obj = dental_notes[0] ? dental_notes[0] : {}
            const dental_exam = details.dental_exam ? details.dental_exam : {}
            const dental_diagnosis = diagnosis ? diagnosis : []
            const procedure = details.procedure ? details.procedure : []
            setVisitDetails({
               ...visitDetails,
               labResults: labArr,
               radiologyResults: scanArr,
               patientDiagnosis: diagnosis,
               medication: medicationArr,
               patientTriage: triage,
                procedure,
               clinicalNotes: exam_notes.map(note=>({
                   ...note, openNote:true
               })),
                dental_services: dental,
                examination: {...notes_obj,extraOral:dental_exam.extra_oral,
                    softIntraOral:dental_exam.intra_oral_soft_tissue,
                    hardIntraOral:dental_exam.intra_oral_hard_tissue, ...dental_exam,
                    diagnosis:dental_diagnosis, medication:medicationArr, dental_exam},
                visit_date: item.begin_datetime,
                visit_type: item.patient_type,
                visit_id:item.visit_id

           })
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
        setOption([{label:'Triage', value:'triage'},{label:' Clinical Notes', value:'notes'},
            {label:'Diagnosis', value:'diagnosis'},{label:'Prescription', value:'prescription'}])
    }

    const handleClosePrint = () =>{
        setOpenPrint(false)
    }

    const handleOnAfterPrint = () => {
        setOpenPrint(false);
    }

    const handleChangeOption = (value) =>{
        setOption(value)
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    };

    const {first_name, last_name, Age, address, gender, phone_no} = patient

    const patientInfo = {patient_name:`${first_name} ${last_name}`, age:Age, gender, phone:phone_no, address, patient_number}

    const patientProps = {
        visits, patientInfo, handleOpenVisit, handleClickComponent,options, option, handleChangeOption,
        patient, handleOnAfterPrint, handleOpenNote,handleOpenPrintNote, handleOpenPrintResults,
        handleOpenResults, visitDetails, closeSnackbar, openPrint, handleOpenPrint, handleClosePrint
    }

    return {visits, patientInfo, handleOpenVisit, handleClickComponent,options, option, handleChangeOption,
        patient, handleOnAfterPrint, handleOpenNote,patientProps,handleOpenPrintNote, handleOpenPrintResults,
        handleOpenResults, visitDetails, closeSnackbar, openPrint, handleOpenPrint, handleClosePrint}
}