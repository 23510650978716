import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {useCurrency} from "../../../../Context/currency-context";

export const useRefundReceipt = (visit_id, active_page) => {
    const {currency:selectedCurrency} = useCurrency({visit_id, active_page})

    const [state, setState] = useState([]);
    const [totals, setTotals] = useState({amount_paid:0, currency_amount_paid:0, total_refund:0, currency_refund_amount:0})
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)
    const [currency, setCurrency] = useState('')
    const [currencies, setCurrencies] = useState([])
    const [receiptDetails, setReceiptDetails] = useState([])

    const [recordedCurrency, setRecordedCurrency] = useState({})
    const [isBaseCurrency, setIsBaseCurrency] = useState(null)

    useEffect(()=>{
        if (recordedCurrency.currency_symbol){
            return
        }
        setCurrency(`${selectedCurrency?.currency_symbol}-${selectedCurrency?.is_base_currency}-${selectedCurrency.id}`)
    },[recordedCurrency.currency_symbol])

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({...item, value:`${item.currency_symbol}-${item.is_base_currency}-${item.id}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            // logoutErrorMessage(err, null, actions)
        })
    }, []);

    const reduceAmounts = (arr = []) =>{
        return arr.reduce((a, item) => {
            return a + item.amount
        }, 0)
    }

    const calculateTotalAmount = (arr =[]) =>{
        //paid amount
        const usdAmountPaid = reduceAmounts(arr.map(item=>({...item, amount:item.usd_total})))
        const currencyAmountPaid = reduceAmounts(arr.map(item=>({...item, amount:item.currency_total})))

        //refund amount
        const usdRefundAmount = reduceAmounts(arr.map(item=>({...item, amount:item.usd_refund_amount})))
        const currencyRefundAmount = reduceAmounts(arr.map(item=>({...item, amount:item.currency_refund_amount})))

        setTotals({amount_paid: usdAmountPaid, currency_amount_paid: currencyAmountPaid, total_refund: usdRefundAmount,
        currency_refund_amount: currencyRefundAmount})
    }

    const convertList = (details, ex_rate, is_base_currency, isInitial=false) =>{
        const arr = details.map(item=>{
            const qty = item.quantity ? item.quantity : 0;
            const rate = item.rate ? item.rate : 0
            const total = rate * qty
            return {
                ...item,
                item_name: item.service_id ? item.service_name : item.product_id ? item.product_name : '',
                other_currency_rate: ex_rate ?  item.rate / ex_rate : item.rate,
                usd_rate:item.rate,
                usd_total : total,
                currency_total:( ex_rate ? item.rate / ex_rate : item.rate) * item.quantity,
                usd_refund_amount: is_base_currency ? item.refund_amount :  item.refund_amount * ex_rate,
                currency_refund_amount: (ex_rate && !isInitial) ?   ( ex_rate ? item.rate / ex_rate : item.rate) * item.quantity : item.refund_amount
            }})
        calculateTotalAmount(arr)
        setState(arr);
    }

    const currenciesFetched = currencies.length > 0


    useEffect(() => {
        if (currencies.length === 0){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cbilling/refund_list/contents/${active_page}`, formData).then(res => {
            const data = res.data;
            const details = !data ? [] : data;
            const obj = details[0] ? details[0] : {}
            const currency_id = obj.currency_id ? obj.currency_id : 0
            const exchange_rate = obj.currency_rate ? obj.currency_rate : 0
            const isCurrency = currencies.filter(item=>item.id === currency_id).reduce((prev, curr)=>({...prev, ...curr}),{})
            const symbol = isCurrency.currency_symbol ? isCurrency.currency_symbol : selectedCurrency?.currency_symbol
            const is_base = !(isCurrency.is_base_currency == null || undefined)  ? isCurrency.is_base_currency : selectedCurrency.is_base_currency
            const ex_rate = exchange_rate > 0 ?  Math.pow(exchange_rate, -1) : 0
            convertList(details, exchange_rate, is_base, true)
            setReceiptDetails(details)
            setCurrency(`${symbol}-${is_base}-${currency_id}`)
            setExchangeRate(exchange_rate)
            setCurrencyExchangeRate(ex_rate)
            setRecordedCurrency(isCurrency)
            setIsBaseCurrency(exchange_rate === 1 ? 'true' : 'false' )
        }).catch(err=>{
            logoutErrorMessage(err,null)
        })

    }, [visit_id, active_page, currenciesFetched]);

    //Change currency
    const handleChangeCurrency = (e) =>{
        setCurrency(e.target.value)
        // setExchangeRate(0)
        // setCurrencyExchangeRate(0)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        const currency_ex_rate = +event.target.value > 0  ?   1 / +event.target.value : 0
        setCurrencyExchangeRate(event.target.value)
        setExchangeRate(currency_ex_rate)
        convertList(receiptDetails, +currency_ex_rate, recordedCurrency.is_base_currency)
    }

    return {state,totals, handleChangeCurrency, exchangeRate,
    handleChangeExchangeRate, currency, currencies, currencyExchangeRate, selectedCurrency, isBaseCurrency}
}