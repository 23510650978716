import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import styled from "@emotion/styled/macro";
import * as colors from '../../../styles/colors'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {makeStyles,withStyles} from "@material-ui/core/styles";



const ListContainer = styled.div({
    // border:`0.78px solid ${colors.gray00}`,
    marginBottom:'10px'
})
// const ItemIcon = styled(ListItemIcon)({
//     minWidth:'5px'
// })

// const ItemIcon = withStyles((theme) => ({
//     root: {
//         minWidth:'5px'
//     },
// }))((props) => <ListItemIcon {...props} />);

// const StyledList = styled(List)({
//     paddingTop:0,
//     paddingBottom:0,
//
// })

// const ItemButton = withStyles((theme) => ({
//     root: {
//         '&::-webkit-tap-highlight-color':{
//             background: colors.base
//         },
//         ':hover':{
//             background:colors.base
//         },
//         paddingLeft:'6px',
//         paddingRight:'6px',
//         borderBottom:`1px solid ${colors.gray00}`,
//         marginBottom:'16px',
//         paddingBottom:0
//     },
// }))((props) => <ListItem {...props} />);

// const ItemButton = styled(ListItem)({
//     '&::-webkit-tap-highlight-color':{
//         background: colors.base
//     },
//     ':hover':{
//         background:colors.base
//     },
//     paddingLeft:'6px',
//     paddingRight:'6px',
//     borderBottom:`1px solid ${colors.gray00}`,
//     marginBottom:'16px',
//     paddingBottom:0
// })

// const ItemText = styled(ListItemText)({
//     '& .MuiTypography-root':{
//         fontSize:'14px',
//         fontWeight:700,
//     }
//
//
// })

const ItemText = withStyles((theme) => ({
    root: {
        '& .MuiTypography-root':{
            fontSize:'14px',
            fontWeight:700,
        }
    },
}))((props) => <ListItemText {...props} />);

const DateText = styled(ItemText)({
    display: 'flex',
    justifyContent:'right'
})

const StyledCell = styled(TableCell)({
    padding:'6px 13px',
    fontSize:'14px'
})

const HeadCell = styled(StyledCell)({
    borderTop:`0.78px solid ${colors.gray00}`,
    borderBottom:`0.78px solid ${colors.gray00}`,
    background:colors.gray001
})
const DataCell = styled(StyledCell)({
    borderBottom:0
})

const CollapseContainer = styled(Collapse)({
    '& .MuiCollapse-wrapper':{
        display:'inherit !important',
    }

})



const useStyles =  makeStyles(()=>({
    icon: {
        width: '.95rem',
        height: '.95rem'
    },
    list:{
        paddingTop:0, paddingBottom:0,
    },
    listItemButton:{
        '&::-webkit-tap-highlight-color':{
            background: colors.base
        },
        ':hover':{
            background:colors.base
        },
        paddingLeft:'6px',
        paddingRight:'6px',
        borderBottom:`1px solid ${colors.gray00}`,
        marginBottom:'16px',
        paddingBottom:0
    },
    itemText:{
        '& .MuiTypography-root':{
            fontSize:'14px',
            fontWeight:700,
        }
    },
    regularText:{
        '& .MuiTypography-root':{
            fontSize:'14px'
        }
    }
}));



function ModuleTable({children, headData=[]}) {

    return (
        <TableContainer>
            <Table aria-label="module table">
                <TableHead>
                    <TableRow>
                        {headData.map((item, index)=>(
                            <HeadCell key={index}>{item.value} </HeadCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </TableContainer>
    );
}



export function ItemDetails({requested_by, approved_by}) {
    const classes = useStyles()
    return (
        <React.Fragment>
            <ListItemText className={classes.regularText}>
                Requested by: <strong>{requested_by}</strong>
            </ListItemText>
            <ListItemText className={classes.regularText}>
                Radiologist: <strong>{approved_by}</strong>
            </ListItemText>
        </React.Fragment>
    )
}


function ReportList({handleOpen, children, test_name, open, item_details=null, items=null}) {
    const classes = useStyles()
    return (
        <ListContainer>
            <List
                sx={{ width: '100%'}}
                component="nav"
                aria-labelledby="collapsible-list"
                className={classes.list}
            >
                <ListItem onClick={handleOpen} button className={classes.listItemButton}>
                    <ListItemText className={classes.itemText}>
                        {test_name}<span>{open ?   <KeyboardArrowDownIcon className={classes.icon} /> :
                        <KeyboardArrowRightIcon className={classes.icon}/>}</span>
                    </ListItemText>
                    {item_details}
                    {/*<ItemIcon>*/}
                    {/*    */}
                    {/*</ItemIcon>*/}
                    {items}
                </ListItem>
                <CollapseContainer in={open} timeout="auto" unmountOnExit>
                     {children}
                </CollapseContainer>
            </List>
        </ListContainer>
    );
}
export {ReportList, DataCell, ItemText,  ModuleTable, DateText}
