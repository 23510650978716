import React from 'react'
import {Loader} from "./styledComponents"

function LoadingGif() {
  return (
    <Loader src="/images/smsloader.gif" alt='loader'/>
  )
}

function ListPreloader() {
  return (
      <Loader src="/images/listPreloader.gif" alt='loader'/>
  )
}


export {LoadingGif, ListPreloader}