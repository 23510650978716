/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { detailsTable, table } from "../../../styles/tableStyles";
import React, { Fragment } from "react";

const bigFont = css`
  font-size: 18px;
  border-bottom: 1px solid #000;
`;

const mediumFont = css`
  font-size: 16px;
`;
const padding = css`
  td,
  th {
    padding: 5px 0;
  }
`;

const bigMargin = css`
  margin-bottom: 25px;
`;

const title = css`
  color: #706e6e;
  font-weight: 700;
`;
const span = css`
  display: inline-block;
  margin-left: 8px;
  color: #5d5b5b;
  font-weight: 700;
`;

export function DepreciateAssetDetails({ details }) {
  return (
    <Fragment>
      <table css={[table, padding, bigMargin]}>
        <tbody>
          <tr>
            <td>
              <span>Asset Name</span>
            </td>
          </tr>
          <tr>
            <td css={[bigFont]}>
              <span>{`${details?.asset_name ?? ""} ${
                details?.asset ?? ""
              }`}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table css={[table, padding]}>
        <tbody>
          <tr>
            <td css={[mediumFont, title]}>
              <span>Asset Values</span>
            </td>
            <td css={[mediumFont, title]}>
              <span>Accounting</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>
                Original Value{" "}
                <span css={[span]}>{details?.asset_value ?? 0}</span>
              </span>
            </td>
            <td>
              {/* <span>Fixed Asset Account</span> */}

              <span>
                Fixed Asset Account{" "}
                <span css={[span]}>{details?.asset_account ?? ""}</span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>
                Purchase Date{" "}
                <span css={[span]}>{details?.purchase_date ?? ""}</span>
              </span>
            </td>
            <td>
              <span>
                Depreciation Account{" "}
                <span css={[span]}>{details?.depreciation_account ?? ""}</span>
              </span>
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <span>
                Expense Account{" "}
                <span css={[span]}>
                  {details?.depreciation_expense_account ?? ""}
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <table css={[table, padding]}>
        <tbody>
          <tr>
            <td css={[mediumFont, title]}>
              <span>Current Values</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>
                Depreciation Rate{" "}
                <span css={[span]}>{details?.depreciation_rate ?? 0}</span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>
                Months <span css={[span]}>{details?.total_months ?? 0}</span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>
                Monthly Depreciation{" "}
                <span css={[span]}>{details?.monthly_expense ?? 0}</span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>
                Yearly Depreciation{" "}
                <span css={[span]}>{details?.annual_expense ?? 0}</span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>
                Current Value{" "}
                <span css={[span]}>{details?.current_value ?? 0}</span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>
                Accumulated Depreciation{" "}
                <span css={[span]}>
                  {details?.accumulated_depreciation ?? 0}
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
}
