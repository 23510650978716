/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react'
import React, {useRef, useState} from "react";
import {usePreviousInpatientTreatment} from "./CustomHooks/usePreviousInpatientTreatment";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import {connect} from "react-redux";
import SmsSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {usePatientDetails} from "../DoctorsModule/ReferralNote/usePatientDetails";
import Card from '@material-ui/core/Card'
import {useViewPaymentDetails} from "./CustomHooks/useViewPaymentDetails";
import {ItemsTable, PrintTemplate} from "../Utils/Templates/PrintTemplate";
import {usePrint} from "../Utils/Templates/usePrint";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import EditTreatmentDialog from "./EditTreatmentDialog";
import {PatientInformation} from "../DoctorsModule/PatientInfo/PatientInformation";
import EditTreatmentForm from "./EditTreatmentForm";
import {MedicationHistory} from "./MedicationHistory";

const style = {
    border: {
        borderTop: '1px solid #606060',
        borderBottom: '1px solid #606060'
    },
    image: {
        width: 130,
        border: '1px solid #e4e4e4',
        borderRadius: '60px',
        background: '#e4e4e4'
    },
    p: {
        marginTop: '15px 0 0 0'
    },
    parent: {
        textAlign: 'center',
        marginTop: '.7em'
    }
}

const textWeight = css`
    font-weight:600;
`

export const NoTransaction = ({text}) => {
    return (
        <div style={style.parent}>
            <img src="/images/prescription.png" alt='treatment records' style={style.image}/>
            <p className="text-center" style={style.p}>{text}</p>
        </div>
    )
}


const TreatmentHistory = ({actions, snackbar, match}) => {
    const {patient_admission_id, patient_number, visit_id} = match
    const {treatmentHistory, loading, closeSnackbar, isSubmitted, setIsSubmitted} = usePreviousInpatientTreatment(actions, patient_admission_id)
    const printRef = useRef()
    const {openBar, message, type} = snackbar
    const {patient} = usePatientDetails(actions, patient_number, visit_id);
    const {visitDetails} = useViewPaymentDetails(visit_id)
    const [openDialog, setOpenDialog] = useState(false)
    const [treatmentDetails, setTreatmentDetails] = useState({})

    const headData = [{key:'date',value:'Date'}, {key:'drug',value:'Drug'},
        {key:'dosage',value:`Dosage`},{key:'route',value:`Route`}, visitDetails?.is_on_insurance === 1 ?
            {key:'quantity_to_bill',value:`Quantity to bill`}: null, {key:'quantity',value:`Quantity`},
        {key:'time',value:`Time`},{key:'comments',value:`Comments`},{key:'created_by',value:`Created by`},
        {key:'action', value:'Action', isPrint:true}]

    const {componentRef, handlePrint,fontSize}  = usePrint(`Treatment Chart`)


    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Patient Name: </span> <span
                css={[textWeight]}>{`${!patient.first_name ? "" : patient.first_name} 
                                ${!patient.last_name ? "" : patient.last_name}`}
                </span></td>
            <td><span>Gender: </span> <span css={[textWeight]}>{patient.gender}</span></td>


        </tr>
        <tr>
            <td><span>Phone: </span> <span><strong>{patient?.phone_no}</strong></span></td>
            <td><span>Address: </span> <span css={[textWeight]}>{patient.address}</span></td>
        </tr>
        <tr>
            <td><span>Payment Mode: </span> <span
                css={[textWeight]}>{visitDetails.is_on_insurance === 1 ? 'CREDIT' : visitDetails.is_on_insurance === 0 ? 'CASH' : ''}</span>
            </td>
            {visitDetails.is_on_insurance === 1 ?
                <td><span>Provider Name:</span> <span css={[textWeight]}>{visitDetails?.insurance_provider_name}</span>
                </td> : null}
        </tr>
        <tr>
            {visitDetails.is_on_insurance === 1 ?
                <td><span>Card Holder:</span> <span css={[textWeight]}>{visitDetails?.account_name}</span>
                </td> : null}
            <td/>
        </tr>
        </tbody>
    )

    const itemDetails = (
        <ItemsTable {...{headData:headData.filter(item=>Boolean(item))}}>
            <tbody>
            {treatmentHistory.map((item) => {
                return (
                    <tr key={item.patient_medication_detail_id}>
                        <td>
                            <span>{item?.date_prescribed?.split('T').slice(0, 1).join('')}</span>
                        </td>
                        <td>
                            <span>{item.product_name}</span>
                        </td>
                        <td>
                            <span>{item.dosage}</span>
                        </td>
                        <td>
                            <span>{item.route}</span>
                        </td>
                        {visitDetails?.is_on_insurance === 1 ? <td>
                            <span>{item.quantity_to_bill_insurance}</span>
                        </td> : null}
                        <td>
                            <span>{item.quantity}</span>
                        </td>
                        <td>
                            <span>{item.intake_time}</span>
                        </td>
                        <td>
                            <span>{item.instructions ? item.instructions : ''}</span>
                        </td>
                        <td>
                            <span>{item.first_name} {item.last_name}</span>
                        </td>
                        <td className="print_button">
                            <button onClick={()=>{
                                setOpenDialog(true)
                                setTreatmentDetails(item)
                            }} className='btn btn-sm small-btn sms-info-btn px-3'>Edit</button>
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </ItemsTable>
    )
    return (
        <div className='mt-4'>
            <CustomDialog open={openDialog} handleClose={()=>setOpenDialog(false)} title='Edit treatment' maxWidth='lg'>
                <PatientInformation title='PATIENT DETAILS' style={{padding:'5px 0'}} {...{patient_number, visit_id}}/>
                <EditTreatmentDialog
                    editTreatment={<EditTreatmentForm {...{setOpenDialog, treatmentDetails, isSubmitted, setIsSubmitted}}/>}
                    history={<MedicationHistory {...{treatmentDetails, actions}}/>}
                />
            </CustomDialog>
            <SmsSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
            {loading === 'pending' ? <div>Loading data....</div> :
                loading === 'resolved' && treatmentHistory.length > 0 ?
                    <div>
                        <div className='text-right'>
                            <button className="btn btn-sm sms-scan-btn mb-1" onClick={handlePrint}>Print</button>
                        </div>
                        <div ref={printRef} id="treatment-print">
                            <Card className="general-card p-3">
                                <div ref={componentRef} style={{padding: '60px'}} className="requisition-receipt">
                                    <style>
                                        {fontSize()}
                                    </style>
                                    <PrintTemplate title='TREATMENT CHART' {...{patientDetails, itemDetails}}/>
                                </div>
                            </Card>
                        </div>
                    </div> : <NoTransaction text='No previous treatment records found'/>}
        </div>
    );
};


function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TreatmentHistory)
