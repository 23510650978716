import React, { useEffect, useState } from 'react'
  import PageTitle from "../../Utils/smsTitle";
  import { SubHeader } from "../../../Containers/SubHeader";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { bindActionCreators } from "redux";
  import { connect } from "react-redux";
  import * as snackbarActions from '../../../actions/snackbarActions'
  import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
  import { PLContainer, ProfitContainer } from '../../Utils/styledComponents';
  import { usePrint } from '../../Utils/Templates/usePrint';
  import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
  import { BackButton } from '../../Utils/Buttons/DataExportationButton';
  import Card from '@material-ui/core/Card';
  import { LoadingGif } from '../../Utils/Loader';
  import axios from "axios";
  import { config } from "../../../Helpers/env";
  import { errorMessages } from "../../../Helpers/ErrorMessages";
  import { HospitalAddress } from "../../Utils/HospitalAddress";
  import { COACell, TrialBalancePrint } from "../Utils/TrialBalanceTree";
  import TableHead from "@material-ui/core/TableHead";
  import TableRow from "@material-ui/core/TableRow";
  import { COATableCustomCell } from "../ChartofAccounts/TreeViewComponent";
  import { history } from "../../../Helpers/history";
  import {formatDigits } from "../../Utils/formatNumbers";
  import { CustomTableCell, StickyHeadTable } from "../../Utils/Tables/StickyHeaderTable";
  import { TableCell } from "@material-ui/core";
  import { dateConvert } from "../../Utils/ConvertDate";
  import { MuiPagination } from "../../Utils/Tables/CustomTable";
  
  
  const header = (
      <TableHead>
          <TableRow>
              <COATableCustomCell><strong>Account</strong></COATableCustomCell>
              <COACell variant='170'><strong>Debit</strong></COACell>
              <COACell variant='170'><strong>Credit</strong></COACell>
              <COACell variant='170'><strong>Balance</strong></COACell>
          </TableRow>
      </TableHead>
  )
  
  const headData = [
      { id: 'head_name', numeric: false, disablePadding: false, label: 'Account' },
      { id: 'transaction_date', numeric: false, disablePadding: false, label: 'Date'},
      { id: 'memo', numeric: false, disablePadding: false, label: 'Narration' },
      { id: 'debit', numeric: false, disablePadding: false, label: 'Debit' },
      { id: 'credit', numeric: false, disablePadding: false, label: 'Credit' },
      { id: 'transaction_no', numeric: false, disablePadding: false, label: 'Transaction No' },
  ];
  
  const tree_label = (str_type, nav, isParent) => {
      if (str_type === 'final_level') {
          return (
              <TableRow>
                  <COATableCustomCell onClick={() => history.push(`/view_transactions/${nav.id}/${nav.head_code}-${nav.head_name}`)}
                      style={{ cursor: 'pointer' }}>
                      <span style={{ color: '#0065ff', display: 'inline-block' }}>{nav.head_name}</span></COATableCustomCell>
                  <COACell variant='170'><strong>{nav.debit_amount ? formatDigits(nav.debit_amount) : nav.debit_amount}</strong></COACell>
                  <COACell variant='170'><strong>{nav.credit_amount ? formatDigits(nav.credit_amount) : nav.credit_amount}</strong></COACell>
                  <COACell variant='170'><strong>{nav.balance ? formatDigits(nav.balance) : nav.balance}</strong></COACell>
              </TableRow>
          )
      } else if (str_type === 'current_parent') {
          return (
              <TableRow>
                  <COATableCustomCell style={{ fontWeight: isParent ? 'bold' : '' }}>
                      <span style={{ fontWeight: isParent ? 'bold' : '' }}>{nav.head_name}</span>
                  </COATableCustomCell>
                  <COACell variant='170' ><strong>{nav.debit_amount ? formatDigits(nav.debit_amount) : nav.debit_amount}</strong></COACell>
                  <COACell variant='170' ><strong>{nav.credit_amount ? formatDigits(nav.credit_amount) : nav.credit_amount}</strong></COACell>
                  <COACell variant='170'><strong>{nav.balance ? formatDigits(nav.balance) : nav.balance}</strong></COACell>
              </TableRow>
          )
      } else {
          return (
              <TableRow>
                  <COATableCustomCell style={{ fontWeight: isParent ? 'bold' : '' }} >{nav.head_name}</COATableCustomCell>
                  <COACell variant='170' ><strong>{nav.debit_amount ? formatDigits(nav.debit_amount) : nav.debit_amount}</strong></COACell>
                  <COACell variant='170' ><strong>{nav.credit_amount ? formatDigits(nav.credit_amount) : nav.credit_amount}</strong></COACell>
                  <COACell variant='170'><strong>{nav.balance ? formatDigits(nav.balance) : nav.balance}</strong></COACell>
              </TableRow>
          )
      }
  }
  
  const GeneralLedger = ({ actions, snackbar }) => {
      const [data, setData] = useState([]);
      const [loading, setLoading] = useState('idle')
      const [page, setPage] = useState(0)
      const [rowsPerPage, setRowsPerPage] = useState(10)
      const [totalRecords, setTotalRecords] = useState(0);
  
      const [activePage, setActivePage] = useState(1)
      const [dates, setDates] = useState({"start_date": dateConvert(), "end_date": dateConvert()})
  
      const {start_date, end_date} = dates
  
  
      useEffect(() => {
          setLoading('pending')
          axios.get(`${config.smsUrl}/accounting/chart/general_ledger`, { params: { start_date: start_date, end_date: end_date, page: activePage , page_size:rowsPerPage} }).then(res => {
            const data = res.data.data;
            setData(data.data || []);
            setLoading('success');
            setTotalRecords(data.total_records);
          }).catch(err => {
              errorMessages(err, null, actions)
              setLoading('error')
          })
  
      }, [start_date, end_date, rowsPerPage,activePage])
  
      const handleChange = (e) => {
          const { name, value } = e.target; 
          setDates({ ...dates, [name]: value });
      };
  
      const handleChangePage = (event, newPage) => {
          setPage(newPage);
          setActivePage(newPage+1)
      };
  
      const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
          setActivePage(1);
      };
  
  
      const closeSnackbar = () => {
          actions.snackbarActions.hideSnackbar()
      }
      const isLoading = loading === 'pending';
      const isSuccess = loading === 'success';
      const isError = loading === 'error';
  
  
      const { open, variant, message } = snackbar;
  
      const trialBalanceData = data ? data : [];
  
    //   const pagination = (
    //       <MuiPagination {...{
    //           colSpan: headData.length, data, page, handleChangePage, handleChangeRowsPerPage,total_count:total_count,
    //           rowsPerPage, rows: [10, 25,50,55,100, { label: 'All', value: -1 }]
    //       }} />
    //   )
    const pagination = (
        <MuiPagination {...{
            colSpan: headData.length,
            data,
            page,
            handleChangePage,
            handleChangeRowsPerPage,
            total_count: totalRecords,
            rowsPerPage,
            rows: [10, 25, 50, 100, { label: 'All', value: -1 }],
            isTotal:true
        }} />
    );
  
  
      const { componentRef, handlePrint } = usePrint(`General Ledger`)
      const printDoc = (
          <div ref={componentRef}>
              <ProfitContainer>
                  <HospitalAddress />
                  <table className="table table-sm table-borderless prescription-table">
                      <tbody>
                          <tr>
                              <td align='center'><h5><strong>General Ledger</strong></h5></td>
                          </tr>
                      </tbody>
                  </table>
                  <TrialBalancePrint header={header} data={trialBalanceData} tree_label={tree_label} />
                  <p style={{ textAlign: "left", fontStyle: "italic" }} className="mpeke-footer">Powered by Mara Scientific</p>
              </ProfitContainer>
          </div>
      )
  
      const reusableTable = (
          <>
              <StickyHeadTable {...{
                  width: '100%', isPaper: false, pagination,
                  data: data, columns: headData.filter(item => Boolean(item))
              }}>
                  {isLoading ? <TableRow><TableCell align='center' colSpan={headData.length}><LoadingGif /></TableCell></TableRow> : null}
                  {isSuccess ? data.length > 0 ?
                      <>
                          {data.slice(0, rowsPerPage).map((row) => {
                              return (
                                  <TableRow tabIndex={-1} key={row.id}>
                                      <CustomTableCell>{row.head_name}</CustomTableCell>
                                      <CustomTableCell>{row.transaction_date}</CustomTableCell>
                                      <CustomTableCell>{row.memo +" "+ row.narration}</CustomTableCell>
                                      <CustomTableCell>{row.entry_category === "DEBIT"? row.entry_amount : 0}</CustomTableCell>
                                      <CustomTableCell>{row.entry_category === "CREDIT"? row.entry_amount : 0}</CustomTableCell>
                                      <CustomTableCell>{row.transaction_no}</CustomTableCell>
                                      {/* <CustomTableCell>{row.balance}</CustomTableCell> */}
                                  </TableRow>
                              );
                          })}
                          {/*{emptyRows > 0 && (*/}
                          {/*    <TableRow style={{ height: 43 * emptyRows }}>*/}
                          {/*        <TableCell colSpan={headData.length} />*/}
                          {/*    </TableRow>*/}
                          {/*)}*/}
                      </> : <TableRow><TableCell align='center' colSpan={headData.length}>No records found</TableCell></TableRow> : null}
                  {isError ? <TableRow><TableCell align='center' colSpan={headData.length}>The server did not return a valid response</TableCell></TableRow> : null}
              </StickyHeadTable>
          </>
  
      )
  
      return (
          <div className='journals'>
              <PageTitle title="General Ledger" />
              <SubHeader title="Reports" subTitle="General Ledger">
                  <FontAwesomeIcon icon={faClipboardList} />
              </SubHeader>
  
              <MainSnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant} />
  
  
              <PLContainer>
                  <div className="my-3">
                      <BackButton to='/reports/generalreports' text='General Reports' />
                  </div>
                  <Card>
                  <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}} className='m-3'>
                              <div className="form-group pr-4" style={{display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                                  <label className="" htmlFor="startDate">
                                      From&nbsp;
                                  </label>
                                  <input
                                      type="date"
                                      name="start_date"
                                      value={start_date}
                                      onChange={handleChange}
                                      className="form-control"
                                      required
                                      data-testid="start-date"
                                      // defaultValue={reverseDate())}
                                  />
                              </div>
  
                              <div className="form-group" style={{display: 'flex',justifyContent: 'center', alignItems: 'center'}} >
                                  <label className="ml-4" htmlFor="endDate">
                                      To&nbsp;
                                  </label>
                                  <input
                                      type="date"
                                      name="end_date"
                                      value={end_date}
                                      onChange={handleChange}
                                      className="form-control"
                                      required
                                      data-testid="end-date"
                                      // defaultValue={reverseDate())}
                                  />
                              </div>
                              {/* <div style={{ padding: '8px 32px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                  <PrintPdfButtons handlePrint={handlePrint} />
                              </div> */}
                          </div>
                      
                      <div style={{ padding: '32px' }}>
                          <div id="profit-loss-statement-pdf" style={{ overflow: "hidden" }}>
                              <HospitalAddress />
                              <table className="table table-sm table-borderless prescription-table">
                                  <tbody>
                                      <tr>
                                          <td align='center'><h5><strong>General Ledger</strong></h5></td>
                                      </tr>
                                  </tbody>
                              </table>
                              {reusableTable}
  
                              <p style={{ textAlign: "center", fontStyle: "italic" }}>Powered by Mara Scientific</p>
  
                          </div>
  
                      </div>
                  </Card>
                  <div style={{ display: 'none' }}>
                      {printDoc}
                  </div>
              </PLContainer>
          </div>
      );
  }
  
  function mapStateToProps(state) {
      const { snackbar } = state;
      return { snackbar }
  }
  
  function mapDispatchToProps(dispatch) {
      return { actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) } }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(GeneralLedger);
  