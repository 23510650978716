import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {formatDigits} from "../../Utils/formatNumbers";
import { history } from '../../../Helpers/history';
import TableRow from "@material-ui/core/TableRow";
import Table  from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import {useStyles,MinusSquare, PlusSquare, StyledTreeItem,  COATableCustomCell} from "../ChartofAccounts/TreeViewComponent"



function ReportsTreeView({children,data=[] }) {
    const classes = useStyles();

    const openIds = data.map(item=>`${item.head_level}-${item.head_name}`)

    const otherIds = data.map(item=> {
        const items = item.children ? item.children : []
        return {
            ids: items.map(item => `${item.head_level}-${item.head_name}`)
        }
    }).reduce((a, item)=>{
        return a.concat(item.ids)
    },[])

    const defaultIds = [...openIds, ...otherIds]


    const tree_label = (str_type, nav, isheadLevel, padding, isParent) => {
        const balance = nav.balance ? nav.balance : 0;
        const amount = nav.amount ? nav.amount : 0;
        const isTotal = nav.is_total ? nav.is_total : null
        const isProfit = nav.is_profit ? nav.is_profit : null;
        if (str_type === 'final_level') {
            return (
                <TableRow>
                    <COATableCustomCell data-testid={nav.head_name}  onClick={()=>history.push(`/view_transactions/${nav.id}/${nav.head_code}-${nav.head_name}`)}
                                         style={{cursor:'pointer'}}>
                        <span  style={{color: '#0065ff',   display:'inline-block'}}>{nav.head_name}</span></COATableCustomCell>
                    <COATableCustomCell data-testid={`${nav.head_name}-balance`}>{ nav.balance ? formatDigits( nav.balance) : nav.balance}</COATableCustomCell>
                </TableRow>
            )
        } else if (str_type === 'current_parent') {
            return (
                <TableRow>
                    <COATableCustomCell data-testid={nav.head_name}  style={{fontWeight : isParent ? 'bold':''}}>
                        <span style={{fontWeight : isParent ? 'bold':''}}>{nav.head_name}</span>
                    </COATableCustomCell>
                    <COATableCustomCell data-testid={`${nav.head_name}-balance`}>{ nav.balance ? formatDigits(nav.balance) : nav.balance}</COATableCustomCell>
                </TableRow>
            )
        } else {
            return (
                <TableRow>
                    <COATableCustomCell data-testid={nav.head_name} style={{fontWeight : isParent ? 'bold':''}} >{nav.head_name}</COATableCustomCell>
                    <COATableCustomCell data-testid={`${nav.head_name}-balance`}  style={{ borderBottom: isTotal && amount  ? '1px solid gray' : isProfit && amount ? '3px double gray' : '',}} ><strong>{
                        balance ? formatDigits(balance) : balance}</strong></COATableCustomCell>
                </TableRow>
            )
        }
    }




    function nestedMenu(items) {
        return items.map((nav) => {
            const head_level = nav.head_level ? nav.head_level : 0;
            const padding  = head_level === 0 ? 4 :  head_level * 8;
            const category = nav.head_code ? nav.head_code : null;
            const isParent = !category;
            const isheadLevel = head_level >= 0;
            const nav_children = nav.children ? nav.children : []
            const idxs = `${head_level}-${nav.head_name}`
            if (nav.children && nav_children.length === 0) {
                return (
                    <>
                        {nav.is_final_level ?
                            <StyledTreeItem key={nav.id} nodeId={idxs} label={tree_label('final_level', nav, isheadLevel, padding, isParent)}/>
                            :
                            <StyledTreeItem key={nav.id} nodeId={idxs} label={tree_label('current_parent', nav, isheadLevel, padding, isParent)}/>
                        }
                    </>
                )
            }
            return (
                <>
                    <StyledTreeItem key={nav.id} nodeId={idxs} label={tree_label('parent', nav, isheadLevel, padding, isParent)}>
                        {nestedMenu(nav_children)}
                    </StyledTreeItem>

                </>
            )
        })


    }

    const header = (
        <TableHead>
            <TableRow>
                <COATableCustomCell><strong></strong></COATableCustomCell>
                <COATableCustomCell align='center'><strong>Amount</strong></COATableCustomCell>
            </TableRow>
        </TableHead>
    )


    return (
        <Table>
            {/*<TableHead>*/}
                <TreeItem label={header} nodeId={-2}/>
            {/*</TableHead>*/}
            <TableBody>
                <TreeView
                    className={classes.root}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    defaultExpanded={defaultIds}
                >
                    {nestedMenu(data)}
                </TreeView>
                {children}
            </TableBody>
        </Table>
    );
}
export {ReportsTreeView}
