import {useEffect, useState} from "react";
import {currentTime, dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import { useAddPrescription } from "../../../DoctorsModule/Prescription/hooks/useAddPrescription";
import { extractTime } from "../../../Utils/ConvertDate";

export const useDischargeForm = (actions, match) =>{
    const current_time = currentTime()
    const initialState = {clinical_presentation: "", summary_of_invention: "",description: '',
    discharge_date: '', provisional_diagnosis: '', final_diagnosis: '', recommendation: '',end_time: '',
    follow_up_date: '', }
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const discharging_office = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const {patient_admission_id, visit_id} = match.params;
    const [state, setState] = useState(initialState);
    const [p_number, setPatientNumber] = useState(patient_admission_id ? patient_admission_id : '');
    const [showNumber, setShowNumber] = useState(false);
    const [patientNumbers, setPatientNumbers] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [patient, setPatient] = useState({
        patient_name: '', admission_date: '', ward: '', room: '',
        bed_number: '', receipt_number: '',age:'', gender:'', address:'', phone:'',
    });
    const [printDialog, setOpenPrintDialog] = useState(false);
    const [patient_discharge_id, setDischargeId] = useState('');
    const [start_time, setStartTime] = useState('')

    const [isAddPrescription, setAddPrescription]= useState(false);



    const prescriptionObj = {visit_id, actions}

    const {addProps} = useAddPrescription(actions, prescriptionObj)
    const {prescription, others, other} = addProps
    const [contact, setContact] = useState('');

    useEffect(()=>{
        if(!patient_admission_id){
            return
        }
        const formData = new FormData();
        formData.append('patient_admission_id',patient_admission_id ? patient_admission_id : "");
        axios.post(`${config.smsUrl}/cadmission/retrieve_admission_details_by_id`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? {} : details;
                const dt = !data.admission_list ? [] :data.admission_list;
                const adm = !dt[0] ? {} : dt[0];
                const {patient_firstname, patient_lastname, admission_date, ward_name, room_name, bed_number,
                    age, gender, address, phone_no:phone} = adm;
                setPatient({
                    patient_name: `${!patient_firstname ? "" : patient_firstname} ${!patient_lastname ? "" : patient_lastname}`,
                    admission_date:admission_date ? admission_date : '', ward: ward_name ? ward_name : '',
                    room: room_name ? room_name : '', bed_number:bed_number ? bed_number : '',
                    age, gender, address, phone,
                })
            }).catch(error => {
            logoutErrorMessage(error,null,actions)
        });
    },[])

    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangeTime = (time) =>{
        setStartTime(time)
    }

    const handleChangePatientNumbers = (event) => {
        setPatientNumber((event.target.value).toUpperCase());
        const formData = new FormData();
        if (event.target.value !== '') {
            formData.append('patient_admission_id', (event.target.value).toUpperCase());
            axios.post(`${config.smsUrl}/cadmission/auto_complete_discharge_admission`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    const dt  = data.map(item=>({
                        value:item.patient_admission_id,
                        label:item.patient_admission_id
                    }))
                    if (data.length > 0){
                        setPatientNumbers(dt)
                    }else {
                        setPatientNumbers([{value:'', label:'No patient found'}])
                    }
                }).catch(error => {
                errorMessages(error,null,actions)
            });
            setShowNumber(true);
        } else {
            setShowNumber(false);
        }

    };

    const handleClickPatientNumber = (event) => {
        const formData = new FormData();
        setPatientNumber(event.innerText);
        formData.append('patient_admission_id', event.value);
        axios.post(`${config.smsUrl}/cadmission/retrieve_admission_details_by_id`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? {} : details;
                const dt = !data.admission_list ? [] :data.admission_list;
                const adm = !dt[0] ? {} : dt[0];
                const {patient_firstname, patient_lastname, admission_date, ward_name, room_name, bed_number} = adm;
                setPatient({
                    patient_name: `${!patient_firstname ? "" : patient_firstname} ${!patient_lastname ? "" : patient_lastname}`,
                    admission_date:admission_date ? admission_date : '', ward: ward_name ? ward_name : '',
                    room: room_name ? room_name : '', bed_number:bed_number ? bed_number : ''
                })
            }).catch(error => {
            errorMessages(error,null,actions)
        });
        setShowNumber(false);
    };

    const handleReset = () => {
        setState({
            clinical_presentation: "", summary_of_invention: "",
            discharge_date: '', provisional_diagnosis: '', final_diagnosis: '', recommendation: '',
            follow_up_date: ''
        });
        setPatient({patient_name: '', admission_date: '', ward: '', room: '', bed_number: '', receipt_number: ''})
    };

    const handleClosePrintDialog = () => {
        setOpenPrintDialog(false)
    };

    const handleSubmit = event => {
        event.preventDefault();
        const {clinical_presentation, summary_of_invention, discharge_date,
            final_diagnosis, recommendation, follow_up_date,  description} = state;

        const arr = prescription.every(item => item.product_id || (other && !item.product_id));

        const formData = new FormData();
        formData.append('patient_admission_id', p_number);
        formData.append('notes', clinical_presentation);
        formData.append('investigations', summary_of_invention);
        formData.append('discharge_date', discharge_date);
        formData.append('diagnosis', final_diagnosis);
        formData.append('recommendations', recommendation);
        if(follow_up_date){
            formData.append('followup_date', follow_up_date);
        }
        formData.append('discharged_by', user_roles_id);

        if (arr) {
            formData.append('bill_type', 3);
            formData.append('visit_id', visit_id)
            prescription.filter(item => Boolean(item)).filter(drug => drug.product_id).forEach(prod => {
                formData.append('product_id', prod.product_id);
                formData.append('dosage', prod.dosage);
                formData.append('number_of_days', prod.number_of_days);
                formData.append('key_information', prod.key_information);
                formData.append('rate', prod.rate);
                formData.append('frequency', prod.frequency);
                formData.append('quantity_prescribed', parseInt(prod.quantity));
                formData.append('quantity_to_bill', prod.quantity_to_bill ?  parseInt(prod.quantity_to_bill) : '');
                formData.append('is_provided', prod.is_provided)
                formData.append('is_provider', prod.is_provider)
                formData.append('currency_id', prod.currency)
            })

           
            const other_arr = others.filter(item=>Boolean(item)).filter(drug => drug.other_medicine || drug.dosage || drug.price || drug.instructions ||
                drug.frequency || drug.number_of_days);
                
            other_arr.forEach(prod => {
                formData.append('other_medicine',  JSON.stringify(prod));
            })

            formData.append('discharge_prescription', true);
        } else {
            formData.append('discharge_prescription', false);
        }
        formData.append('start_time', start_time ? extractTime(start_time) : current_time);
        formData.append('end_time', start_time ? extractTime(start_time) : current_time);
        formData.append('description', description);
        formData.append('phone',contact)

        const isPrescription = (isAddPrescription && arr) || !isAddPrescription

        setSubmitted(true);
        if (p_number && discharge_date   && recommendation && isPrescription) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cvisit/patient_discharge`, formData).then((res) => {
                const data = res.data;
                const dt = !data ? {} : data;
                const discharge_id = !dt.discharge_id ? "" : dt.discharge_id;
                actions.snackbarActions.snackSuccess("Patient discharged successfully");
                setDischargeId(discharge_id);
                setOpenPrintDialog(true);
                setIsSubmitted('resolved');
                setState(initialState)
                setPatientNumber('')
                setSubmitted(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }
    };

    const handleClick = () => {
        history.push(`/dischargenote/${patient_discharge_id}/${visit_id}`)
    };

    const handlePrescriptionToggle = (event) => {
        setAddPrescription(event.target.checked);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };
    const isPending = isSubmitted === 'pending'

    return {state, p_number, showNumber, patientNumbers, submitted, isSubmitted, patient,
        printDialog, discharging_office, handleChange, handleChangePatientNumbers,
        handleClickPatientNumber, handleReset, handleClosePrintDialog, handleSubmit,
        handleClick, closeSnackbar,patient_discharge_id,setState, setPatientNumber,userObj,
        token, _token, user_roles_id,visit_id,prescription, contact, setContact,
        addProps, isPending, handleChangeTime, start_time, isAddPrescription, handlePrescriptionToggle
    }

}