import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {useCommonState} from "../../CustomHooks/useReusableFunctions";
import { useStockAdjustment } from "../../../Inventory/stock/hooks/useStockAdjustment";
import { dateConvert } from "../../../Utils/ConvertDate";

export const useReusableInventory = (actions, url) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [total_count, setCount] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const csvRef = useRef();
    const [state, setState] = useState({
        start_date: dateConvert(),
        end_date: dateConvert(),
      });
    const {
        closeSnackbar, search_type, text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear, inputType,
        customSearch, order, orderBy, activePage, handleChangeActivePage, handleCustomSearch,
        handleSearch, handleRequestSort, handleChangePage
    } = useCommonState(actions)
    const {adjustmentProps, openStockDialog, handleOpenStockDialog, handleCloseStockDialog,isResolvedStock,isInitialStock,
        isAvailableStock, stockStatus} = useStockAdjustment(actions)
    const { start_date, end_date} = state;

    useEffect(() => {
        setLoading(true);
        const requestData = {
            search: customSearch,
            start_date: start_date,
            end_date: end_date
        };
        // const formData = new FormData();
        // formData.append('search', customSearch)
        axios.post(`${config.smsUrl}/creport/${url}/${activePage}`, requestData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const count = !dt.total_count ? 0 : dt.total_count;
            
            setData(dt)
            setCount(count)
            console.log(dt)
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, [activePage, customSearch, isResolvedStock, start_date, end_date]);


    const handleSubmit = (event) => {
        event.preventDefault();
        const {start_date, end_date} = date;
        const formData = new FormData();
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        formData.append('option', search_type);
        setSubmitted(true);
        if ((search_type === '3' && start_date && end_date) ||
            ((search_type !== '3' || search_type !== '2') && start_date) ||
            (search_type === '2' && year)) {
            setIsSubmitted(true)
            setLoading(true)
            axios.post(`${config.smsUrl}/creport/${url}`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const count = !dt.total_count ? 0 : dt.total_count;
                
                setData(data)
                setCount(count)
                setLoading(false);
                setIsSubmitted(false)
            }).catch(err => {
                setIsSubmitted(false)
                setLoading(false)
                errorMessages(err, null, actions);
            })
        }
    }

    

    const downloadCsv = () => {
        csvRef.current.link.click()
    }


    return {data, search_type, text, textType, year, date, handleChangeActivePage,csvRef,downloadCsv,
        handleCustomSearch, isSubmitted, submitted, customSearch, order, orderBy, loading, total_count,
        activePage, closeSnackbar, handleSearch, handleChangePage, handleRequestSort, inputType,
        handleChangeSearch, handleChangeDate, handleChangeYear, handleIncrementYear, handleSubmit,adjustmentProps, openStockDialog, handleOpenStockDialog, handleCloseStockDialog,isResolvedStock,isInitialStock,
        isAvailableStock, stockStatus, state, setState
    }
}