import { useEffect, useReducer, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { dateConvert } from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {
	errorMessages,
	logoutErrorMessage,
} from "../../../../Helpers/ErrorMessages";
import { titleCase } from "../../../Users/addUser";
import { history } from "../../../../Helpers/history";
import { useSelfRequestPrescription } from "../../../Visit/NewService/hooks/usePrescriptionSelfRequest";
import { useNewServiceRequest } from "../../../Visit/NewService/hooks/useNewServiceRequest";
import {billingProductsAndServicesReducer} from "../../utils/billingProductsReducer";
import * as billingActions from "../../utils/billingActions";
import {
	filteredPayloadProducts, filterServicesWithCurrency, filterProductsWithCurrency,
} from "../../utils/SanitizeArr";
import {useCurrency} from "../../../../Context/currency-context";
import {useVersionsContext} from "../../../../Context/versions-context";

export const useAddBill = (props) => {
	const { actions } = props;

	const [
		{ products, productsEdited, productsRemoved, productsPaid, procedureData, invoicesEdited, invoicesRemoved, invoicesPaid },
		dispatchProductsAndServices,checkAllServices,checkAllProducts
	] = useReducer(billingProductsAndServicesReducer, {
		products: [],
		productsEdited: [],
		productsRemoved: [],
		productsPaid: [],
		procedureData: [],
		invoicesEdited: [],
		invoicesRemoved: [],
		invoicesPaid: [],
		checkAllServices:false,
		checkAllProducts:false
	});

	const [payment_method, setPaymentMethod] = useState("");
	const [state, setState] = useState({ notes: '', patient_name: '', patient_number: '', address: '' });
	const [isSubmitted, setIsSubmitted] = useState("idle");
	const [date, setDate] = useState(dateConvert());
	const [openPrint, setOpenPrint] = useState(false);
	const [total_amount, setTotalAmount] = useState(0);
	const [paidAmount, setPaidAmount] = useState("");
	const [balance, setBalance] = useState(0);
	const [submitted, setSubmitted] = useState(false);
	const [payTypes, setPayTypes] = useState([]);
	const [addService, setAddService] = useState(false);
	const [addProduct, setAddProduct] = useState(false);
	const [departments, setDepartments] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [currency, setCurrency] = useState("");
	const [exchangeRate, setExchangeRate] = useState(0);
	const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)
	const [usdAmount, setUsdAmount] = useState(0);
	const [sspAmount, setSspAmount] = useState(0);
	const [currencyTotal, setCurrencyTotal] = useState(0);
	const [currencyTotalSsp, setCurrencyTotalSsp] = useState(0);
	const [isBlocking, setIsBlocking] = useState(false);
	const [is_converted, setIsCoverted] = useState(false);

	//const {visit_id, transaction_id} = props.match.params;

	const { visit_id, transaction_id } = props.match.params;
	const user = JSON.parse(sessionStorage.getItem("user"));
	const userObj = !user ? {} : user;
	const token = !userObj.token ? [] : userObj.token;
	const _token = !token[1] ? {} : token[1];
	const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
	const obj = { visit_id, user_roles_id, actions };

	const { renderPrescription, isResolved, handleClosePrescriptionDialog } =
		useSelfRequestPrescription({ ...obj, setOpenDialog: setAddProduct });

	const { renderService, isResolvedService, handleCloseServiceDialog } =
		useNewServiceRequest({ ...obj, setOpenDialog: setAddService }, true);

	// get payment methods
	useEffect(() => {
		axios
			.get(`${config.smsUrl}/cbilling/payment_methods`)
			.then((res) => {
				const data = res.data;
				const all_data = !data ? {} : data;
				const arr = all_data.data ?? [];
				const list = arr
					.map((item) => ({
						value: item.id,
						text: item.payment_type_name,
					}))
					.filter((item) => item.text === "CASH");
				setPayTypes(list);
			})
			.catch((err) => {
				logoutErrorMessage(err, null, actions);
			});
	}, []);

	const {currency:selectedCurrency} = useCurrency({actions})

	const {subscription} = useVersionsContext()
	const version = subscription?.version
	const isPremium = version === 'Premium'


	useEffect(()=>{
		if (isPremium){
			return
		}
		setCurrency(`${selectedCurrency?.id}-${selectedCurrency?.is_base_currency}-${selectedCurrency?.currency_symbol}`)
	},[version])


	// get currencies
	useEffect(() => {
		axios
			.get(`${config.smsUrl}/accounting/currencies`)
			.then((res) => {
				const data = res.data;
				const all_data = !data ? {} : data;
				const arr = all_data.data ?? [];
				const list = arr.map((item) => ({
					value: `${item.id}-${item.is_base_currency}-${item.currency_symbol}`,
					text: `${item.currency_symbol} ${item.currency_name}`,
				}));
				setCurrencies(list);
			})
			.catch((err) => {
				logoutErrorMessage(err, null, actions);
			});
	}, []);

	//get departments
	useEffect(() => {
		axios
			.get(`${config.smsUrl}/cvisit/retrieve_department`)
			.then((res) => {
				const data = res.data;
				const dt = !data ? [] : data;
				const departs = !dt.departments ? [] : dt.departments;
				const arr = departs.map((department) => ({
					value: department.department_id,
					label: department.department_name,
				}));
				setDepartments(arr);
			})
			.catch((err) => {
				logoutErrorMessage(err, null, actions);
			});
	}, []);

	function calculateTotal(procArr, prdts, rate = 0, selectedCurrency=currency) {

		const totalPaidAmount = [...procArr, ...prdts].filter(item=>item.isPaid).reduce((a, item) => {
			return +(a + item.subTotal)
		}, 0)

		const overallPaidAmount = selectedCurrency?.includes('true') ? totalPaidAmount :   totalPaidAmount / rate

		const proc_total = procArr.reduce((a, item) => {
			return a + item.subTotal;
		}, 0);

		const prdtArr = prdts.reduce((a, item) => {
			return a + item.subTotal;
		}, 0);

		const currency_service_total = procArr.reduce((a, item) => {
			const base_total = rate ? +item.rate / rate : 0;
			return a + +base_total;
		}, 0);

		const currency_service_total_ssd = procArr.reduce((a, item) => {
			const base_total = rate ? (+item.rate / rate) * +item.quantity : 0;
			return a + +item.currency_total;
		}, 0);

		const product_service_total = prdts.reduce((a, item) => {
			const base_total = rate ? (+item.rate / rate) * +item.quantity : 0;
			return a + +base_total;
		}, 0);

		const product_service_total_ssd = prdts.reduce((a, item) => {
			const base_total = rate ? (+item.rate / rate) * +item.quantity : 0;
			return a + +item.currency_total;
		}, 0);

		// const amt = paidAmount - (proc_total + prdtArr);
		//
		// const bal = amt < 0 ? 0 : amt;

		const balance = paidAmount <= 0 ? 0 : (paidAmount - (proc_total + prdtArr)).toFixed(3)

		// console.log(rate, balance, paidAmount, proc_total , prdtArr, (proc_total + prdtArr).toFixed(2))

		setBalance(balance)

		setTotalAmount((proc_total + prdtArr).toFixed(3))

		multiplyCurrency(rate, overallPaidAmount, currency)

		setCurrencyTotal((currency_service_total + product_service_total).toFixed(3));

		setCurrencyTotalSsp((currency_service_total_ssd + product_service_total_ssd).toFixed(3));
		setPaidAmount(overallPaidAmount)
	}

	const outPatientUnpaidBillDetailsReceiptQuery = useQuery(
		["outPatientUnpaidBillDetailsReceiptQuery", isResolved, isResolvedService],
		async () => {
			try {
				const formData = new FormData();

				formData.append("visit_id", visit_id);
				formData.append("pay_status", 0);

				const response = await axios.post(
					`${config.smsUrl}/cbilling/bill_update_form`,
					formData
				);

				const data = response?.data ?? {};

				const patient = data?.patient_info ?? {};

				const bill_details = data?.bill ?? [];

				const bill_amount = data?.total_bill ?? 0;

				// setTotalAmount(bill_amount.toFixed(2));

				const { due_amount, paid_amount } = patient;

				setState({
					patient_name: titleCase(`${ patient?.patient_firstname ?? "" } ${ patient?.patient_lastname ?? "" }`),
					patient_number: patient?.patient_number ?? "",
					address: titleCase(patient?.address ?? ""),
					notes: "",
				});

				const filteredArr = bill_details.filter(
					(item) => item.product_id
				);
				const procs = bill_details.filter((item) => item.service_id);

				// const services = filterServices(exchangeRate, procs);
				//
				// const prescr = filterProductsArr(exchangeRate, filteredArr);

				const services = filterServicesWithCurrency(exchangeRate, procs, currency);

				const prescr = filterProductsWithCurrency(exchangeRate, filteredArr, currency);

				// dispatch({
				// 	type: billingActions.RETRIEVE_SERVICES,
				// 	products: prescr,
				// 	services: services,
				// 	calculateTotal,
				// });
				//
				// dispatchProducts({
				// 	type: billingActions.RETRIEVE_PRODUCTS,
				// 	products: prescr,
				// 	services: services,
				// 	calculateTotal,
				// });


				dispatchProductsAndServices({
					type: billingActions.RETRIEVE_PRODUCTS_AND_SERVICES,
					products: prescr,
					services: services,
					exchangeRate,
					calculateTotal,
				})

				// setBalance((prevBalance) => {
				// 	console.log(prevBalance, due_amount)
				// 	return prevBalance > 0 ? prevBalance.toFixed(2) : due_amount ?? 0 });
				//
				// setPaidAmount((prevPaidAmount) => prevPaidAmount !== "" ? prevPaidAmount : paid_amount ?? "");

				// calculateTotal(services, prescr);

				return {
					total_amount: bill_amount.toFixed(3),

					state: {
						patient_name:
							titleCase(
								`${!patient?.patient_firstname ?? ""} ${
									patient?.patient_lastname ?? ""
								}`
							) ?? "",
						patient_number: patient?.patient_number ?? "",
						address: titleCase(patient?.address ?? "") ?? "",
						notes: "",
					},

					balance: patient?.due_amount ?? 0,

					paidAmount: patient?.paid_amount ?? 0,
				};
			} catch (error) {
				logoutErrorMessage(error, null, actions);
			}
		},
		{
			retry: 1,
			// refetchInterval: 5000,
		}
	);

	// let { total_amount, state, balance, paidAmount } = billDetailsQuery.isSuccess ? billDetailsQuery.data : { total_amount: 0.0000, state: { patient_name: "", patient_number: "", address: "", notes: "" }, balance: 0, paidAmount: 0 };

	// get data for bill form
	// useEffect(() => {
	//
	//     function retrieveBillDetails() {
	//         const formData = new FormData();
	//         formData.append('visit_id', visit_id);
	//         formData.append('pay_status', 0);
	//         axios.post(`${config.smsUrl}/cbilling/bill_update_form`, formData).then(res => {
	//             const data = res.data;
	//             const all_data = !data ? {} : data;
	//             const patient = !all_data.patient_info ? {} : all_data.patient_info;
	//
	//             const bill_details = !all_data.bill ? [] : all_data.bill;
	//             const bill_amount = !all_data.total_bill ? 0 : all_data.total_bill
	//             setTotalAmount(bill_amount.toFixed(4))
	//
	//
	//             const { due_amount, paid_amount } = patient;
	//
	//             setState({
	//                 patient_name: titleCase(`${!patient.patient_firstname ? "" : patient.patient_firstname} ${!patient.patient_lastname ? "" : patient.patient_lastname}`),
	//                 patient_number: !patient.patient_number ? "" : patient.patient_number,
	//                 address: titleCase(!patient.address ? "" : patient.address), notes: ''
	//             });
	//
	//             const filteredArr = bill_details.filter(item => item.product_id);
	//             const procs = bill_details.filter(item => item.service_id);
	//
	//             const services = filterServices(exchangeRate, procs)
	//
	//             const prescr = filterProductsArr(exchangeRate, filteredArr);
	//
	//             dispatch({ type: billingActions.RETRIEVE_SERVICES, services })
	//             dispatchProducts({ type: billingActions.RETRIEVE_PRODUCTS, products: prescr })
	//
	//             setBalance(due_amount ? due_amount : 0);
	//             setPaidAmount(paid_amount ? paid_amount : '')
	//
	//             calculateTotal(services, prescr)
	//         }).catch(err => {
	//             logoutErrorMessage(err, null, actions)
	//         })
	//     }
	//
	//     // retrieveBillDetails()
	//
	//     const interval = setInterval(() => {
	//         // console.log("inside interval before calling")
	//         retrieveBillDetails()
	//
	//     }, 15000)
	//
	//     return () => {
	//         return clearInterval(interval)
	//     }
	// }, [isResolved, isResolvedService]);

	// const  countDecimals = (value) => {
	//     if(Math.floor(value) === value) return 0;
	//     return value.toString().split(".")[1].length || 0;
	// }


	const multiplyCurrency = (exchange_rate, amountReceived, displayedCurrency) => {


		let [amountInUSD, amountInSSP] = [0,0];

		// currency is USD
		if(displayedCurrency.includes("true")){
			amountInUSD = amountReceived

			amountInSSP = Number(exchange_rate) <= 0 ? 0 : Number(exchange_rate) * Number(amountReceived)


			setUsdAmount(Number(amountInUSD).toFixed(3));

			setSspAmount(Number(amountInSSP).toFixed(3));

			return;
		}

		// currency is SSP
		if(displayedCurrency.includes("false")){
			amountInSSP = amountReceived;

			amountInUSD = Number(exchange_rate) <= 0 ? 0 : Number(amountReceived) * Number(exchange_rate)

			setUsdAmount(Number(amountInUSD).toFixed(3));

			setSspAmount(Number(amountInSSP).toFixed(3));

			return;
		}


	}



	//Change currency
	const handleChangeCurrency = (event) => {
		setCurrency(event.target.value);
		// setTotals({...totals, balance: 0})
		// setPaidAmount('')
        // setTotals({...totals, balance: 0})
        setUsdAmount(0)
        setSspAmount(0)
        setExchangeRate(0)
		setCurrencyExchangeRate('')
		setIsBlocking(true);
		calculateTotal(procedureData, products, exchangeRate, event.target.value)
	};

	//Change exchange rate
	const handleChangeExchangeRate = (event) => {
		setCurrencyExchangeRate(event.target.value)
		const ex_rate = +event.target.value > 0 ?  1 / +event.target.value:0
		setExchangeRate(ex_rate);
		multiplyCurrency(ex_rate, paidAmount, currency);
		setIsCoverted(true);

		// dispatchProducts({
		// 	type: billingActions.CALCULATE_PRODUCT_AMOUNT,
		// 	rate: event.target.value,
		// 	currency: currency.split("-")[2],
		// });
		// dispatch({
		// 	type: billingActions.CALCULATE_SERVICE_AMOUNT,
		// 	rate: event.target.value,
		// 	currency: currency.split("-")[2],
		// });

		dispatchProductsAndServices({
			type: billingActions.CALCULATE_PRODUCT_AMOUNT,
			rate: ex_rate,
			currency: currency.split("-")[1],
		});

		dispatchProductsAndServices({
			type: billingActions.CALCULATE_SERVICE_AMOUNT,
			rate: ex_rate,
			currency: currency.split("-")[1],
		});

		//dispatchProducts({ type: billingActions.CALCULATE_PRODUCT_AMOUNT, rate: event.target.value })
		//dispatch({ type: billingActions.CALCULATE_SERVICE_AMOUNT, rate: event.target.value })

		calculateTotal(procedureData, products, Number(ex_rate));
		setIsBlocking(true);
	};

	//change amount received
	const handleChangePaidAmount = (event) => {
		const isBase = currency.split("-")[1];
		const isBaseCurrency = isBase === "true";
		setPaidAmount(event.target.value);
		// paidAmount = event.target.value;
		const total_amt = isBaseCurrency ? +total_amount : +currencyTotal;
		const amount = +event.target.value - total_amt;
		const amt = amount < 0 ? 0 : amount;
		setBalance((amt).toFixed(3))
		// setBalance(amt)
		// balance = amt.toFixed(4);
		multiplyCurrency(exchangeRate, event.target.value, currency);
		setIsBlocking(true);
	};

	const handleChangeCurrencyRate = (event, bill_id) => {
		const prods = products.filter((item) => !item.isExcluded);
		// dispatch({
		// 	type: billingActions.CHANGE_SERVICE_CURRENCY_RATE,
		// 	value: event.target.value,
		// 	bill_id,
		// 	exchangeRate,
		// 	calculateTotal,
		// 	products: prods,
		// });

		dispatchProductsAndServices({

			type: billingActions.CHANGE_SERVICE_CURRENCY_RATE,
			value: event.target.value,
			bill_id,
			exchangeRate,
			calculateTotal,
			products: prods,
		})

		setIsBlocking(true);
	};

	const handleChangeRate = (event, bill_id) => {
		const prods = products.filter((item) => !item.isExcluded);
		// dispatch({
		// 	type: billingActions.CHANGE_SERVICE_RATE,
		// 	value: event.target.value,
		// 	bill_id,
		// 	exchangeRate,
		// 	calculateTotal,
		// 	products: prods,
		// });

		dispatchProductsAndServices({
			type: billingActions.CHANGE_SERVICE_RATE,
			value: event.target.value,
			bill_id,
			exchangeRate,
			calculateTotal,
			products: prods,
		});

		setIsBlocking(true);
	};

	const handleDeleteProcedure = (item) => {
		const { bill_id } = item;
		const prods = products.filter((item) => !item.isExcluded);
		// dispatch({
		// 	type: billingActions.DELETE_SERVICE,
		// 	bill_id,
		// 	exchangeRate,
		// 	calculateTotal,
		// 	products: prods,
		// });

		dispatchProductsAndServices({
			type: billingActions.DELETE_SERVICE,
			bill_id,
			exchangeRate,
			calculateTotal,
			products: prods,
		});

		setIsBlocking(true);
	};

	const handlePayProcedure = (event,item) => {
		const { bill_id, service_id } = item;
		dispatchProductsAndServices({
			type: billingActions.PAY_SERVICE,
			service_id,
			exchangeRate,
			calculateTotal,
			products: products,
			value:event.target.checked
		});

		setIsBlocking(true);
	};

	const handlePayAllProcedures = (event) => {
		dispatchProductsAndServices({
			type: billingActions.CHECK_ALL_SERVICES,
			exchangeRate,
			calculateTotal,
			products: products,
			value:event.target.checked
		});

		setIsBlocking(true);
	};

	const handleChangeProductRate = (event, bill_id) => {
		const procedures = procedureData.filter((item) => !item.isExcluded);
		// dispatchProducts({
		// 	type: billingActions.CHANGE_PRODUCT_RATE,
		// 	value: event.target.value,
		// 	bill_id,
		// 	exchangeRate,
		// 	calculateTotal,
		// 	services: procedures,
		// });

		dispatchProductsAndServices({
			type: billingActions.CHANGE_PRODUCT_RATE,
			value: event.target.value,
			bill_id,
			exchangeRate,
			calculateTotal,
			services: procedures,
		});

		setIsBlocking(true);
	};

	const handleChangeProductCurrencyRate = (event, bill_id) => {
		const procedures = procedureData.filter((item) => !item.isExcluded);
		// dispatchProducts({
		// 	type: billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
		// 	value: event.target.value,
		// 	bill_id,
		// 	exchangeRate,
		// 	calculateTotal,
		// 	services: procedures,
		// });

		dispatchProductsAndServices({
			type: billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
			value: event.target.value,
			bill_id,
			exchangeRate,
			calculateTotal,
			services: procedures,
		});

		setIsBlocking(true);
	};

	const handleChangeProductQuantity = (event, bill_id) => {
		const procedures = procedureData.filter((item) => !item.isExcluded);
		// dispatchProducts({
		// 	type: billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
		// 	value: event.target.value,
		// 	bill_id,
		// 	exchangeRate,
		// 	calculateTotal,
		// 	services: procedures,
		// });

		dispatchProductsAndServices({
			type: billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
			value: event.target.value,
			bill_id,
			exchangeRate,
			calculateTotal,
			services: procedures,
		});


		setIsBlocking(true);
	};

	const handleDeleteProduct = (item) => {
		const { bill_id } = item;
		const procedures = procedureData.filter((item) => !item.isExcluded);
		// dispatchProducts({
		// 	type: billingActions.DELETE_PRODUCT,
		// 	bill_id,
		// 	exchangeRate,
		// 	calculateTotal,
		// 	services: procedures,
		// });

		dispatchProductsAndServices({
			type: billingActions.DELETE_PRODUCT,
			bill_id,
			exchangeRate,
			calculateTotal,
			services: procedures,
		});

		setIsBlocking(true);
	};

	const handlePayProduct = (event,item) => {
		const { bill_id, product_id } = item;
		dispatchProductsAndServices({
			type: billingActions.PAY_PRODUCT,
			product_id,
			exchangeRate,
			calculateTotal,
			services: procedureData,
			value:event.target.checked
		});

		setIsBlocking(true);
	};

	const handlePayAllProducts = (event) => {
		dispatchProductsAndServices({
			type: billingActions.CHECK_ALL_PRODUCTS,
			exchangeRate,
			calculateTotal,
			services: procedureData,
			value:event.target.checked
		});

		setIsBlocking(true);
	};

	const handleChangeDate = (event) => {
		setDate(event.target.value);
		setIsBlocking(true);
	};

	const handleChangeMethod = (event) => {
		const { value } = event.target;
		setPaymentMethod(value);
		setIsBlocking(true);
	};

	const paidProducts = filteredPayloadProducts(productsPaid)
	const editedProducts = filteredPayloadProducts(productsEdited).filter(
		(item) => !item.isExcluded
	);

	const servicesEdited = invoicesEdited.filter((item) => !item.isExcluded);
	const invoicesAdded = [...invoicesPaid, ...paidProducts].filter(item=>item.isPaid);

	const removedInvoices = [
		...filteredPayloadProducts(productsRemoved),
		...invoicesRemoved,
	];
	const editedInvoices = [...servicesEdited, ...editedProducts];

	const handleOpenReceipt = (e) => {
		e.preventDefault();
		const isBase = currency.split("-")[1];

		const paidServices = procedureData.some(item=>item.isPaid)
		const paidProducts = products.some(item=>item.isPaid)

		const isNotBase = isBase === "false";
		const isBaseCurrency = isBase === "true";

		setIsBlocking(false);


		const requiredData =
			(isNotBase && Number(usdAmount).toFixed(3) === Number(total_amount).toFixed(3)) ||
			(isBaseCurrency && Number(paidAmount).toFixed(3) === Number(total_amount).toFixed(3));
		const isBaseRequired =
			(isNotBase && exchangeRate) || (isBaseCurrency && !exchangeRate);
		if (payment_method && currency && (paidServices || paidProducts)) {
			setOpenPrint(true);
		} else {
			setSubmitted(true);
		}
	};

	const handleSubmitBill = (event) => {
		event.preventDefault();
		const { notes } = state;
		setSubmitted(true);

		const isPaid = invoicesAdded.length > 0;
		const isEdited = editedInvoices.length > 0;
		const isInvoice = isPaid || isEdited;

		const amount = !isInvoice ? 0 : paidAmount;
		const payType = !isInvoice ? "" : payment_method;

		const isBase = currency.split("-")[1];

		const isNotBase = isBase === "false";
		const isBaseCurrency = isBase === "true";

		const currency_id = currency.split("-")[0];


		const isBaseData = isBaseCurrency
			? {
					is_base_currency: isBase,
					currency_id,
					amount_paid: +amount,
					is_converted: is_converted,
					rate_input_by_cashier: +exchangeRate,
			  }
			: {};
		const isNotBaseData = {
			currency_id,
			amount_paid_in_the_currency: +paidAmount,
			rate_input_by_cashier: +exchangeRate,
			total_amount_in_base_currency: +usdAmount,
			amount_paid: +usdAmount,
			is_base_currency: isBase,
		};
		const data = isBaseCurrency ? isBaseData : isNotBaseData;

		setIsSubmitted("pending");
		setIsBlocking(false);
		const url = transaction_id
			? "payment_update"
			: total_amount === +paidAmount
			? "pay_bill"
			: "pay_bill";
		axios
			.post(`${config.smsUrl}/cbilling/${url}`, {
				pay_type: payType ? +payType : payType,
				visit_id: +visit_id,
				received_by: user_roles_id,
				memo: notes,
				bill_mode: "cash",
				invoices_paid: invoicesAdded,
				invoices_removed: removedInvoices,
				invoices_edited: editedInvoices,
				...data,
			})
			.then((res) => {
				const data = res.data ?? {};
				let receipt_number = data.receipt_numbers;
				actions.snackbarActions.snackSuccess(`Bill created successfully`);
				setIsSubmitted("resolved");
				history.push(`/billingReceipt/${visit_id}/${receipt_number}`);
			})
			.catch((err) => {
				errorMessages(err, null, actions);
				setIsSubmitted("rejected");
			});
	};

	const handleCloseReceipt = () => {
		setOpenPrint(false);
	};

	const closeSnackbar = () => {
		actions.snackbarActions.hideSnackbar();
	};

	const handleAddService = () => {
		setAddService(true);
	};

	const handleAddProduct = () => {
		setAddProduct(true);
	};

	const handleCloseAddService = () => {
		// setAddService(false)
		handleCloseServiceDialog();
	};

	const handleCloseAddProduct = () => {
		setAddProduct(false);
		handleClosePrescriptionDialog();
	};

	const { patient_name } = state;

	const isExcluded =
		patient_name && (invoicesAdded.length > 0 || editedInvoices.length > 0);

	return {
		payment_method,
		state,
		handleChangeProductRate,
		isSubmitted,
		date,
		openPrint,
		total_amount,
		paidAmount,
		balance,
		submitted,
		handleChangeDate,
		handleDeleteProcedure,
		handleOpenReceipt,
		handleChangeMethod,
		handleChangePaidAmount,
		handleSubmitBill,
		handleCloseReceipt,
		procedureData,
		closeSnackbar,
		transaction_id,
		isExcluded,
		sspAmount,
		handleChangeProductQuantity,
		handleDeleteProduct,
		products,
		handleChangeRate,
		payTypes,
		handleAddProduct,
		handleAddService,
		addProduct,
		addService,
		departments,
		handleCloseAddService,
		handleCloseAddProduct,
		currencies,
		currency,
		usdAmount,
		currencyTotal,
		handleChangeCurrencyRate,
		handleChangeProductCurrencyRate,
		renderPrescription,
		renderService,
		currencyTotalSsp,
		handleChangeExchangeRate,
		handleChangeCurrency,
		exchangeRate,
		isBlocking,
		currencyExchangeRate,
		handlePayProcedure,
		handlePayProduct,
		checkAllProducts,
		checkAllServices,
		handlePayAllProcedures,
		handlePayAllProducts
	};
};
