import React, {useEffect, useState} from "react";
import {useTheme} from "@material-ui/styles";
import ReusablePrescriptionDetails from "./ReusablePrescription";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages} from "../../Helpers/ErrorMessages";
import CustomDialog from "../Utils/Dialogs/CustomDialog";


const returnHeadData = [{name:'Drug Name'},{name:'Quantity Dispensed'},
    {name:'Quantity Used'},{name:'Balance'},
    {name:'Chosen Batch ID(s)', sub_headings: ["Batch ID","Quantity Returned"]},
    {name:'Quantity Returned'}]
const ViewReturnedMedicine = ({actions, openDialog, setOpenDialog, prescriptionIds}) => {

    const [medicineReturn, setMedicineReturn] = useState([]);
    const [state, setState] = useState({});


    /**
     * get the patient details (patient_info)
     */
    useEffect(() => {
        if(!prescriptionIds?.visit_id){
            return;
        }

        axios.get(`${config.smsUrl}/cpharmacy/medicine-return/visit/${prescriptionIds?.visit_id}`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const patient = !dt.patient ? {} : dt.patient;
            const medicine_return = dt.medicine_return_details ? dt.medicine_return_details : []

            setState({
                patient_id: patient.patient_number,
                patient_name: `${!patient.first_name ? "" : patient.first_name} ${!patient.last_name ? "" : patient.last_name}`,
                gender: patient.gender,
                age: patient.age, weight: patient.weight, blood_pressure: patient.blood_pressure,
                reference: patient.reference, visit_id: patient.visit_id, date: patient.begin_datetime,
                policy_number: patient.policy_number, address: patient.address, phone:patient.phone_no
            })

            const arr = medicine_return.map(item=>{
                return {
                ...item,
                product_id:item.product.product_id,
                product_name:item.product.product_name,
                quantity_given:item.prescription_detail.quantity_given,
                quantity_administered:+item.prescription_detail.quantity_given - +item.medicine_return.quantity_to_returned,
                quantity_remaining:+item.medicine_return.quantity_to_returned,
                batches:item.medicine_return.batches.map(item=>({
                    ...item,
                    quantity_returned:item.quantity
                })),
                quantity_returned:item.medicine_return.batches.reduce((item, sum)=> +item + sum.quantity,0)

            }})
            setMedicineReturn(arr)
        }).catch(err => {
            errorMessages(err,null,actions)
        })
    }, [prescriptionIds?.visit_id]);

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    return (
            <CustomDialog open={openDialog} handleClose={() => setOpenDialog(false)} title={'Return Remaining Stock'}
                          maxWidth="xl" isContentOverflow={false}>

                    <ReusablePrescriptionDetails {...{
                        headData: returnHeadData,
                        text: 'Receive Balances',
                        state,
                        isFillPrescription: false
                    }}>
                        <tbody>
                        {medicineReturn.length > 0 ? medicineReturn.map((row, index) => {
                                return (
                                    <tr key={row.product_id}>
                                        <td>{row.product_name}</td>
                                        <td>{row.quantity_given}</td>
                                        <td>{row.quantity_administered}</td>
                                        <td>{row.quantity_remaining}</td>
                                        <td>
                                            <table>
                                                <tbody>
                                                {row.batches?.map((btch, btch_idx) => (
                                                    <tr key={btch_idx}>
                                                        <td style={{width: '30%'}}>
                                                            {btch.batch_id}
                                                        </td>
                                                        <td style={{width: '40%'}}>
                                                            {btch.quantity_returned}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </td>
                                        <td>
                                            {row.quantity_returned}
                                        </td>

                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={returnHeadData.length} align="center"
                                    className={palette.type === 'dark' && 'dark-theme-color'}>No drugs
                                    given
                                </td>
                            </tr>}
                        </tbody>
                    </ReusablePrescriptionDetails>
            </CustomDialog>
    )
};

export {ViewReturnedMedicine};
