import React, {useEffect, useState} from "react";
import TextField from "../../Utils/FormInputs/TextField";
import {ErrorMessage} from "../../Utils/styledComponents";
import SelectInput, {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import {ResizableTextarea} from "../../Utils/FormInputs/Textarea";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {AssetInfo, ViewDetailsComponent} from "./DetailsComponent";
import {useCurrency} from "../../../Context/currency-context";


function DisposeFixedAsset({actions, openDialog, setOpenDialog, isSubmitted, setIsSubmitted,assetDetails}){
    const {currency:selectedCurrency} = useCurrency({actions, openDialog, setOpenDialog, isSubmitted, setIsSubmitted,assetDetails})
    const [state, setState] = useState({date:'', dispose_to: ''})
    const [submitted, setSubmitted] = useState(false);
    const [currencies, setCurrencies] = useState([])
    const [currency, setCurrency] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [receivingAccount, setReceivingAccount] = useState({value:'', label:''})
    const [reason, setReason] = useState({value:'', label:''})
    const [openDetails, setOpenDetails] = useState(false)
    const [totalAmount, setTotalAmount] = useState({received_amount:'', converted_amount:0})


    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`${config.smsUrl}/accounting/chart/cash_ledgers`)
                let _data = res.data
                let dt = _data.data ?? [];
                let credits = dt.map(i => ({label: i.head_name, value: i.id}))
                setAccounts(credits)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [])


    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);


    const calculateConvertedAmount = (is_base, rate, amountReceived) =>{
        console.log(is_base)
        let amount;
       if (is_base.includes('true')){
          amount = +amountReceived * +rate
           setTotalAmount({received_amount: amountReceived, converted_amount: amount})
           return
       }
       if (is_base.includes('false')){
           // amount = +amountReceived / +rate
           amount = +amountReceived * +rate
           setTotalAmount({received_amount: amountReceived, converted_amount: amount})
           return;
       }

    }

    const handleChangeState = (event) =>{
        const {name, value} = event.target
        setState({...state,[name]:value})
        setIsBlocking(true)
    }

    const handleChangeReceivingAcount = (value) =>{
        setReceivingAccount(value)
        setIsBlocking(true)
    }

    const handleChangeReason = (value) =>{
        setReason(value)
        setIsBlocking(true)
    }


    //Change currency
    const handleChangeCurrency = (event) =>{
        setCurrency(event.target.value)
        setIsBlocking(true)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        const ex_rate = +event.target.value > 0 ? 1 / +event.target.value : 0
        setExchangeRate(ex_rate)
        setCurrencyExchangeRate(event.target.value)
        calculateConvertedAmount(currency,ex_rate, totalAmount.received_amount)
        setIsBlocking(true)
    }

    const handleChangeReceivedAmount = (event) =>{
        calculateConvertedAmount(currency, +exchangeRate, +event.target.value)
    }



    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpenDialog(false)
            setIsBlockDialog(false)
            setIsBlocking(false)
        }
    }



    const handleSubmit = (e) => {
        e.preventDefault()
        const isBase = currency.split('-')[1]

        const isNotBase = isBase === 'false'
        const isBaseCurrency = isBase === 'true'

        const currency_id = currency.split('-')[0]
        const data = {
            asset_id:assetDetails?.state?.id,
            disposal_date:state.date,
            receiving_account : receivingAccount?.value,
            dispose_to: state.dispose_to,
            currency_id: +currency_id,
            currency_rate:+exchangeRate,
            reason:reason?.value,
            amount:totalAmount?.received_amount,
            converted_amount:totalAmount?.converted_amount
        }
        const ex_rate = (isNotBase && exchangeRate) || (isBaseCurrency && !exchangeRate)
        setSubmitted(true);
        if (state.date && receivingAccount.value && state.dispose_to && +currency_id && ex_rate && reason.value && totalAmount.received_amount) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cinventory/fixed_asset/sale/add`, data).then(() => {
                actions.snackbarActions.snackSuccess('Asset disposed off successfully')
                setIsSubmitted('resolved')
                handleResetForm()
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }
    }

    const handleResetForm = () =>{
        setOpenDialog(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        setCurrency('')
        setExchangeRate('')
        setReceivingAccount('')
        setReason('')
        setState({date:'', dispose_to: ''})
        setTotalAmount({received_amount: 0, converted_amount: 0})
    }

    const isPending = isSubmitted === 'pending'

    const isBase = currency.split('-')[1]
    const currency_symbol = currency.split('-')[2]
    const isNotBase = isBase === 'false'
    const isBaseCurrency = isBase === 'true'

    return (
        <div>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={() => {
                    return isBlocking;
                }}
                navigate={path => history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title={`Dispose ${assetDetails?.state?.asset_name}`} maxWidth='lg'>
                <ViewDetailsComponent title={assetDetails?.state?.asset_name} open={openDetails} handleOpen={()=>{
                    setOpenDetails(!openDetails)
                }}>
                    <AssetInfo {...assetDetails}/>
                </ViewDetailsComponent>
                <form onSubmit={handleSubmit} autoComplete='off'>
                    <div className="row">
                        <div className="col-lg-6">
                            <Form.Group as={Col}>
                                <Label name="Date" type htmlFor='dispose_date'/>
                                <TextField type='date' value={state.date} name='date' id="dispose_date" submitted={submitted} onChange={handleChangeState}/>
                                {submitted && !state.date ? <ErrorMessage>Date is required</ErrorMessage>: null}
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label name="Dispose to" type htmlFor='dispose_to'/>
                                <ResizableTextarea rows={1} value={state.dispose_to} name='dispose_to' id='dispose_to' submitted={submitted} onChange={handleChangeState}/>
                                {submitted && !state.dispose_to ? <ErrorMessage>Dispose to  is required</ErrorMessage>: null}
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label name="Reason" type/>
                                <CustomSelect value={reason} options={[{value:'Sale', label:'Sale'},{value:'Donation', label:'Donation'}]}
                                              onChange={handleChangeReason} id='dispose_reason'/>

                                {(submitted && !reason) ?
                                    <ErrorMessage>Reason  is required</ErrorMessage> : null}
                            </Form.Group>
                            <Form.Group as={Col}>

                                <Label name="Receiving Account" type/>
                                <CustomSelect value={receivingAccount} options={accounts}
                                              onChange={handleChangeReceivingAcount} id='receiving_account'/>

                                {(submitted && !receivingAccount) ?
                                    <ErrorMessage>Receiving account  is required</ErrorMessage> : null}
                            </Form.Group>

                        </div>
                        <div className="col-lg-6">
                            <Form.Group as={Col}>
                                <Label name="Currency" type htmlFor='currency'/>
                                <SelectInput submitted={submitted} value={currency}
                                             onChange={handleChangeCurrency} options={currencies}
                                             defaultOption="Select..." id='currency'/>
                                {(submitted && !currency) ?
                                    <ErrorMessage>Currency  is required</ErrorMessage> : null}
                            </Form.Group>
                            {isNotBase ?
                                <Form.Group as={Col}>
                                    <Label name="Exchange Rate" htmlFor="exchange_rate"/>
                                    <InputGroup className="">
                                        <InputGroup.Text style={{borderRadius: '0.7rem 0 0 0.7rem'}}
                                                         id="basic-addon1">{currency_symbol}</InputGroup.Text>
                                        <TextField submitted={submitted} type="number" value={currencyExchangeRate}
                                                   onChange={handleChangeExchangeRate} id="exchange_rate"/>
                                        {(submitted && !currencyExchangeRate) ?
                                            <ErrorMessage>Exchange rate  is required</ErrorMessage> : null}
                                    </InputGroup>
                                    <span style={{
                                        marginTop: '5px',
                                        display: 'inline-block',
                                        fontWeight: 700,
                                        color: 'blue'
                                    }}>(1 {selectedCurrency?.currency_symbol} = {currencyExchangeRate} {currency_symbol})</span>
                                </Form.Group> : null}
                            <Form.Group as={Col}>
                                <Label name="Amount Received" htmlFor='received_amount'/>
                                <InputGroup className="">
                                    <InputGroup.Text style={{borderRadius: '0.7rem 0 0 0.7rem'}}
                                                     id="basic-addon1">{currency_symbol}</InputGroup.Text>
                                    <TextField submitted={submitted} type="number" value={totalAmount.received_amount}
                                               onChange={handleChangeReceivedAmount} name='received_amount' id='received_amount'/>
                                    {(submitted && !totalAmount.received_amount) ?
                                        <ErrorMessage>Received amount  is required</ErrorMessage> : null}
                                </InputGroup>
                            </Form.Group>

                            {currency && totalAmount.received_amount && isNotBase ? <Form.Group as={Col}>
                                <Label name={`Amount(${selectedCurrency?.currency_symbol})`} />
                                <InputGroup className="">
                                    <InputGroup.Text style={{borderRadius: '0.7rem 0 0 0.7rem'}}
                                                     id="basic-addon1">{selectedCurrency?.currency_symbol}</InputGroup.Text>
                                    <TextField  type="number" value={totalAmount.converted_amount}
                                               disabled/>
                                </InputGroup>
                            </Form.Group>: null}
                        </div>
                    </div>
                    <button type='submit' disabled={isPending}
                            className='btn btn-sm sms-btn'>{isPending ? 'Submitting...' : 'Submit'}</button>
                </form>
            </CustomDialog>
        </div>

    )
}

export {DisposeFixedAsset}