import React, {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";


export const usePreviousInpatientTreatment = (actions,patient_admission_id) =>{
    const [treatmentHistory, setTreatmentHistory] = useState([])
    const [loading, setLoading] = useState('idle')
    const [isSubmitted, setIsSubmitted] = useState('idle');

    const isResolved = isSubmitted === 'resolved'

    useEffect(()=>{
        const formData = new FormData();
        formData.append('patient_admision_id',patient_admission_id)
        setLoading('pending')
        axios.post(`${config.smsUrl}/cmedicationround/get_previous_treatment`,formData).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.data ?? [];
            setTreatmentHistory(arr);
            setLoading('resolved')
        }).catch(err=>{
            errorMessages(err, null, actions)
            setLoading('rejected')
        })
    },[isResolved])

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

    return {treatmentHistory,  loading, closeSnackbar, isSubmitted, setIsSubmitted}
}