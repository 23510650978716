import React, {useEffect} from 'react'
import DentalPatientDetails from "./DentalPatientDetails";
import DentalDashboard from "./DentalDashboard";
import {
    Button,
    PatientNotesContainer,
    StyledAdd
} from "../DoctorsModule/Notes/PatientNotesContainer";
import {RightAlignedContainer} from "../Utils/styledComponents";
import {ActionParagraph, NotFound, StyledImage} from "../DoctorsModule/Notes/PreviousNotesTable";
import {AccountingDrawer} from "../Utils/AccountingUtils/AccountingDrawer";
import AddServiceRequestForm from "./AddServiceRequestForm";
import {DataCell, ModuleTable} from "../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import styled from "@emotion/styled/macro";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {LoadingGif} from "../Utils/Loader";
import Form from 'react-bootstrap/Form'
import Label from "../Utils/FormInputs/Label";
import {CustomResizableTextarea} from "../Inventory/CustomInput";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {FinishedStatus, PendingStatus, Processing, StyledIcon, StyledLibraryIcon} from "../DoctorsModule/doctorStyles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import DentalServiceReport from "./DentalServiceReport";
import {usePrint} from "../Utils/Templates/usePrint";



const headData = [{item:'Service'}, {item:'Status'},{item:'Results'}, {item:'Action'}];
function DentalServicesRequest(props) {
    const {actions, snackbars} = props
    const {patient_number, visit_id} = props.match.params
    const [openServiceDialog, setOpenServiceDialog] = React.useState(false)
    const [dentalServices, setDentalServices] = React.useState({serviceData:[], status:'idle'})
    const [isSubmitted, setIsSubmitted] = React.useState('idle')
    const [openRecordResults, setOpenRecordResults] = React.useState(false)
    const [requestedService, setRequestedService] = React.useState({})
    const [submitted, setSubmitted] = React.useState(false)
    const [isSubmittedResults, setIsSubmittedResults] = React.useState('idle')
    const [results, setResults] = React.useState({})
    const [openDentalResults, setOpenDentalResults] = React.useState(false)

    const formRef = React.useRef()

    const isResolved = isSubmitted === 'resolved'

    const isResolvedResults = isSubmittedResults === 'resolved'



    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;

    useEffect(() => {
            const formData = new FormData();
            formData.append('visit_id', visit_id);
            setDentalServices({...dentalServices, status: 'loading'})
            axios.post(`${config.smsUrl}/cdoctor/get_previous_procedure_by_visit_id`, formData).then(res => {
                const resp = res.data;
                const data = !resp ? {} : resp;
                const arr = data.procedure ? data.procedure : []
                // const services = groupByDate(arr)
                // const prods = services.map(item=>({...item,open:true}))
                setDentalServices({...dentalServices, serviceData: arr, status: 'success'})
            }).catch(error => {
                logoutErrorMessage(error, null, actions)
                setDentalServices({...dentalServices, status: 'error'})
            });
    }, [isResolved, isResolvedResults])


    const handleOpenAddService = () =>{
        setOpenServiceDialog(true)
    }

    const handleCloseAddService = () =>{
        setOpenServiceDialog(false)
    }

    const retrieveResultDetails = (row) =>{
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        formData.append('dental_result_id', row.dental_result_id);
        axios.post(`${config.smsUrl}/cdental/dental_results_update_form`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? {} : resp;
            setResults({...results, ...data,...row})
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
    }

    const handleOpenDentalResults = (item) =>{
        setRequestedService(item)
        if (item.dental_result_id){
            retrieveResultDetails(item)
        }
        setOpenDentalResults(true)
    }

    const handleCloseDentalResults = () =>{
        setOpenDentalResults(false)
    }

    const handleOpenResults = (item) =>{
        setRequestedService(item)
        if (item.dental_result_id){
            retrieveResultDetails(item)
        }
        setOpenRecordResults(true)
    }

    const handleCloseResults = () =>{
        setOpenRecordResults(false)
    }



    const handleSubmitResults = (event) => {
        event.preventDefault();
        const formData = new FormData(formRef.current)
        const results = formData.get('results')
        setSubmitted(true)
        const {service_id} = requestedService

        const params = requestedService.dental_result_id ?
            {
                dental_result_id: requestedService.dental_result_id,
                dental_results: {
                    dental_result_id: requestedService.dental_result_id,
                    procedure_payment_id: requestedService.procedure_payment_id,
                    bill_id: requestedService.bill_id,
                    results, visit_id, service_id, user_roles_id
                }
            } : {
                procedure_payment_id: requestedService.procedure_payment_id,
                bill_id: requestedService.bill_id,
                results,
                visit_id,
                service_id,
                user_roles_id
            }
        const url = requestedService.dental_result_id ? 'dental_results_update' : 'add_dental_results'
        if (results) {
            setIsSubmittedResults('pending')
            axios.post(`${config.smsUrl}/cdental/${url}`, params).then(() => {
                actions.snackbarActions.snackSuccess(`Dental results ${requestedService.dental_result_id ? 'updated' : 'recorded'} successfully`);
                setIsSubmittedResults('resolved');
                setSubmitted(false)
                setOpenRecordResults(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmittedResults('rejected');
            })
        }
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const {serviceData, status} = dentalServices


    const isLoading = status === 'loading'
    const isSuccess = status === 'success'
    const isError = status === 'error'
    const {openBar, type, message} = snackbars;

    const isPending = isSubmittedResults === 'pending'
    const {componentRef, handlePrint} = usePrint()
    return (
        <DentalDashboard {...{...props, title:"Dental Examination"}}>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <DentalPatientDetails {...{patient_number}}/>
            <AccountingDrawer open={openServiceDialog} title="Request service" handleClose={handleCloseAddService}>
                <AddServiceRequestForm {...{visit_id, isSubmitted, setIsSubmitted,setOpenServiceDialog}}/>
            </AccountingDrawer>

            <CustomDialog handleClose={handleCloseDentalResults} open={openDentalResults}
                          title={`${requestedService?.procedure_name} Results`}>
                <RightAlignedContainer>
                    <button type='button' onClick={handlePrint} className='btn btn-sm small-btn sms-amber-btn'>Print</button>
                </RightAlignedContainer>
                <div ref={componentRef}>
                    <DentalServiceReport {...{patient_number, dental_services: [{...results,service_name:results?.procedure_name}]}}/>
                </div>

            </CustomDialog>

            <AccountingDrawer open={openRecordResults} title={`${requestedService.dental_result_id ? 'Update': 'Record'} results for ${requestedService.procedure_name}`} handleClose={handleCloseResults}>
                <form ref={formRef} onSubmit={handleSubmitResults}>
                    {/*<Label name={<strong>{requestedService?.procedure_name}</strong>}/>*/}
                    <Form.Group>
                        <Label name={`Results for ${requestedService?.procedure_name}`} type htmlFor='results'/>
                        <CustomResizableTextarea name='results' id='results' rows={3} reset={isResolvedResults || isResolved} isRequired
                                                 placeholder='Write results...' submitted={submitted} inputValue={results?.results}/>
                    </Form.Group>
                    <RightAlignedContainer>
                        <button type='submit' className='btn btn-sm sms-info-btn small-btn'>{isPending ? 'Saving...':'Save'}</button>
                    </RightAlignedContainer>

                </form>
            </AccountingDrawer>
            <PatientNotesContainer heading="Requested services" button={
                serviceData.length ? <RightAlignedContainer>
                    <Button onClick={handleOpenAddService}><StyledAdd/>Add</Button>
                </RightAlignedContainer>: null}>
                {isLoading ? <LoadingGif/>: null}
                {isSuccess && serviceData.length > 0 ?
                    <ModuleTable headData={headData}>
                        {serviceData.map((row, index) => {
                            const status = {
                                'Pending': <PendingStatus>Pending</PendingStatus>,
                                'Paid': <Processing>Paid</Processing>,
                                'Done':<FinishedStatus>Done</FinishedStatus>,
                                 null: <PendingStatus>Pending</PendingStatus>
                            }

                            const buttons = (
                                <>
                                    <Tooltip title='View results'>
                                        <IconButton onClick={()=>handleOpenDentalResults(row)}>
                                            <StyledLibraryIcon/>
                                        </IconButton>
                                    </Tooltip>
                                   <Tooltip title='Edit Results'>
                                        <IconButton onClick={()=>handleOpenResults(row)}>
                                            <StyledIcon/>
                                        </IconButton>
                                    </Tooltip>

                                </>
                            )

                            const isPaid = row.pay_status === 1;
                            const isInsurance = row.pay_status === 3;
                            const isDone = row.item_status === 'Done' || row.dental_result_id;
                            const isAccumulatedBill = row.accumulated_bill
                            return(
                                <TableRow key={index}>
                                    <DataCell data-testid={`procedure_name-${index}`}>{row.procedure_name}</DataCell>
                                    <DataCell data-testid={`procedure_status-${index}`}>{status[row.item_status]}</DataCell>
                                    <DataCell>{row.result}</DataCell>
                                    <DataCell data-testid={`record_results-${index}`}>
                                        {(isPaid || isInsurance || isAccumulatedBill) && !isDone ? <button type='button' onClick={()=>handleOpenResults(row)}
                                                                     className='btn sms-info-btn small-btn btn-sm'>Record results</button>: null}
                                        {(isPaid || isInsurance || isAccumulatedBill) && isDone && row.dental_result_id ? buttons: null}
                                    </DataCell>
                                </TableRow>

                            )})}

                    </ModuleTable>: <NotFound text='There are no dental services to display for this patient'  image={(
                        <StyledImage src="/images/examination.png" alt="clipboard"/>
                    )}>
                        <ActionParagraph id="record_notes" onClick={handleOpenAddService}>Request service(s)</ActionParagraph>
                    </NotFound>}
                {isError ? <p>The server did not return a valid response</p>:null}
            </PatientNotesContainer>
        </DentalDashboard>
    )
}
function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DentalServicesRequest);