function formatAmount(amount) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    
    return formatter.format(amount)
}

function formatDigits(amount) {
  const formatter = new Intl.NumberFormat('en-US',{
      style: 'currency',
      currency: 'USD',
      currencyDisplay: "code",
  });
  return formatter.format(amount).replace("USD", "").trim()
}


function formatNumbers(amount) {
    const formatter = new Intl.NumberFormat('en-US');
    return formatter.format(amount)
}

function formatSsp(amount) {
    let formattedString = amount.toString().replace(/(\.\d*?[1-9])0+$/, '');
    const formatter = new Intl.NumberFormat('en-US');
    return formatter.format(parseFloat(formattedString))
}
export {formatAmount, formatDigits, formatSsp, formatNumbers}