import React from "react";
import PageTitle from "../../Utils/smsTitle";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import { getComparator, stableSort } from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import { faShippingFast } from "@fortawesome/free-solid-svg-icons/faShippingFast";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { usePurchaseList } from "./hooks/usePurchaseList";
import { dateStringConvert } from "../../Utils/ConvertDate";
import { DataExportButton } from "../../Utils/Buttons/DataExportationButton";
import { makeStyles } from "@material-ui/core/styles";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import { ViewPurchaseTemplate } from "./ViewPurchase";
import { RightAlignedContainer } from "../../Utils/styledComponents";
import { ReusableRouteTabs } from "../../Utils/Dialogs/ReusableTabs";
import { history } from "../../../Helpers/history";
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";

const useStyles = makeStyles((theme) => ({
  received: {
    color: "green",
  },
  purchased: {
    color: "#1d5dd3",
  },
  canceled: {
    color: "#D9512C",
  },
  pending: {
    color: "#F29339",
  },
}));

const headData = [
  { id: "sl", numeric: false, disablePadding: false, label: "SL" },
  { id: "date", numeric: false, disablePadding: false, label: "Purchase Date" },
  {
    id: "invoice_no",
    numeric: false,
    disablePadding: false,
    label: "Invoice No.",
  },
  {
    id: "purchase_id",
    numeric: false,
    disablePadding: false,
    label: "Purchase#",
  },
  {
    id: "supplier_name",
    numeric: false,
    disablePadding: false,
    label: "Supplier Name",
  },
  {
    id: "total_amount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
  },
  { id: "currency", numeric: false, disablePadding: false, label: "Currency" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "actions", numeric: false, disablePadding: true, label: "Action" },
];

export const PurchaseList = (props) => {
  const propsObj = { ...props, status: [1, 2] };
  const {
    searchValue,
    order,
    orderBy,
    loading,
    purchases,
    openDelete,
    activePage,
    total_count,
    handleSearch,
    handleChangePage,
    handleOpenDelete,
    handleCloseDelete,
    downloadPdf,
    closeSnackbar,
    handleRequestSort,
    csvRef,
    allRecords,
    downloadCsv,
    openReverse,
    handleCloseReverse,
    handleOpenReverse,
    handleReverse,
    draftDialog,
    productPurchase,
    isErrorPurchase,
    isLoadingPurchase,
    isSuccessPurchase,
    handleOpenDraftDialog,
    handleCancelDraft,
    handleCloseDraftDialog,
    handleCancelPurchase,
    submitted,
    state,
    setState,
  } = usePurchaseList(propsObj);
  const isFilter = false;

  const { start_date, end_date } = state;

  const { supplierDetails, products } = productPurchase;
  const headers = [
    { label: "Purchase Date", key: " purchase_date" },
    {
      label: "Purchase ID",
      key: " product_purchase_id",
    },
    { label: "Supplier", key: "supplier_name" },
    { label: "Total Amount", key: "  total_amount" },
  ];
  const { openBar, type, message } = props.snackbars;
  const classes = useStyles();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const buttons = (
    <>
      <button
        className="btn sms-info-btn  btn-sm mr-3"
        onClick={handleOpenDraftDialog}
      >
        Continue
      </button>
      <button className="btn sms-gray-btn btn-sm" onClick={handleCloseDelete}>
        No
      </button>
    </>
  );

  const components = [
    { label: "All Purchases", path: "/purchaselist" },
    { label: "Canceled Purchases", path: "/canceled-purchases" },
    { label: "Drafted Purchases", path: "/drafted-purchases" },
  ];

  return (
    <div data-testid="purchase-list" className="journals">
      <PageTitle title="Purchases" />
      <SubHeader title="Inventory" subTitle="Purchases">
        <FontAwesomeIcon icon={faShippingFast} />
      </SubHeader>
      <div className="general-ledger-header">
        <RightAlignedContainer>
          <Link to="/addpurchase">
            <button className="btn sms-btn btn-sm small-btn mr-4">
              <FontAwesomeIcon icon={faPlus} /> Add Purchase
            </button>
          </Link>
          <DataExportButton
            data={allRecords}
            title="Purchase List"
            csvRef={csvRef}
            exportCsv={downloadCsv}
            downLoadPdf={downloadPdf}
            headers={headers}
          />
        </RightAlignedContainer>
      </div>
      <CustomDialog
        handleClose={handleCloseDelete}
        open={openDelete}
        buttons={buttons}
        title="Cancel Purchase"
        isContentOverflow={false}
        maxWidth="md"
        isPaperStyle
      >
        <p
          style={{ marginLeft: 0, marginRight: 0, marginTop: 0 }}
          className="text-center"
        >
          {" "}
          You are about to cancel a purchase, are you sure you want to continue?
        </p>
        <ViewPurchaseTemplate
          {...{
            state: supplierDetails,
            products,
            isErrorPurchase,
            isLoadingPurchase,
            isSuccessPurchase,
          }}
        />
      </CustomDialog>
      <CustomDialog
        handleClose={handleCloseDraftDialog}
        open={draftDialog}
        title="Use purchase as draft"
        isContentOverflow={true}
      >
        <p style={{ marginLeft: 0, marginRight: 0 }} className="text-center">
          Would you like to use this purchase as a draft?
        </p>
        <RightAlignedContainer>
          <button
            className="btn sms-info-btn btn-sm mr-3"
            onClick={handleCancelPurchase}
          >
            Yes
          </button>
          <button
            className="btn sms-grey-btn btn-sm"
            onClick={handleCancelDraft}
          >
            No
          </button>
        </RightAlignedContainer>
      </CustomDialog>
      <ReusableRouteTabs
        value={history.location.pathname}
        tabHeading={components}
      />
      <div className="mui-tables">
        {/*<DeleteDialog message='cancel' openDialog={openDelete} handleClose={handleCloseDelete}*/}
        {/*                    text="purchase" title='Cancel Purchase'>*/}
        {/*         */}
        {/*</DeleteDialog>*/}

        <DeleteDialog
          message="reverse"
          openDialog={openReverse}
          handleClose={handleCloseReverse}
          text="purchase"
          title="Reverse Purchase"
        >
          <button
            className="btn sms-info-btn btn-sm mr-3"
            onClick={handleReverse}
          >
            Continue
          </button>
          <button
            className="btn sms-grey-btn btn-sm"
            onClick={handleCloseReverse}
          >
            No
          </button>
        </DeleteDialog>
        <DateTimeFilter
          {...{
            start_date,
            end_date,
            handleChange,
            isFilter,
            submitted,
            // handle_submit,
            isSuccessPurchase,
            type: "date",
          }}
        />
        <MainSnackbar
          variant={type}
          open={openBar}
          message={message}
          handleCloseBar={closeSnackbar}
        />
        <CustomTable
          title="Purchases"
          handleChangeNextPage={handleChangePage}
          total_count={total_count}
          headData={headData}
          handler={handleSearch}
          activePage={activePage}
          records={10}
          handleRequestSort={handleRequestSort}
          data={purchases}
          colSpan={5}
          order={order}
          orderBy={orderBy}
          term={searchValue}
          customPage
        >
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={headData.length} align="center">
                  <img src="/images/smsloader.gif" alt="loader" />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : purchases.length > 0 ? (
            <TableBody>
              {stableSort(purchases, getComparator(order, orderBy))
                .slice(0, 10)
                .map((item, index) => {
                  const styles = {
                    1: classes.pending,
                    2: classes.received,
                    9: classes.canceled,
                  };
                  const text = {
                    1: "PURCHASED",
                    2: "RECEIVED",
                    9: "CANCELED",
                  };
                  const button = (
                    <>
                      {item.status === 1 ? (
                        <button
                          type="button"
                          onClick={() =>
                            handleOpenDelete(item.product_purchase_id)
                          }
                          className="btn sms-gray-btn btn-sm mb-2"
                        >
                          Cancel
                        </button>
                      ) : null}
                      {item.status === 2 ? (
                        <button
                          type="button"
                          onClick={() =>
                            handleOpenReverse(item.product_purchase_id)
                          }
                          className="btn sms-btn btn-sm mb-2"
                        >
                          Reverse
                        </button>
                      ) : null}
                    </>
                  );
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className="mui-table-row"
                    >
                      <TableCell>{item.sl}</TableCell>
                      <TableCell>
                        {item.purchase_date
                          ? dateStringConvert(item.purchase_date)
                          : ""}
                      </TableCell>
                      <TableCell>{item.chalan_no}</TableCell>
                      <TableCell>{item.product_purchase_id}</TableCell>
                      <TableCell>{item.supplier_name}</TableCell>
                      <TableCell>{item.total_amount}</TableCell>
                      <TableCell>{item.currency_symbol}</TableCell>
                      <TableCell>
                        <span className={styles[item.status]}>
                          {text[item.status]}
                        </span>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{
                            pathname: `/view-purchase/${item.product_purchase_id}/active`,
                          }}
                        >
                          <button
                            className="btn btn-sm sms-info-btn mr-2 mb-2"
                            id="view_invoice"
                          >
                            View
                          </button>
                        </Link>
                        {button}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={headData.length} align="center">
                  No records found
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </CustomTable>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { snackbars: state.snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseList);
