import React from 'react';
import {useTheme} from "@material-ui/core/styles";
import TextField from "../../Utils/FormInputs/TextField";
import Form from "react-bootstrap/Form";
import {FinishedStatus} from "../../DoctorsModule/doctorStyles";




export const ReusableBillTable = ({title, headData, services, transaction_id, handleDelete,
    children, handleChangeRate,isInPatient,handleChangeCurrencyRate,handlePayProcedure,
    checkAllServices, handlePayAllProcedures,isPatientDeposit, isCurrency=false}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const isLab = services.some(item=>item.is_external === true || item.is_external === false)
    return (
        <div className='table-responsive'>
            <table className="table table-bordered table-sm billing-invoice-table  strike-table">
                <thead>
                <tr>
                    <td align="center" colSpan={headData.length}>{title}</td>
                </tr>
                <tr>
                    {headData.map((item, index) => {
                        return  item === 'Pay' ? (<td key={index}>
                                <Form.Check type='checkbox' label={item} id='pay_all_services'
                                          checked={checkAllServices} onChange={handlePayAllProcedures}/>
                            </td>):
                            (<td key={index}>{item}</td>)
                    })}
                </tr>
                </thead>
                <tbody>
                {services.map((item, idx) => (
                    <tr key={item.service_id}>
                        <td className={item.isExcluded ? 'strike-out' : ''} style={{color: palette.type === "dark" ? "white" : "black"}}>{item.service_name}</td>
                        <td className={item.isExcluded ? 'strike-out' : ''} style={{color: palette.type === "dark" ? "white" : "black"}}>{item.quantity}</td>
                        {isCurrency ? <td className={item.isExcluded ? 'strike-out' : ''} style={{color: palette.type === "dark" ? "white" : "black"}}>
                            <TextField   value={(item.currency_rate).toFixed(3)} onChange={e => handleChangeCurrencyRate(e, item.bill_id)}
                                        disabled/>
                        </td>: null}
                        <td className={item.isExcluded ? 'strike-out' : ''} style={{color: palette.type === "dark" ? "white" : "black"}}>
                            <TextField   value={item.rate} onChange={e => handleChangeRate(e, item.bill_id)}
                                    disabled/>
                        </td>

                        {isCurrency ? <td className={item.isExcluded ? 'strike-out' : ''} style={{color: palette.type === "dark" ? "white" : "black"}}>
                            {(item.currency_total).toFixed(3)}
                        </td>: null}
                        <td className={item.isExcluded ? 'strike-out' : ''} style={{color: palette.type === "dark" ? "white" : "black"}}>{(item.subTotal).toFixed(3)}</td>
                        {(transaction_id && !isInPatient) &&
                        <td valign="bottom" align="center"
                            style={{color: palette.type === "dark" ? "white" : "black"}}>{item.status === 1 ?
                            <span className="lab-status
                        finished px-1">Paid</span> : <span className="lab-status pending px-1">Unpaid</span>}</td>}
                        {isLab ?  <td className={item.isExcluded ? 'strike-out' : ''}
                            style={{color: palette.type === "dark" ? "white" : "black"}}>
                            {item.is_external === true  ? 'External Test' :  item.is_external === false ?  'Internal Test':''}
                        </td>: null}
                        {!isPatientDeposit ? <td style={{verticalAlign:'middle'}}>
                            { item.pay_status === 0 ?
                                <Form.Check type='checkbox' checked={item.isPaid} onChange={(e)=>handlePayProcedure(e,item)}/>:
                                <FinishedStatus>Paid</FinishedStatus>
                            }
                            {/*<button type="button"*/}
                            {/*    disabled={item.item_status === "Done"}*/}
                            {/*        className={`${item.isExcluded ? ' sms-info-btn ' : 'sms-btn-dismiss'} btn btn-sm`}*/}
                            {/*        id="delete_service"*/}
                            {/*        onClick={() => handleDelete(item)}>*/}
                            {/*    {item.isExcluded ? 'Include' : 'Exclude'}*/}
                            {/*</button>*/}
                        </td>: null}

                        </tr>
                    ))}
                    {children}
                    </tbody>

                </table>
            </div>

    )
}

//products table
export const ProductsTable = ({products, handleDeleteProduct,children, handleChangeProductRate,handleChangeProductCurrencyRate,
                                  handlePayProduct, checkAllProducts, handlePayAllProducts,isPatientDeposit, headData=[], isCurrency=false}) =>{
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <div className='table-responsive'>
        <table className="table table-bordered table-sm billing-invoice-table strike-table">
            <thead>
            <tr>
                <td align="center" colSpan={9}>Product(s)</td>
            </tr>
            <tr>
                {headData.map((item, index) => {
                   return  item === 'Pay' ? (<td key={index}><Form.Check type='checkbox' label={item} id='pay_all_products'
                                                         checked={checkAllProducts} onChange={handlePayAllProducts}/></td>):
                       (<td key={index}>{item}</td>)
                })}
            </tr>
            </thead>

            <tbody>
            {products.map((item, idx) =>{
                 return(<tr key={item.product_id}>
                <td className={item.isExcluded ? 'strike-out' : ''} style={{color: palette.type === "dark" ? "white" : "black"}}>
                    {item.product_name }
                        
                </td>
                <td className={item.isExcluded ? 'strike-out' : ''} align="center" style={{color: palette.type === "dark" ? "white" : "black"}}>
                    {item.dosage}
                </td>

                <td className={item.isExcluded ? 'strike-out' : ''} align="center" style={{color: palette.type === "dark" ? "white" : "black"}}>
                    {item.frequency}
                </td>
                <td className={item.isExcluded ? 'strike-out' : ''} align="center" style={{color: palette.type === "dark" ? "white" : "black"}}>
                    {item.number_of_days}
                </td>
                <td className={item.isExcluded ? 'strike-out' : ''}>
                    {item.quantity}
                </td>
                 {isCurrency ? <td className={item.isExcluded ? 'strike-out' : ''} align="center" style={{color: palette.type === "dark" ? "white" : "black"}}>
                    <TextField value={(item?.currency_rate).toFixed(3)} onChange={e => handleChangeProductCurrencyRate(e, item.bill_id)}
                                disabled/></td>:null}
                <td className={item.isExcluded ? 'strike-out' : ''} align="center" style={{color: palette.type === "dark" ? "white" : "black"}}>
                    <TextField value={item.rate} onChange={e => handleChangeProductRate(e, item.bill_id)}
                               type='number' disabled/>
                </td>
                 {isCurrency ? <td className={item.isExcluded ? 'strike-out' : ''} align="center">
                    {(item.currency_total).toFixed(3)}
                </td>: null}

                <td className={item.isExcluded ? 'strike-out' : ''} align="center">
                    {(item.subTotal).toFixed(3)}
                </td>
                 {!isPatientDeposit ? <td>
                     {item.pay_status === 0 ?
                    <Form.Check type='checkbox' checked={item.isPaid}
                                onChange={(e)=>handlePayProduct(e,item)}/>: <FinishedStatus>Paid</FinishedStatus>}
                    {/*<button style={{verticalAlign:'middle'}} type="button"*/}
                    {/*        disabled={item.item_status === "Offered"}*/}
                    {/*                className={`${item.isExcluded ? ' sms-info-btn  ' : 'sms-btn-dismiss'} btn btn-sm`}*/}
                    {/*                id="delete_product"*/}
                    {/*                onClick={() => handleDeleteProduct(item)}>*/}
                    {/*            {item.isExcluded ? 'Include' : 'Exclude'}*/}
                    {/*        </button>*/}
                </td>: null}
            </tr>)})}
            {children}
            </tbody>
        </table>
        </div>
    )
}