/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react'
import { history } from '../../../Helpers/history';
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import { formatDateTime } from '../../Utils/ConvertDate';
import Card from '@material-ui/core/Card'
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import styled from "@emotion/styled/macro";
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";
import React, {useEffect, useState} from "react";
import {useVersionsContext} from "../../../Context/versions-context";
import {formatDigits, formatSsp} from "../../Utils/formatNumbers";
import {useCurrency} from "../../../Context/currency-context";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {groupProductId, groupServiceId} from "../utils/SanitizeArr";


const textWeight = css`
    font-weight:600;
`



const StyledContainer = styled(RightAlignedContainer)({
    marginBottom:'5px'
})

function BillingReceipt(props) {
    const {visit_id, payment_id} = props.match.params;
    const {actions} = props;

    const {currency:selectedCurrency} = useCurrency(props)
    const [receipt, setReceipt] = useState({});
    const [services, setServices] = useState([]);
    const [total_amount, setTotalAmount] = useState({currency_total:0, usd_total:0,
        currency_paid_amount:0,paid_amount: 0, due_amount: 0,currency_due_amount:0})
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)
    const [currency, setCurrency] = useState('')
    const [currencies, setCurrencies] = useState([])
    const [recordedCurrency, setRecordedCurrency] = useState({})


    useEffect(()=>{
        if (recordedCurrency.currency_symbol){
            return
        }
        setCurrency(`${selectedCurrency?.currency_symbol}-${selectedCurrency?.is_base_currency}`)
    },[recordedCurrency.currency_symbol])

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.currency_symbol}-${item.is_base_currency}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/view_receipt_by_payment_id`, {params:{visit_id:+visit_id,payment_id:+payment_id}})
        .then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            const info = !all_data.patient_info ? {} : all_data.patient_info;
            const bills = !all_data.bill ? [] : all_data.bill;
            const payment = all_data.payment_details ? all_data.payment_details : {}

            const exchange_rate = payment.currency_rate ? payment.currency_rate : 0;

            const ex_rate = exchange_rate ?  Math.pow(exchange_rate, -1) : 0;

            const symbol = payment.currency_symbol ? payment.currency_symbol : selectedCurrency?.currency_symbol
            const is_base = !(payment.is_base_currency == null || undefined) ? payment.is_base_currency : selectedCurrency?.is_base_currency

            setCurrency(`${symbol}-${is_base}`)
            setCurrencyExchangeRate(ex_rate)
            setReceipt({...info, ...payment});
            setRecordedCurrency(payment)

            convertList(exchange_rate, payment.amount_paid, payment.balance, bills)
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, []);

    const totalCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.usd_total)
        }, 0)
    }

    const totalNonCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.currency_total)
        }, 0)
    }

    const convertList = (ex_rate,paidAmount,balance=0,arr=[]) =>{
        const paid_amt =  paidAmount / ex_rate
        const currency_balance = ex_rate ?  balance / ex_rate :  balance

        const productArr = groupProductId(arr.filter(item=>item.product_id))
        const serviceArr = groupServiceId(arr.filter(item=>item.service_id))
        const invArr = [...serviceArr, ...productArr].map(item=>{
            const qty = item.quantity ? item.quantity : 0;
            const rate = item.rate ? item.rate : 0
            const total = rate * qty
            return {
                ...item,
                item_name: item.service_id ? item.service_name : item.product_id ? item.product_name : '',
                currency_rate: ex_rate ?  item.rate / ex_rate : item.rate,
                usd_rate:item.rate,
                usd_total : total,
                currency_total: (ex_rate ?  item.rate / ex_rate : item.rate) *  (!item.quantity ? 0 : item.quantity),
            }})

        const t_amount = totalCurrency(invArr)
        const t_currency = totalNonCurrency(invArr)

        setTotalAmount({usd_total: t_amount, currency_total: t_currency,
            currency_paid_amount: paid_amt, currency_due_amount: currency_balance,
            paid_amount: paidAmount, due_amount: balance,})

        setServices(invArr);
    }

    //Change currency
    const handleChangeCurrency = (e) =>{
        setCurrency(e.target.value)


    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setCurrencyExchangeRate(event.target.value)
        const currency_ex_rate = +event.target.value > 0 ?  1 / +event.target.value : 0
        const {paid_amount, due_amount} = total_amount
        convertList(+currency_ex_rate, +paid_amount, +due_amount , services)

    }


    const {currency_total, usd_total,paid_amount,due_amount,currency_due_amount, currency_paid_amount} = total_amount


    const isBaseCurrency = currency && currency.split('-')[1] === 'true'
    const currency_symbol = currency && currency.split('-')[0]

    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'




    const handleOnAfterPrint = () => {
        history.push('/paymentList');
    }

    const {componentRef, handlePrint,fontSize}  = usePrint(`BillingReceipt`,handleOnAfterPrint)

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key:'rate',value:`Rate(${currency_symbol})`}, {key:'amount',value:`Amount(${currency_symbol})`}]
  const patientDetails = (
      <tbody>
          <tr>
              <td><span>Date: </span> <span css={[textWeight]}>{formatDateTime(receipt.date)}</span></td>
              <td><span>Patient Name: </span> <span css={[textWeight]}>{`${!receipt.patient_firstname ? "" : receipt.patient_firstname} 
                                ${!receipt.patient_lastname ? "" : receipt.patient_lastname}`}
                </span> </td>


          </tr>
          <tr>
              <td><span>Cashier: </span> <span><strong>{`${receipt.first_name ? receipt.first_name : ''} ${receipt.last_name ? receipt.last_name : ''}`}</strong></span></td>
              <td><span>Contact: </span> <span css={[textWeight]}>{receipt.phone_no}</span></td>
          </tr>
          <tr>

              <td><span>Patient Number: </span> <span css={[textWeight]}>{receipt.patient_number}</span></td>
              <td><span>Address:</span> <span css={[textWeight]}>{receipt.address}</span></td>
          </tr>
      </tbody>
  )
  const itemDetails = (
    <ItemsTable {...{headData}}>
      <tbody>
      {services.map((item) => {
            return (
                <tr key={item.bill_id}>
                    <td><span>{item.item_name}</span></td>
                    <td><span>{item.quantity}</span></td>
                    <td><span>{isBaseCurrency ?  formatDigits(item.usd_rate) :   formatSsp(item.currency_rate) }</span></td>
                     <td><span>{isBaseCurrency ? formatDigits(item.usd_total) :  formatSsp(item.currency_total)}</span></td>
                </tr>
            )
        })}
          <tr>
              <td colSpan={headData.length -1}  align='right'><span css={[textWeight]}>Total Amount({currency_symbol})</span></td>
              <td ><span css={[textWeight]}>{isBaseCurrency ? formatDigits(Number(usd_total)) :  formatSsp(Number(currency_total))}</span></td>
          </tr>
          <tr>
              <td colSpan={headData.length -1}  align='right' ><span css={[textWeight]}>Amount Received({currency_symbol})</span></td>
              <td><span css={[textWeight]}>{isBaseCurrency ? formatDigits(Number(paid_amount)):  formatSsp(Number(currency_paid_amount))}</span></td>
          </tr>
          <tr>
              <td colSpan={headData.length -1}  align='right'  ><span css={[textWeight]}>Balance({currency_symbol})</span></td>
              <td><span css={[textWeight]}>{isBaseCurrency ? formatDigits(Number(due_amount)) :  formatSsp(Number(currency_due_amount))}</span></td>
          </tr>
      </tbody>
    </ItemsTable>
  )
  return (
      <div>
        <PageTitle title="Billing Receipt"/>
        <SubHeader title="Billing" subTitle="Billing receipt">
            <FontAwesomeIcon icon={faCoins}/>
        </SubHeader>
        <MiniCenteredDiv>
            <BackButton to='/outpatientreceipts' text='Billing List' data-testid="billing-receipt-back-button"/>
            <StyledContainer>
              <Button variant='orange' onClick={handlePrint}>Print</Button>
            </StyledContainer>
            <Card>
                {isPremium ? <div style={{padding:'8px 60px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                    <RightAlignedContainer>
                        <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />
                        { !isBaseCurrency ? <FxRate currency={selectedCurrency?.currency_symbol} value={currencyExchangeRate}  handleChangeRate={handleChangeExchangeRate} nonBaseCurrency={currency_symbol}/>: null}
                    </RightAlignedContainer>
                </div>: null}
                <div ref={componentRef} style={{padding:'60px'}} className="requisition-receipt">
                    <style>
                        {fontSize()}
                    </style>
                    <PrintTemplate title='RECEIPT' {...{patientDetails,itemDetails}}/>
                </div>
            </Card>
        </MiniCenteredDiv>
      </div>
  )
}

export default BillingReceipt