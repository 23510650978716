import React from "react";

export function BillingSubheading({links}) {
    return (
        <div className="general-ledger-header inpatient-billing-header">
            {links.map((item, index)=>{
                return (
                    <a
                        key={index}
                        className={`billing-span btn btn-sm ${
                            item.active ? "active-billing-span" : ""
                        }`}
                        onClick={item.onClick}
                        id="unpaid_bills"
                    >
                        {item.label}
                    </a>
                )
            })}
        </div>
    )
}