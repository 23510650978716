import React, {useEffect, useReducer, useState} from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageTitle from "../../Utils/smsTitle";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {ReusableBillTable, ProductsTable} from "../utils/ReusableTable";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea"
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader"
import Label from "../../Utils/FormInputs/Label"
import SelectInput from '../../Utils/FormInputs/SelectInput';
import {Container, ErrorMessage} from "../../Utils/styledComponents"
import {dateConvert, formatDate, formatDateTime} from "../../Utils/ConvertDate";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import InputGroup from "react-bootstrap/InputGroup";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import {
    filterInpatientBeds,
    filterInpatientProducts,
    filterInpatientServices,
    groupProductId,
    groupServiceId
} from "../utils/SanitizeArr";
import {useCurrency} from "../../../Context/currency-context";
import {useVersionsContext} from "../../../Context/versions-context";
import {billingProductsAndServicesReducer} from "../utils/billingProductsReducer";
import {useSelfRequestPrescription} from "../../Visit/NewService/hooks/usePrescriptionSelfRequest";
import {useNewServiceRequest} from "../../Visit/NewService/hooks/useNewServiceRequest";
import * as billingActions from "../utils/billingActions";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useQuery} from "react-query";
import * as colors from "../../../styles/colors";
import {DepositsMade} from "./DepositsMade";


function MakeDeposit(props){
    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'

    const {currency:selectedCurrency} = useCurrency(props)

    const {actions} = props;

    const {patient_admission_id, visit_id, pay_status} = props.match.params;


    const [
        { products, productsEdited, productsRemoved, productsPaid, procedureData, invoicesEdited, invoicesRemoved, invoicesPaid,
            bedBill, checkAllServices,checkAllProducts,checkAllBeds},
        dispatchProductsAndServices
    ] = useReducer(
        billingProductsAndServicesReducer,
        {
            products: [],
            productsEdited: [],
            productsRemoved: [],
            productsPaid: [],
            procedureData: [],
            invoicesEdited: [],
            invoicesRemoved: [],
            invoicesPaid: [],
            bedBill:[],
            checkAllServices:false,
            checkAllProducts:false,
            checkAllBeds:false
        }, () => ({
            products: [],
            productsEdited: [],
            productsRemoved: [],
            productsPaid: [],
            procedureData: [],
            invoicesEdited: [],
            invoicesRemoved: [],
            invoicesPaid: [],
            bedBill:[],
            checkAllServices:false,
            checkAllProducts:false,
            checkAllBeds:false
        }));

    const user = JSON.parse(sessionStorage.getItem('user'));

    const userObj = user ?? {};

    const token = userObj.token ?? [];

    const _token = token[1] ?? {};

    const user_roles_id = _token.user_roles_id ?? "";

    const obj = {visit_id, user_roles_id, actions}


    const [payment_method, setPaymentMethod] = useState('');


    const [submitted, setSubmitted] = useState(false);

    const [isSubmitted, setIsSubmitted] = useState('idle');

    const [date, setDate] = useState(dateConvert());

    const [openPrint, setOpenPrint] = useState(false);

    const [totals, setTotals] = useState({
        total_amount:0,
        bed_amount:0,
        total_product:0,
        due_amount:0,
        balance:0,
        net_total:0,
        tax_amount:0,
        discount_amount:0
    })

    const [paidAmount, setPaidAmount] = useState("");


    const [addService, setAddService] = useState(false)

    const [addProduct, setAddProduct] = useState(false)


    const [currency, setCurrency] = useState('')

    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)

    const [usdAmount, setUsdAmount] = useState(0)

    const [sspAmount, setSspAmount] = useState(0)

    const [isBlocking, setIsBlocking] = useState(false)


    const [isConverted, setIsConverted] = useState(false);

    const [currencyTotal, setCurrencyTotal] = useState(0)

    const [currencyTotalSsp, setCurrencyTotalSsp] = useState(0)
    const [openPaymentsDialog, setOpenPaymentsDialog] = useState(false)

    const [receiptTotals, setReceiptTotals] = useState({
        total_amount_paid:0,
        remaining_balance:0,
        ssp_amount_paid:0,
        ssp_remaining_balance:0
    })

    const {renderPrescription, isResolved:isPrescription, handleClosePrescriptionDialog} = useSelfRequestPrescription({...obj, setOpenDialog:setAddProduct},true)

    const {renderService, isResolvedService, handleCloseServiceDialog} = useNewServiceRequest({...obj,setOpenDialog:setAddService}, true)





    const payTypesQuery = useQuery(
        ["payTypesQuery"],
        async () => {
            try {

                const response = await axios.get(`${config.smsUrl}/cbilling/payment_methods`)

                const data = response.data ?? {};

                const arr = data?.data ?? [];

                const cash = arr.map(item=>({value:item.id, text:item.payment_type_name})).filter(item=> item.text === 'CASH')

                return ({
                    payTypes: cash
                })
            } catch (error) {
                logoutErrorMessage(error, null, actions);
                return {payTypes:[]}
            }
        },
        {retry: 1, refetchOnWindowFocus: true}
    );

    const payTypes = payTypesQuery.isSuccess ? payTypesQuery.data?.payTypes : [];

    const currenciesQuery = useQuery(
        ["currenciesQuery"],
        async () => {
            try {
                const response = await axios.get(`${config.smsUrl}/accounting/currencies`)

                const data = response.data ?? {};

                const arr = data.data ?? [];

                const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))

                return ({
                    currencies: list
                });
            } catch (error) {
                logoutErrorMessage(error, null, actions);
                return {currencies:[]}
            }
        },
        {retry: 1, refetchOnWindowFocus: true}
    );

    const currencies = currenciesQuery?.isSuccess ? currenciesQuery?.data?.currencies : [];



    const inPatientDepositsMakeDepositQuery = useQuery(
        ["inPatientDepositsMakeDepositQuery",isPrescription, isResolvedService],
        async () => {
            try{
                const formData = new FormData();

                formData.append('visit_id', visit_id);

                formData.append('patient_admission_id', patient_admission_id);

                formData.append('pay_status', pay_status);

                formData.append('invoice_number', '');

                const response = await axios.post(`${config.smsUrl}/cbilling/get_inpatient_bill`, formData)

                const data = response.data ?? {};

                const bill_details = data ?? {};


                const {patient_info, admission_bill, patient_deposit, balance} = bill_details;

                const p_info = patient_info ?? [];



                const pt_info = p_info[0] ?? {};

                const service_bill = admission_bill ?? [];

                const base_currency = bill_details.base_currency ? bill_details.base_currency : {}

                const {
                    admission_date, discharge_date, p_address, p_dob, p_first_name,
                    p_gender, p_last_name, patient_number, phone_no
                } = pt_info;

                setCurrency(`${base_currency?.id}-${base_currency?.is_base_currency}-${base_currency?.currency_symbol}`)


                const srvc = filterInpatientServices(exchangeRate,service_bill.filter(service => service.service_id))

                const prdts = filterInpatientProducts(exchangeRate, service_bill.filter(prod => prod.product_id))



                const bd = filterInpatientBeds(exchangeRate, service_bill.filter(bd => bd.bed_assignment_id))



                setReceiptTotals({...receiptTotals,total_amount_paid: patient_deposit?.bill, remaining_balance: balance ? balance : 0})


                // setBedBill(bd)

                dispatchProductsAndServices({
                    type: billingActions.RETRIEVE_PRODUCTS_AND_SERVICES,
                    services: srvc,
                    products: prdts,
                    bed: bd,
                    exchangeRate,
                    calculateTotal
                });

                return ({
                    patientDetails: {
                        patient_number,
                        first_name: p_first_name,
                        last_name: p_last_name,
                        address: p_address,
                        dob: p_dob,
                        gender: p_gender,
                        admission_date,
                        discharge_date,
                        phone_no
                    },
                })

            } catch (error) {
                logoutErrorMessage(error, null, actions)
                return { patientDetails: {}}
            }
        },
        {retry: 1,
            // refetchInterval:3000
        },
    );

    const {patientDetails} = inPatientDepositsMakeDepositQuery.isSuccess ? inPatientDepositsMakeDepositQuery.data : {
        patientDetails: {
            patient_number: "",
            first_name: "",
            last_name: "",
            address: "",
            dob: null,
            gender: "",
            admission_date: null,
            discharge_date: null,
            phone_no: ""
        },
    };


    useEffect(()=>{
        if (isPremium){
            return
        }
        setCurrency(`${selectedCurrency?.id}-${selectedCurrency?.is_base_currency}-${selectedCurrency?.currency_symbol}`)
    },[version, selectedCurrency])

    const calculateTotal = (servicesArr, prdts, rate = 0, bed=[], selectedCurrency=currency) => {

        const totalPaidAmount = [...servicesArr, ...prdts, ...bed].filter(item=>item.isPaid).reduce((a, item) => {
            return +(a + item.amount)
        }, 0)

        const overallPaidAmount = selectedCurrency.includes('true') ? totalPaidAmount :  (totalPaidAmount ?   totalPaidAmount / rate : 0)


        //usd total amount
        const totalBedBill = bed.reduce((a, item) => {
            return +(a + item.amount)
        }, 0);

        const totalProduct = prdts.reduce((a, item) => {
            return +(a + item.subTotal)
        }, 0);

        const totalService = servicesArr.reduce((a, item) => {
            return +(a + item.amount)
        }, 0);

        // non usd total amount
        const currency_service_total = servicesArr.reduce((a, item) => {
            const base_total = rate ?  (+item.rate / rate) : 0
            return a + +base_total
        }, 0)

        const product_service_total = prdts.reduce((a, item) => {
            const base_total = rate ?  (+item.rate / rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const currency_bed_total = bed.reduce((a, item) => {
            const base_total = rate ?  (+item.rate / rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const product_service_total_ssp = prdts.reduce((a, item) => {
            const base_total = rate ?  (+item.rate * rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const currency_service_total_ssp = servicesArr.reduce((a, item) => {
            const base_total = rate ? +item.rate * rate : 0;
            return a + +base_total;
        }, 0);

        const currency_bed_total_ssp = bed.reduce((a, item) => {
            const base_total = rate ?  (+item.rate * rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const non_currency_total_amt = totalProduct + totalService + totalBedBill

        const currency_total_amount =  currency_bed_total + currency_service_total + product_service_total

        const currency_total_amount_ssp =  currency_bed_total_ssp + currency_service_total_ssp + product_service_total_ssp

        const amt = paidAmount - (non_currency_total_amt);

        const bal = amt < 0 ? 0 : amt;

        setTotals({...totals, total_amount: non_currency_total_amt, balance: bal})

        setCurrencyTotal(currency_total_amount)
        setCurrencyTotalSsp(currency_total_amount_ssp)

        multiplyCurrency(rate, overallPaidAmount, currency)

    }


    const multiplyCurrency = (exchange_rate, amountReceived, displayedCurrency) => {

        const {remaining_balance, total_amount_paid} = receiptTotals

        let [amountInUSD, amountInSSP, balanceInSsp, paidAmountSsp] = [0,0,0,0];

        // currency is USD
        if(displayedCurrency.includes("true")){
            amountInUSD = amountReceived

            amountInSSP = Number(exchange_rate) <= 0 ? 0 : Number(exchange_rate) * Number(amountReceived)

            balanceInSsp = Number(exchange_rate) <= 0 ? 0 : Number(remaining_balance) * Number(exchange_rate)
            paidAmountSsp = Number(exchange_rate) <= 0 ? 0 : Number(total_amount_paid) * Number(exchange_rate)

            setUsdAmount(amountInUSD);

            setSspAmount(amountInSSP);

            setReceiptTotals({...receiptTotals, ssp_remaining_balance: balanceInSsp, ssp_amount_paid: paidAmountSsp})

            return;
        }

        // currency is SSP
        if(displayedCurrency.includes("false")){
            amountInSSP = amountReceived;

            amountInUSD = Number(exchange_rate) <= 0 ? 0 : Number(amountReceived) * Number(exchange_rate)

            balanceInSsp = Number(exchange_rate) <= 0 ? 0 : Number(remaining_balance) / Number(exchange_rate)
            paidAmountSsp = Number(exchange_rate) <= 0 ? 0 : Number(total_amount_paid) / Number(exchange_rate)


            setUsdAmount(amountInUSD);

            setSspAmount(amountInSSP);
            setReceiptTotals({...receiptTotals, ssp_remaining_balance: balanceInSsp, ssp_amount_paid: paidAmountSsp})

            return;
        }


    }


    //Change currency
    const handleChangeCurrency = (event) =>{
        setCurrency(event.target.value)

        setTotals({...totals, balance: 0})
        setUsdAmount(0)

        setSspAmount(0)

        setExchangeRate(0)
        setCurrencyExchangeRate('')
        setReceiptTotals({...receiptTotals, ssp_remaining_balance: 0, ssp_amount_paid: 0})
        calculateTotal(procedureData, products, Number(exchangeRate), bedBill,event.target.value)
        setIsBlocking(true)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setCurrencyExchangeRate(event.target.value)
        const currency_ex_rate = Number(event.target.value) > 0 ?  1 / Number(event.target.value) : 0
        setExchangeRate(currency_ex_rate)

        setIsConverted(true);

        multiplyCurrency(Number(currency_ex_rate), paidAmount, currency)


        dispatchProductsAndServices({
            type: billingActions.CALCULATE_PRODUCT_AMOUNT,
            rate:currency_ex_rate,
            currency:currency.split('-')[1]
        });

        dispatchProductsAndServices({
            type: billingActions.CALCULATE_SERVICE_AMOUNT,
            rate:currency_ex_rate,
            currency:currency.split('-')[1]
        });
        dispatchProductsAndServices({
            type: billingActions.CALCULATE_BED_AMOUNT,
            rate:currency_ex_rate,
            currency:currency.split('-')[1]
        });



        calculateTotal(procedureData, products, Number(currency_ex_rate), bedBill)

        setIsBlocking(true)
    }

    //change amount received
    const handleChangePaidAmount = (event) => {
        const {total_amount} = totals

        const isBaseCurrency = currency.includes('true');

        setPaidAmount(event.target.value);

        const total_amt = isBaseCurrency ? +total_amount : +currencyTotal

        const amount = +(event.target.value) -  total_amt;

        const amt = amount < 0 ? 0 : amount;

        const balance_amount = total_amt > 0 ? amt : 0

        setTotals({...totals, balance: balance_amount})

        multiplyCurrency(exchangeRate, event.target.value, currency)

        setIsBlocking(true)
    };

    const handleChangeServiceCurrencyRate = (event, bill_id) => {
        const prods = products.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.CHANGE_SERVICE_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods,
            bedBill
        });

        setIsBlocking(true)
    }

    const handleChangeServiceRate = (event, bill_id) => {

        const prods = products.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.CHANGE_SERVICE_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods,
            bedBill
        })

        setIsBlocking(true)


    }

    const handleChangeProductRate = (event, bill_id) => {
        const procedures = procedureData.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.CHANGE_PRODUCT_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })

        setIsBlocking(true)
    }

    const handleChangeProductCurrencyRate = (event, bill_id) => {

        const procedures = procedureData.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })

        setIsBlocking(true)
    }


    const handleDeleteServices = (item) => {
        const {bill_id} = item;
        const prods = products.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.DELETE_SERVICE,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods,
            bedBill
        })

        setIsBlocking(true)
    };



    const handleChangeProductQuantity = (event,bill_id) =>{
        const procedures = procedureData.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })

        setIsBlocking(true)
    }

    const handleDeleteProduct = (item) => {
        const {bill_id} = item
        const procedures = procedureData.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.DELETE_PRODUCT,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })

        setIsBlocking(true)
    };

    const handleChangeDate = (event) => {
        setDate(event.target.value)
        setIsBlocking(true)
    };

    const handleChangeMethod = (event) => {
        const {value} = event.target;
        setPaymentMethod(value);
        setIsBlocking(true)
    };


    const handleSubmitBill = (event) => {
        event.preventDefault();
        setIsBlocking(false)
        setSubmitted(true)
        const {remaining_balance,ssp_remaining_balance} = receiptTotals

        const isBase = currency.split('-')[1]
        const isBaseCurrency = isBase === 'true'
        const isNotBaseCurrency = isBase === 'false'

        const correct_payment = (isNotBaseCurrency && paidAmount <= ssp_remaining_balance) || (isBaseCurrency && paidAmount <= remaining_balance)

        if (payment_method && paidAmount && correct_payment) {
            setOpenPrint(true)
        }

    };
    const handleCloseReceipt = () => {
        setOpenPrint(false);
    };


    const handleClickReceipt = async () => {
        try {

            const {notes} = patientDetails;

            const isBase = currency.split('-')[1]

            const isNotBase = isBase === 'false'
            const isBaseCurrency = isBase === 'true'

            const currency_id = parseInt(currency.split('-')[0]);

            const isBaseData = isBaseCurrency ?
                {
                    is_base_currency: isBase,
                    amount_paid_in_the_currency: +currencyTotal,
                    currency_id,
                    amount_paid: +paidAmount,
                    rate_input_by_cashier: +exchangeRate,
                    is_converted: isConverted
                } : {};

            const isNotBaseData = isNotBase ?
                {
                    currency_id,
                    amount_paid_in_the_currency: +currencyTotal,
                    rate_input_by_cashier: +exchangeRate,
                    total_amount_in_base_currency: +usdAmount,
                    amount_paid: +usdAmount,
                    is_base_currency: isBase

                } : {};


            setOpenPrint(true)

            setIsSubmitted('pending');

            const response = await axios.post(
                `${config.smsUrl}/cbilling/make_inpatient_deposit`,
                {
                    currency_id,
                    pay_type: +payment_method,
                    visit_id,
                    received_by: user_roles_id,
                    amount_paid: +paidAmount,
                    bill_mode: "cash",
                    memo: notes ?? '',
                    ...isBaseData, ...isNotBaseData
                }
            )

            if(response.status === 200) {
                const data = response.data ?? {};

                const receipt_number = data.receipt_numbers ?? '';

                actions.snackbarActions.snackSuccess('Deposit made successfully');

                setIsSubmitted('resolved');

                setOpenPrint(false)

                return history.push(`/deposit-receipt/${visit_id}/${receipt_number}`);

            }


        } catch (error) {
            errorMessages(error, null, actions)
            setIsSubmitted("rejected")
        }

    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const {openBar, type, message} = props.snackbars;

    const {patient_number, first_name, last_name, address,  admission_date} = patientDetails;



    const groupedProducts  = groupProductId(products)

    const groupedServices = groupServiceId(procedureData)

    const {total_amount} = totals

    const {total_amount_paid, remaining_balance, ssp_amount_paid, ssp_remaining_balance} = receiptTotals


    const isBase = currency && currency.split('-')[1]
    const currency_symbol = currency && currency.split('-')[2]
    const isNotBase = isBase === 'false'
    const isBaseCurrency = isBase === 'true'


    const isLab = groupedServices.some(item=>item.is_external === true || item.is_external === false)


    const unpaid = ["Item", "Quantity",   isNotBase ?  `Rate(${currency_symbol})` : null,
        `Rate(${selectedCurrency.currency_symbol})`,
        isNotBase ? `Amount(${currency_symbol})` : null,
        `Amount(${selectedCurrency.currency_symbol})`,
        isLab ? "Status" : null]

    const productHeadData = ["Medicine Name", "Dosage", "Frequency", "No. of Days", "Quantity Administered",
        isNotBase ?  `Rate(${currency_symbol})` : null, `Rate(${selectedCurrency.currency_symbol})`,
        isNotBase ? `Amount(${currency_symbol})` : null, `Amount(${selectedCurrency.currency_symbol})`,]

    const bedHeadData = ["Ward", "Room", "Bed No.", "Assign Date",
        "End Date","Days",`Rate(${selectedCurrency.currency_symbol})`,
        isNotBase ?  `Rate(${ currency_symbol})` : null,
        `Amount(${selectedCurrency.currency_symbol})`,
        isNotBase ? `Amount(${currency_symbol})` : null]



    const isProducts = products.some(item=>item.isPaid)
    const isServices = procedureData.some(item=>item.isPaid)


    return (
        <>
            <div data-testid="add-bill-component" className='journals'>
                <PageTitle title='Make Deposit'/>

                <SubHeader title="Billing" subTitle='Make Deposit'>
                    <FontAwesomeIcon icon={faCoins}/>
                </SubHeader>

                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                />

                <PrintDialog
                    handleCancel={handleCloseReceipt}
                    openDialog={openPrint}
                    handleClose={handleCloseReceipt}
                    handleClick={handleClickReceipt}
                    message="View receipt"
                    text="Would you like to confirm this payment"/>

                <CustomDialog
                    open={addService}
                    title="Add Service"
                    handleClose={handleCloseServiceDialog}
                    maxWidth="md"  isContentOverflow={true}
                >
                    {renderService}
                </CustomDialog>

                <CustomDialog
                    open={addProduct}
                    title="Add Sundries"
                    handleClose={handleClosePrescriptionDialog}
                    maxWidth="lg"
                    isContentOverflow={false}
                >
                    {renderPrescription}
                </CustomDialog>

                <MainSnackbar
                    variant={type}
                    handleCloseBar={closeSnackbar}
                    open={openBar}
                    message={message}
                />

                <Container>
                    <BackButton
                        to='/patientbillinglist'
                        text='Billing List'
                        data-testid="inpatient-bill-form-back-button"
                    />

                    <MiniHeader  title="Make deposit"/>

                    <form onSubmit={handleSubmitBill} autoComplete="off" style={{marginTop:'1.5rem'}}>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Admission Date"/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField type="text" value={formatDate(admission_date)} readOnly/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Patient No."/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField type="text" value={patient_number} readOnly/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Patient Name" />
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField type="text" value={`${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`} name="patient_name" readOnly/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Address" />
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <Textarea value={address} readOnly/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Payment Method"  type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <SelectInput
                                            name='payment_method'
                                            submitted={submitted}
                                            value={payment_method}
                                            onChange={handleChangeMethod}
                                            options={payTypes}
                                            defaultOption="Select..."
                                        />
                                    </Col>
                                </Form.Group>

                                {isPremium ? <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Currency" type htmlFor="currency"/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <SelectInput
                                            submitted={submitted}
                                            value={currency}
                                            onChange={handleChangeCurrency}
                                            options={currencies}
                                            defaultOption="Select..."
                                            id="currency"
                                            name="currency"
                                        />
                                    </Col>
                                </Form.Group>: null}

                                {
                                    isNotBase ?

                                        <Form.Group as={Row} >
                                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                                <Label  name="Exchange Rate" />
                                            </Col>

                                            <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                                <InputGroup className="">
                                                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{Boolean(currency_symbol) ? currency_symbol : null}</InputGroup.Text>

                                                    <TextField type="number"  value={currencyExchangeRate} onChange={handleChangeExchangeRate} id="exchange_rate"/>
                                                </InputGroup>
                                                {!(isProducts || isServices) && !currencyExchangeRate ? <ErrorMessage>Enter the exchange rate</ErrorMessage>: null}

                                                <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 {selectedCurrency.currency_symbol} = {currencyExchangeRate} {currency_symbol})</span>
                                            </Col>
                                        </Form.Group>
                                        :
                                        null
                                }

                            </div>

                            <div className='col-lg-6'>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Date"  type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                        <TextField submitted={submitted} type="date" value={date} onChange={handleChangeDate}/>

                                        {
                                            submitted && !date ?
                                                <ErrorMessage>This is a required field</ErrorMessage> : null
                                        }

                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name={`Total Bill(${selectedCurrency.currency_symbol})`}/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                        <TextField name='Total_Bill' type="text" value={total_amount.toFixed(3)} readOnly/>
                                    </Col>
                                </Form.Group>

                                {
                                    isNotBase ?

                                        <Form.Group as={Row}>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                                <Label name={`Total Bill(${currency_symbol})`}/>
                                            </Col>

                                            <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                                <TextField type="text" value={currencyTotal.toFixed(3)} readOnly/>
                                            </Col>
                                        </Form.Group>
                                        :
                                        null
                                }
                                    <DepositsMade {...{open:openPaymentsDialog,setOpen:setOpenPaymentsDialog, visit_id, actions}}/>
                                    <Form.Group as={Row}>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                            <Label name="Amount Paid"/>
                                        </Col>

                                        <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                            {isNotBase ? <Row>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>

                                                            <TextField name='outstanding_amount' type="text" value={ssp_amount_paid.toFixed(3)} readOnly/>
                                                        </InputGroup>
                                                    </Col>

                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <InputGroup className="mb-1">
                                                            <InputGroup.Text style={{borderRadius: '0.7rem 0 0 0.7rem'}}
                                                                             id="basic-addon1">{selectedCurrency?.currency_symbol}</InputGroup.Text>

                                                            <TextField name='outstanding_amount' type="text"
                                                                       value={total_amount_paid.toFixed(3)} readOnly/>
                                                        </InputGroup>

                                                        {total_amount_paid > 0 ? <p onClick={()=>setOpenPaymentsDialog(true)} style={{
                                                            margin: 0,
                                                            color: colors.blue,
                                                            textDecoration: "underline",
                                                            cursor: "pointer"
                                                        }}>View payments</p>: null}                                                    </Col>


                                                </Row>:
                                                <>
                                                    <InputGroup className="mb-1">
                                                        <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol !== 'undefined' ? currency_symbol : selectedCurrency.currency_symbol}</InputGroup.Text>

                                                        <TextField name='outstanding_amount' type="text" value={total_amount_paid.toFixed(3)} readOnly/>
                                                    </InputGroup>
                                                    {total_amount_paid > 0 ? <p onClick={()=>setOpenPaymentsDialog(true)} style={{
                                                        margin: 0,
                                                        color: colors.blue,
                                                        textDecoration: "underline",
                                                        cursor: "pointer"
                                                    }}>View payments</p>: null}
                                                </>

                                            }
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                            <Label name="Remaining Balance"/>
                                        </Col>

                                        <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                            {isNotBase ? <Row>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>

                                                            <TextField name='outstanding_amount' type="text" value={ssp_remaining_balance.toFixed(3)} readOnly/>
                                                        </InputGroup>
                                                    </Col>

                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{selectedCurrency?.currency_symbol}</InputGroup.Text>

                                                            <TextField name='outstanding_amount' type="text" value={remaining_balance.toFixed(3)} readOnly/>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>:
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol !== 'undefined' ? currency_symbol : selectedCurrency.currency_symbol}</InputGroup.Text>

                                                    <TextField name='outstanding_amount' type="text" value={remaining_balance.toFixed(3)} readOnly/>
                                                </InputGroup>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                            <Label  name={`Amount Received ${ Boolean(currency_symbol) ? `(${currency_symbol ?? ''})`: ''}`}  type/>
                                        </Col>

                                        <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                            <TextField submitted={submitted} type="number"  value={paidAmount} onChange={handleChangePaidAmount} name="Amount_Received"/>
                                            {submitted && !paidAmount ? <ErrorMessage>Amount received is required</ErrorMessage>: null}
                                            {Number(paidAmount) > 0 && (isNotBase && ssp_remaining_balance && Number(paidAmount) > Number(ssp_remaining_balance)) ||
                                            (isBaseCurrency && remaining_balance && Number(paidAmount) > Number(remaining_balance)) ?
                                                <ErrorMessage>{`Amount received is more than ${isBaseCurrency ? remaining_balance : ssp_remaining_balance}`}</ErrorMessage>: null}
                                        </Col>
                                    </Form.Group>

                                    {
                                        isNotBase ?
                                            <Form.Group as={Row}>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                                    <Label  name={`Amount Received(${selectedCurrency.currency_symbol})`}/>
                                                </Col>

                                                <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{selectedCurrency.currency_symbol}</InputGroup.Text>

                                                        <TextField submitted={submitted} type="text" value={ isBaseCurrency ? Number(paidAmount).toFixed(3) : Number(usdAmount).toFixed(3)} style={{pointerEvents:'none'}}/>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            :
                                            null
                                    }

                            </div>
                        </div>

                        <div className="other-billing-details mb-3">
                            <div className="service-details">
                                {
                                    groupedServices.length > 0 ?

                                        <ReusableBillTable
                                            services={groupedServices}
                                            isInPatient={true}
                                            handleDelete={handleDeleteServices}
                                            headData={ unpaid.filter(item=>Boolean(item))}
                                            title="Service(s)"
                                            isCurrency={isNotBase}
                                            handleChangeRate={handleChangeServiceRate}
                                            handleChangeCurrencyRate={handleChangeServiceCurrencyRate}
                                            {...{isPatientDeposit:true}}
                                        >
                                        </ReusableBillTable>
                                        :
                                        null
                                }

                                {
                                    groupedProducts.length > 0 ?

                                        <ProductsTable
                                            {...{handleChangeProductQuantity, handleDeleteProduct}}
                                            products={groupedProducts}
                                            submitted={submitted}
                                            headData={productHeadData.filter(item=>Boolean(item))}
                                            handleChangeProductRate={handleChangeProductRate}
                                            isInPatient={true}
                                            isCurrency={isNotBase}
                                            handleChangeProductCurrencyRate={handleChangeProductCurrencyRate}
                                            {...{isPatientDeposit:true}}
                                        >
                                        </ProductsTable>
                                        :
                                        null
                                }

                                {
                                    bedBill.length > 0 &&
                                    <table className="table table-bordered table-sm billing-invoice-table">
                                        <thead>
                                        <tr>
                                            <td colSpan={bedHeadData.length} align="center">Bed Payment</td>
                                        </tr>

                                        <tr>
                                            {bedHeadData.filter(item=>Boolean(item)).map((item, index) => (<td key={index}>{item}</td>))}
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            bedBill.map((bed, index) =>{

                                                    return (
                                                        <tr key={index}>
                                                            <td>{bed.ward_name}</td>
                                                            <td>{bed.room_name}</td>
                                                            <td>{bed.bed_number}</td>
                                                            <td>{formatDateTime(bed.assign_date)}</td>
                                                            <td>{formatDateTime(bed.discharge_date)}</td>
                                                            <td>{bed.quantity}</td>
                                                            <td>{bed.rate}</td>
                                                            {isNotBase ? <td>{bed.currency_rate}</td> : null}
                                                            <td>{bed.amount}</td>
                                                            {isNotBase ? <td>{bed.currency_total}</td> : null}
                                                        </tr>
                                                    )
                                                }
                                            )}

                                        </tbody>

                                    </table>
                                }

                            </div>
                        </div>

                        <button
                            type="button"
                            onClick={()=>setAddProduct(true)}
                            className="btn btn-sm btn-primary mr-3"
                            disabled={isSubmitted === 'pending'}
                        >
                            Add Sundries
                        </button>

                        <button
                            type="button"
                            onClick={()=>setAddService(true)}
                            className="btn btn-sm btn-secondary mr-3"
                            disabled={isSubmitted === 'pending'}
                        >
                            Add Service
                        </button>

                        <button
                            type="submit"
                            className="btn btn-sm sms-info-btn"
                            disabled={isSubmitted === 'pending'}
                        >
                            {isSubmitted === 'pending' ? "Saving..." : "Save"}
                        </button>
                    </form>

                </Container>
            </div>
        </>
    );
}


function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(MakeDeposit);
