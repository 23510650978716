import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as snackbarActions from '../../../actions/snackbarActions';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {formatDate} from "../../Utils/ConvertDate";
import {formatDigits} from "../../Utils/formatNumbers";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {DataCell} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import {ReceiveDebtorsPayments} from "./ReceiveDebtorsPayments";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import TableCell from "@material-ui/core/TableCell";
import {CustomTablePagination, EnhancedSearchTableToolbar} from "../../Utils/Tables/CustomTable";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {CollapsibleReportDetails, ReportDetailsTable} from "../Utils/CollapsibleReportDetails";
import {ReverseDebtorsPayments} from "./ReverseDebtorsPayment";
import {history} from "../../../Helpers/history";


const useStyles = makeStyles((theme) => ({
    drawerWidth:{
        width: 800,
        padding:'16px'
    },
    paragraph:{
        fontSize:'15px',
        display:'inline-block',
        marginBottom:'0.8rem',
        marginRight:'16px'
    },
    icon: {
        width: '.9em',
        height: '.9em'
    },
    iconHolder: {
        minWidth: '38px '
    },
    item: {
        borderRight: '1px solid #c7c6c7',
        borderLeft: '1px solid #c7c6c7',
        height: '100vh',
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c6c7',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    },
    pItem: {
        borderLeft: '1px solid #c7c6c7',
    },
    rounded: {
        borderRadius: 0
    },
    listText: {
        textAlign: 'right'
    },
    approved: {
        color: "green"
    },
    purchased: {
        color: "#1d5dd3"
    },
    canceled: {
        color: '#D9512C'
    },
    pending: {
        color: "#F29339"
    },
    transactions:{
        padding:'20px 30px'
    }
}));

/*
const headData = [{item:'Item', align:'left'},{item:'Item Type',align: 'left'},
{item:'Quantity', align:'left'},{item:'Rate', align:'left'},{item:'Amount', align: 'left'}];

Date, Patient name, Invoice No, Item, Item Type, Acc name, Acc Number, 
*/





const DebtorsReportDetails = ({actions, snackbars, match}) => {
    const classes = useStyles();
    const {customer_id:id, customer_name:name, pay_status} = match.params;
    const [data, setData] = useState({invoices:[], loading:'idle', total_count:0, providerDetails:{}})
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const {invoices,loading:loadingInvoices, providerDetails} = data
    const [searchValue, setSearchValue] = useState('');
    const [openDialog, setOpenDialog] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [paymentDetails, setPaymentDetails] = useState({})
    const [currency_symbol, setCurrencySymbol] = useState('')



    const isResolved = isSubmitted === 'resolved'

    useEffect(() => {
        if (!id) {
            return;
        }
        setData({...data, loading: 'loading'});
        
        axios.get(`${config.smsUrl}/cbilling/customer-visit-bills/${pay_status}/${id}`, {params: {search: searchValue}})
            .then(res => {
                const data = res.data;
                const arr = data || {};  // Handle null/undefined data
    
                // Safely map over provider_balances if it's an array
                const arrData = Array.isArray(arr.provider_balances) ? arr.provider_balances.map((row, index) => {
                    return {
                        ...row,
                        open: index === 0,
                        total_amount: row?.bills?.map(item => ({
                            total: (item.rate || 0) * (item.quantity || 0)
                        })).reduce((prev, sum) => +prev + sum.total, 0)
                    };
                }) : [];
    
                // Safely access currency data if provider_balances has at least one item
                const currencyData = arrData.length > 0 ? arrData[0] : {};
                const currencyObj = currencyData?.bills?.[0] || {};
    
                setCurrencySymbol(currencyObj?.currency_symbol || '');
                setData({
                    ...data,
                    invoices: arrData,
                    loading: 'success',
                    total_count: arrData.length,
                    providerDetails: {
                        ...arr.provider,
                        ...currencyObj
                    }
                });
            })
            .catch(err => {
                errorMessages(err, null, actions);
                setData({...data, loading: 'error'});
            });
    }, [id, isResolved, searchValue]);
    




    // useEffect(() => {
    //     if(!id){
    //         return
    //     }
    //     setData({...data, loading: 'loading'})
    //     axios.get(`${config.smsUrl}/cbilling/customer-visit-bills/${pay_status}/${id}`,{params:{search:searchValue}}).then(res => {
    //         const data = res.data;
    //         const arr = !data ? {} : data;
    //         // const prov = arr?.provider_balances[0]?.bill[0] ?? {}
    //         const arrData = arr?.provider_balances?.map((row, index)=>{
    //             return {
    //                 ...row,
    //                 open:index === 0,
    //                 total_amount:row?.bills?.map(item=>({total : (item.rate ? item.rate: 0) * (item.quantity ? item.quantity : 0)})).reduce((prev, sum)=> +prev + sum.total,0)
    //             }
    //         })


    //         const currencyData = arr?.provider_balances?.length > 0 ? (arr?.provider_balances[0] ?? {}) : {}
    //         const currencyObj = currencyData?.bills[0] ?? {}

    //         setCurrencySymbol(currencyObj?.currency_symbol)
    //         setData({...data,invoices: arrData,  loading: 'success', total_count:arrData.length,
    //             providerDetails: {
    //                 ...arr.provider,
    //                 ...currencyObj
    //             }})
    //     }).catch(err => {
    //         errorMessages(err,null,actions)
    //         setData({...data, loading: 'error'})
    //     })
    // }, [id, isResolved,searchValue]);

    const handleOpen = (index)=>{
        const arr = data.invoices.map((item, idx)=>{
            if (index === idx){
                return {...item, open:!item.open}
            }
            return item
        })
         setData({...data, invoices: arr})
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };


    //invoices
    const isLoadingInvoices = loadingInvoices === 'loading'
    const isSuccessInvoices = loadingInvoices === 'success'
    const isErrorInvoices = loadingInvoices === 'error'


    const {openBar, type, message} = snackbars


    const headData = [
        {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'item_type', numeric: false, disablePadding: false, label: 'Item Type'},
        {id: 'item', numeric: false, disablePadding: false, label: 'Item'},
        {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
        {id: 'rate', numeric: false, disablePadding: false, label: `Rate(${currency_symbol})`},
        {id: 'amount', numeric: false, disablePadding: false, label: `Amount(${currency_symbol})`},
    ];

    const year = new Date().getFullYear().toString()
    const current_year = year.split('').slice(-2).join('')

    const isPaid = pay_status === 'paid'

    function handleReversePayment(visit_id, insurance_id) {
        const params = {
            visit_id: visit_id[0],
            insurance_provider: insurance_id[0],
        }
        // setIsSubmitted('pending')
        axios.post(`${config.smsUrl}/cbilling/reverse-insurance-payment`, params).then(() => {
            actions.snackbarActions.snackSuccess('Payment reversed successfully')
            setIsSubmitted('resolved')
            history.push('/paidcustomerreceivables')
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsSubmitted('rejected')
        })

    }

    return (
        <div className='journals'>
            <PageTitle title="Accounts Receivables Details"/>
            <SubHeader title="Reports" subTitle="Accounts Receivables Details">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
            {isPaid ?
                <ReverseDebtorsPayments {...{openDialog, setOpenDialog, actions, isSubmitted, setIsSubmitted, providerDetails: paymentDetails}}/>:
                <ReceiveDebtorsPayments {...{openDialog, setOpenDialog, actions, isSubmitted, setIsSubmitted, providerDetails: paymentDetails}}/>
            }
            <div className="top-bar py-0">
                <div className="row">
                    <div className='col-lg-6'>
                        <p style={{fontSize: '16px', marginTop: '4px', marginBottom: 0}}>{name}'s Transactions</p>
                        {/*<p style={{marginTop: 0, marginBottom: 0}}>{providerDetails.provider_type}</p>*/}
                        {/*<p style={{marginTop: 0, marginBottom: 0}}>Currency: <strong>{providerDetails.currency_type === 1 ? '{currency_symbol}' : 'SSP'}</strong></p>*/}
                    </div>
                    <div className='col-lg-6'>
                        {/*<div className="text-right">*/}
                        {/*    <div className='btn-group'>*/}
                        {/*        <button type='button' onClick={downloadPdf}*/}
                        {/*                className='btn btn-sm pharmacy-grey-btn'>*/}
                        {/*            <PictureAsPdfOutlinedIcon classes={{root: classes.icon}}/>*/}
                        {/*        </button>*/}
                        {/*        <button type='button'*/}
                        {/*                className='btn btn-sm pharmacy-grey-btn'>*/}
                        {/*            <PrintOutlinedIcon classes={{root: classes.icon}}/>*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>

            </div>
            <div className={classes.transactions}>
                <div className='row'>
                    <div className='col-lg-6'>
                        <BackButton text={isPaid ? 'Paid Invoices':'Unpaid Invoices'} to={isPaid ? '/paidcustomerreceivables':'/customerreceivables'}/>
                    </div>
                    <div className='col-lg-6'>
                        {/*<RightAlignedContainer>*/}
                        {/*    <button type='button' style={{marginTop:'0.6rem'}} onClick={handleOpenDialog}*/}
                        {/*            className="btn btn-sm sms-btn mr-2 mb-3">Receive Payment*/}
                        {/*    </button>*/}
                        {/*</RightAlignedContainer>*/}
                    </div>
                </div>
                <EnhancedSearchTableToolbar term={searchValue} handler={handleSearch}/>
                {isLoadingInvoices ? <TableRow>
                <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                        alt="loader"/></TableCell>
                </TableRow>:null}
                {isSuccessInvoices ? invoices.length > 0 ?
                    invoices.map((item, index) => {
                        const items = item.bills ? item.bills : [];
                        const tableData = items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => {
                            const rate = row.rate ? row.rate : 0;
                            const quantity = row.quantity ? row.quantity : 0;
                            const count = (page * rowsPerPage) + idx + 1;

                            const ward_name = `${row.ward_name}->${row.room_name}->${row.bed_number}`

                            return (
                                <TableRow key={row.id}>
                                    <DataCell>{count}</DataCell>
                                    <DataCell>{row.product_name ? 'Product' : row.service_name ?  'Service' : 'Bed assigment'}</DataCell>
                                    <DataCell>{row.service_name || row.product_name || ward_name}</DataCell>
                                    <DataCell>{row.quantity}</DataCell>
                                    <DataCell>{row.rate}</DataCell>
                                    <DataCell>{formatDigits(quantity * rate)}</DataCell>
                                </TableRow>
                            );
                        });
                        const total_amount = item.total_bill ? formatDigits(item.total_bill) : 0;
                        const buttons = (
                            <>
                                <span style={{
                                    display: 'inline-block',
                                    marginRight: 50
                                }}>Total bill({currency_symbol}): <strong>{item.total_bill ?
                                    formatDigits(item.total_bill) : 0}</strong></span>

                                {item.discount !== 0 ?
                                    <>
                                        <span style={{
                                            display: 'inline-block',
                                            marginRight: 50
                                        }}>CO-PAYMENT ({item.discount}%)({currency_symbol}): <strong>{item.discount_amount ?
                                            formatDigits(item.discount_amount) : 0}</strong></span>

                                        <span style={{
                                            display: 'inline-block',
                                            marginRight: 50
                                        }}>Grand Total bill({currency_symbol}): <strong>{item.grand_total_bill ?
                                            formatDigits(item.grand_total_bill) : 0}</strong></span>
                                    </>
                                    : null}


                                <button type='button' onClick={() => {

                                    if (isPaid) {
                                        handleReversePayment(item.visit_id, item.insurance_id)
                                    } else {
                                        setOpenDialog(true)
                                        setPaymentDetails(item)
                                    }
                                }}
                                        className='btn btn-sm small-btn sms-btn'>{isPaid ? 'Reverse Payment' : 'Receive Payment'}
                                </button>
                            </>

                        );

                        const date = (
                            <span>Visit date: <strong>{item.begin_datetime ? formatDate(item.begin_datetime) : item.begin_datetime}</strong></span>
                        )


                        return (
                            <div key={item.visit_id} data-testid={`customer-bill-${index}`} >
                                <CollapsibleReportDetails
                                    open={item.open}
                                    name={item.first_name ? `${item.visit_id}/${current_year}  |  ${item.first_name} ${item.last_name}` : 'Unknown name'}
                                    date={date}
                                    handleOpen={() => handleOpen(index)}
                                    button={buttons}
                                >
                                    <ReportDetailsTable headData={headData}>
                                        {tableData}
                                        <TableRow>
                                            <DataCell
                                                align="right"
                                                colSpan={headData.length - 1}
                                            >
                                                Total Amount({currency_symbol})
                                            </DataCell>
                                            <DataCell>
                                                <strong>{total_amount}</strong>
                                            </DataCell>
                                        </TableRow>
                                    </ReportDetailsTable>
                                    <CustomTablePagination colSpan={headData.length} data={item?.bills} page={page}
                                    rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    handleChangePage={handleChangePage}/>
                                </CollapsibleReportDetails>
                            </div>
                        );
                    }):<TableRow>
                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                </TableRow>:null}
                {isErrorInvoices ? <TableRow>
                    <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                </TableRow>:null}

            </div>
        </div>
    )
}

function mapStateToProps(state) {

    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DebtorsReportDetails);


