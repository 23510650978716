import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SubHeader } from "../../../Containers/SubHeader";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";

import { ReusableRouteTabs } from "../../Utils/Dialogs/ReusableTabs";
import { history } from "../../../Helpers/history";
import {useVersionsContext} from "../../../Context/versions-context";
import BillingListTable from "./BillingListTable";
import {BillingSubheading} from "../utils/BillingSubheading";




/**
 * Component that renders the List of unpaid outpatient bills
 * @param {*} param0
 * @returns
 */

const headData = [
  {
    id: "count",
    numeric: false,
    disablePadding: false,
    label: "Sl",
    hidden: false,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Visit Date",
    hidden: false,
  },
  {
    id: "patient_number",
    numeric: false,
    disablePadding: false,
    label: "Patient Number",
    hidden: false,
  },
  {
    id: "patient_name",
    numeric: false,
    disablePadding: false,
    label: "Patient Name",
    hidden: false,
  },
  {
    id: "phone_no",
    numeric: false,
    disablePadding: false,
    label: "Phone",
    hidden: false,
  },
  {
    id: "kin_phone_no",
    numeric: false,
    disablePadding: false,
    label: "NOK Phone",
    hidden: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    hidden: false,
  },
];



const UnpaidBillingList = (props) => {


  const {subscription} = useVersionsContext();
  const version = subscription?.version;

  const components = [
    {
      label: "Out-patient",
      path: "/paymentList",
      tier: ["Basic", "Standard", "Premium"],
    },
    {
      label: "In-patient",
      path: "/patientbillinglist",
      tier: ["Standard", "Premium"],
    },
    {label: "Invoiced Bills", path: "/invoicedbills", tier: ["Premium"]},
  ];

  const links = [
    {
      label:'Unpaid bills',
      active:true,
      route:'/paymentList',
      onClick:()=> history.push('/paymentList')
    },
    {
      label:'Paid bills by visit',
      active:false,
      route:'/paidoutpatientbills',
      onClick:()=> history.push('/paidoutpatientbills')
    },
    {
      label:'Paid bills by receipt',
      active:false,
      route:'/outpatientreceipts',
      onClick:()=> history.push('/outpatientreceipts')
    },
    {
      label:'Refunded bills',
      active:false,
      route:'/refundedoutpatientbills',
      onClick:()=> history.push('/refundedoutpatientbills')
    }
  ]




  return (
      <div className="journals">
        <PageTitle title="Billing List"/>
        <SubHeader title="Billing" subTitle="Billing list">
          <FontAwesomeIcon icon={faCoins}/>
        </SubHeader>
        <ReusableRouteTabs
            value={history.location.pathname}
            tabHeading={components}
            version={version}
        />
        <BillingSubheading links={links}/>
        <div className="mui-tables">
          <BillingListTable headData={headData} billStatus={0}/>
        </div>
      </div>
  );
};


export default UnpaidBillingList;
