import React, {useState} from "react";
import {PatientInformation} from "../PatientInfo/PatientInformation";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../../Utils/smsTitle";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import {ReusablePhoneInput} from "../../Utils/FormInputs/FormGroup";
import Label from "../../Utils/FormInputs/Label";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import { useAddAdmission } from "../../Visit/NewAdmission/hooks/useAddAdmission";
import { usePatientDetails } from "../ReferralNote/usePatientDetails";
import { titleCase } from "../../Users/addUser";
import { CustomSelect } from "../../Utils/FormInputs/SelectInput";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {CenteredContainer, ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import {ResizableTextarea} from "../../Utils/FormInputs/Textarea";
import { FormLabel } from "../../Utils/FormInputs/Label";
import {NewPrescriptionTable} from "../Prescription/NewPrescription";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {ProviderForm} from "../../Visit/NewVisit/NewVisit";
import TextField from "../../Utils/FormInputs/TextField";
import Alert from "@material-ui/lab/Alert";
import {Link} from "react-router-dom"
import {FullScreenDialog} from "../../Utils/Dialogs/FullScreenDialog";
import {AssignBed} from "../../BedManager/AddEditBedAssign";
import {ConfirmationDialog} from "../../Utils/ReusableComponents/ConfirmationDialog";


export const DoctorAdmissionForm = ({admissionProps, children}) =>{

    const {state,  submitted, handleChange, contact, setContact, relationship,
        handleChangeRelationship, findings, handleChangeNotes, addProps,
        isProvider,   isOnInsurance, handleChangeIsInsurance,providerProps,handleOpenNewInsurance,
        insurance_status, handleChangeInsuranceStatus,
        insuranceSchemes, insurance_scheme, handleChangeInsuranceScheme} = admissionProps;

    // removed the nin
    const { first_name, address} = state;


    const {providerDetails:{provider_type}} = providerProps
    const isInsuranceProvider = provider_type === "Insurance Company"


    const [isAddPrescription, setAddPrescription]= useState(false);
    
    const handlePrescriptionToggle = (event) => {
        setAddPrescription(event.target.checked);
    };




    return(
        <form  autoComplete="off">
         
            {/*<div className="col-md-10">*/}
            <div>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Guardian(care taker)  Name"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <TextField onChange={handleChange} name="first_name" value={titleCase(first_name)} type="text"
                                //    submitted={submitted}
                        />
                        {/* {(submitted && !first_name) &&
                            <ErrorMessage>Guardian name is required</ErrorMessage>} */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Guardian(care taker) Contact" />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <ReusablePhoneInput  contact={contact} setContact={setContact}/>
                    </Col>
                </Form.Group>
            </div>

            <div>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Guardian(care taker) Address"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <ResizableTextarea onChange={handleChange} placeholder='Write address...' name="address" value={address}
                                           rows={2}
                        />
                        {/*{(submitted && !address) &&*/}
                        {/*    <ErrorMessage>Guardian address is required</ErrorMessage>}*/}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Relationship" />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <CustomSelect value={relationship} onChange={handleChangeRelationship} options={[{value:'Mother',label:'Mother'},{value:'Father',label:'Father'},
                            {value:'Wife',label:'Wife'},{value:'Husband',label:'Husband'},
                            {value:'Sister',label:'Sister'},{value:'Brother',label:'Brother'},
                            {value:'Spouse',label:'Spouse'},{value:'Guardian',label:'Guardian'},
                            {value:'Other',label:'Other'}]} id='kin_relationship'/>
                    </Col>
                </Form.Group>
                {isOnInsurance && isProvider ?
                    <ProviderForm {...{...providerProps, isDisabled:true, labelSm:"3", colSm:"6"}}/> :  null}
                {isOnInsurance && isInsuranceProvider ? <>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Label name="Insurance scheme" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <CustomSelect onChange={handleChangeInsuranceScheme} id='insurance_scheme'
                                          value={insurance_scheme}
                                          options={insuranceSchemes}/>
                            {submitted && insurance_scheme.length === 0 ? <ErrorMessage>Insurance scheme is required</ErrorMessage>:null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Label  name="Insurance status" htmlFor="insurance_status" type/>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Form.Check type="radio" inline label="On-smart" name="insurance_status" value="ON_SMART"
                                        checked={insurance_status === "ON_SMART"} id="on_smart"
                                        onChange={handleChangeInsuranceStatus} data-testid="insurance_status"/>
                            <Form.Check type="radio" inline label="Off-smart" name="insurance_status" value="OFF_SMART"
                                        checked={insurance_status === "OFF_SMART"} id="off_smart"
                                        onChange={handleChangeInsuranceStatus} data-testid="insurance_status"/>
                            {submitted && !insurance_status ? <ErrorMessage>Insurance status is required</ErrorMessage>:null}
                        </Col>
                    </Form.Group>

                </>:null}
                {/*<Col xs={12} sm={12} md={12} lg={6} xl={6}>*/}
                {/*    {isOnInsurance && !isProvider ? <RightAlignedContainer>*/}
                {/*        <button type='button' onClick={handleOpenNewInsurance} className='btn btn-sm sms-info-btn mb-2'>Add Provider</button>*/}
                {/*    </RightAlignedContainer>:null}*/}
                {/*    {isOnInsurance && isProvider ? <RightAlignedContainer>*/}
                {/*        <button type='button' onClick={handleOpenNewInsurance} className='btn btn-sm sms-info-btn mb-2'>Change Provider</button>*/}
                {/*    </RightAlignedContainer> : null}*/}
                {/*</Col>*/}
                {/*<Form.Group className="switch-admission-prescription">*/}
                {/*    <Label name="Do you want to use Insurance?" className="label-admission-prescription" style={{paddingLeft:'15px'}}/>*/}
                {/*    <text className="label-admission-prescription">No</text>*/}
                {/*    <Form.Check type="switch" id="custom-admission-prescription-switch" checked={isOnInsurance} defaultChecked={isOnInsurance} onChange={handleChangeIsInsurance}*/}
                {/*                className="toggle-admission-prescription"/>*/}
                {/*    <text className="label-admission-prescription">Yes</text>*/}
                {/*</Form.Group>*/}
            </div>
           

            <div>
                <div className="switch-admission-prescription">
                <Label name="Do you want to add a prescription?" className="label-admission-prescription" style={{paddingLeft:'15px'}}/>
                <text className="label-admission-prescription">No</text>
                <Form.Check type="switch" id="custom-switch" defaultChecked={isAddPrescription} onChange={handlePrescriptionToggle} className="toggle-admission-prescription"/>
                <text className="label-admission-prescription">Yes</text>
                </div>
                {isAddPrescription ?
                    <NewPrescriptionTable {...addProps}/>
                : null}
            </div>

            <div>
            <Form.Group as={Row}>
                <Form.Group as={Col}>
                    <FormLabel className="pl-0" name='Admission Notes' htmlFor="admission_notes" isRequired toolTip='Enter Admission Notes Here '/>
                    {submitted && (findings.trim().length === 0) ? <ErrorMessage>Please enter admission notes</ErrorMessage>:null}
                    <ResizableTextarea submitted={submitted} id="admission_notes"  rows="5" value={findings} name="notes" onChange={handleChangeNotes}/>
                </Form.Group>
            </Form.Group>
            </div>
            {children}
    </form>)
}
 
const DoctorAdmission = ({actions, snackbars, match:{params}}) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const {patient_number, visit_id, labrequest_id, scan_request_id, patient_type, patient_admission_id} = params

    // added parameter for the admission notes
    const {admissionProps, closeSnackbar, printDialog, handleClosePrintDialog, handleClick,
    assignProps, openDialog, handleCloseDialog,openAssignBed,
        handleOpenAssignBed, assignBed, handleCloseAssignBed} = useAddAdmission(actions, patient_number, false, params)
    const {patient} = usePatientDetails(actions, patient_number, '')
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const {openBar, type, message} = snackbars;
    const {first_name, last_name, Age, gender, address, phone_no} = patient;
    const arr = doctorRoutes(patient_number, visit_id,  patient_type, patient_admission_id)

    const {providerProps, openNewInsurance, handleCloseNewInsurance,isPendingProvider,
        handleSubmitProvider, handleOpenNewInsurance, newProviderProps,
        isDiagnosis,handleCloseAlert, handleSubmit, isPending} =  admissionProps

    const buttons = (
        <>
            <button type='button' onClick={handleSubmit} disabled={isPending} className='sms-info-btn btn-sm btn small-btn px-2 mr-3'>Yes</button>
            <button type='button' onClick={handleCloseAssignBed} className='sms-gray-btn btn btn-sm small-btn px-2'>No</button>
        </>

    )


    return (
        <div className="journals">
            <PageTitle title="Patient Admission"/>
            <SubHeader title="Doctor" subTitle="Patient Admission" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>

            <FullScreenDialog open={openDialog} handleClose={handleCloseDialog} title="Assign Bed">
                <AssignBed {...assignProps}/>
            </FullScreenDialog>
            <ConfirmationDialog open={assignProps.openConfirmation} handleClose={assignProps.handleCloseConfirmation}
                                title='Make cash payment'
                                confirmItems={assignProps.handleBillRoom}
                                removeItems={assignProps.handleCloseConfirmation} service='room'/>
            <CustomDialog open={openAssignBed} buttons={buttons} handleClose={handleCloseAssignBed} title='Assign bed'>
                <p style={{textAlign:'center'}}>Would you like to assign a bed to this patient?</p>
            </CustomDialog>
            <PrintDialog message="Admission Form" openDialog={printDialog} handleClose={handleClosePrintDialog}
                             text="Would you like to view and print this admission form" handleClick={handleClick}
                             handleCancel={handleClosePrintDialog}/>
            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
                <PatientInformation {...{patient_number, visit_id}}/>
                <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type}
                        message={message} duration={3500}/>
                <CustomDialog open={openNewInsurance} handleClose={handleCloseNewInsurance} title='Add Provider' isContentOverflow={true}>
                    <form onSubmit={handleSubmitProvider}>
                        <ProviderForm {...newProviderProps}/>
                        <CenteredContainer>
                            <button type='submit' disabled={isPendingProvider} className='btn btn-sm sms-btn'>{isPendingProvider ? 'Saving...' : 'Save'}</button>
                        </CenteredContainer>
                    </form>
                </CustomDialog>
                <div className="mt-2 px-3">
                {isDiagnosis  ? <Alert severity="error" style={{paddingTop:0, paddingBottom:0, marginBottom:'8px'}} onClose={handleCloseAlert}>
                    <span style={{display:'inline-block', marginRight:4}} >Before admitting a patient, please add their diagnosis</span>
                <Link to={{pathname: `/doctordiagnosis/${patient_number}/${visit_id}/${patient_type}/null`}}>Add diagnosis</Link>
            </Alert>: null}
                    <DoctorAdmissionForm  {...{providerProps,handleOpenNewInsurance, admissionProps}}>
                        <button type="button" onClick={handleOpenAssignBed} className="btn btn-sm sms-btn px-4"
                                data-testid="submitAdmissionForm">Save</button>
                    </DoctorAdmissionForm>
                </div>
            </ReusableDoctorTabs>

        </div>

    )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorAdmission);
