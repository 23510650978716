import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { Container, RightAlignedContainer } from "../../Utils/styledComponents";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages } from "../../../Helpers/ErrorMessages";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { DataExportButton } from "../../Utils/Buttons/DataExportationButton";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TextField from "../../Utils/FormInputs/TextField";

import {
  CustomTableCell,
  MuiPaper,
  StickyHeadTable,
} from "../../Utils/Tables/StickyHeaderTable";
import { LoadingGif } from "../../Utils/Loader";
import PageTitle from "../../Utils/smsTitle";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { Link } from "react-router-dom";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {
  AssetAccountingDetails,
  AssetDetails,
  AssetDetailsTemplate,
} from "./AssetDetailsTemplate";
import {dateConvert, formatDate} from "../../Utils/ConvertDate";
import { DisposeFixedAsset } from "./DisposeAsset";
import { ReusableRouteTabs } from "../../Utils/Dialogs/ReusableTabs";
import { history } from "../../../Helpers/history";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import {
  useExcelReports,
  usePdfDownload,
} from "../../Utils/ReusableComponents/useExcelExport";
import {ActiveStatus, ExpiredStatus, FinishedStatus, Processing} from "../../DoctorsModule/doctorStyles";
import {CustomTablePagination} from "../../Utils/Tables/CustomTable";
import {DateFilter} from "../../Billing/utils/DateFilter";

const headData = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date purchased",
  },
  {
    id: "asset_name",
    numeric: false,
    disablePadding: false,
    label: "Asset name",
  },
  { id: "cost", numeric: false, disablePadding: false, label: "Purchase cost" },
  {
    id: "current_value",
    numeric: false,
    disablePadding: false,
    label: "Current value",
  },
  {
    id: "depreciation_rate",
    numeric: false,
    disablePadding: false,
    align: "center",
    label: "Depreciation rate",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    align: "center",
    label: "Status",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    align: "center",
  },
];
export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    minHeight: 0,
  },
  spacer: {
    flex: "1 1 100%",
  },
}));

const FixedAssetList = ({ actions, snackbars }) => {
  const [date, setDate] = useState({
    start_date: dateConvert(),
    end_date: dateConvert(),
  });
  const [data, setData] = React.useState({ assets: [], loading: "idle", total_count:0 });
  const [page, setPage] = React.useState(0);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openDepreciation, setOpenDepreciation] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [asset_id, setAssetId] = React.useState("");
  const [isDeprecate, setIsDeprecate] = React.useState("idle");
  const [assetDetails, setAssetDetails] = React.useState({});
  const { assets, loading, total_count } = data;
  const [isDeleteFixedAsset, setDeleteFixedAsset] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const isDepreciateResolved = isDeprecate === "resolved";

  const {start_date, end_date} = date

  useEffect(() => {
    setData({ ...data, loading: "pending" });
    axios
      .get(`${config.smsUrl}/cinventory/fixed_asset/list`, {
        params: {
          search,
          start_date: start_date,
          end_date: end_date,
          page:activePage,
          per_page:rowsPerPage
        },
      })
      .then((res) => {
        const response = res.data;
        const dt = response ? response : {};
        const arr = dt.data ? dt.data : [];
        const dataArr = arr.map((item) => ({ ...item, id: item.id }));
        setData({ assets: dataArr, loading: "success", total_count: dt.total });
      })
      .catch((err) => {
        errorMessages(err, null, actions);
        setData({ ...data, loading: "error" });
      });
  }, [isDepreciateResolved, search, activePage, rowsPerPage, start_date, end_date]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setActivePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setActivePage(1);
  };

  const handleChangeDate = (event) => {
    const { name, value } = event.target;
    setDate({ ...date, [name]: value });
    setActivePage(1);
    setPage(0);
  };

  const handleOpenViewDialog = (item) => {
    const state = {
      ...item,
      warranty: item.waranty_period,
      serial_number: item.serial_no,
      date: formatDate(item.date_purchased),
    };
    const assetValue = {
      ...item,
      assetAccount: { label: item.asset_account_name },
      supplier: { label: item.supplier_name },
      depreciationExpense: { label: item.depreciated_expenses },
      depreciationAccount: { label: item.accumulated_depriciation },
      paymentAccount: { label: "" },
    };
    setAssetDetails({ state, assetValue });
    setOpenView(true);
  };

  const handleCloseViewDialog = () => {
    setOpenView(false);
  };

  const handleOpenDepreciationDialog = (item) => {
    setAssetId(item);
    const state = {
      ...item,
      warranty: item.waranty_period,
      serial_number: item.serial_no,
      date: formatDate(item.date_purchased),
    };
    setAssetDetails({ state });
    setOpenDepreciation(true);
  };

  const handleCloseDepreciationDialog = () => {
    setOpenDepreciation(false);
  };

  const handlecloseDeleteForm = () => {
    setDeleteFixedAsset(false);
  };
  const handleDeleteFixedAsset = () => {
    setDeleteFixedAsset(false);
    axios
      .post(`${config.smsUrl}/cinventory/delete_asset`, { asset_id: asset_id })
      .then((res) => {
        const info = res.data ? res.data : {};

        const message_response = info.error || info.message;

        // actions.snackbarActions.snackSuccess("Fixed Asset deleted successfully..");
        actions.snackbarActions.snackSuccess(message_response);

        setDeleteFixedAsset(false);
        onreload_Data();
      })
      .catch((e) => {
        setDeleteFixedAsset(false);
        errorMessages(e, null, actions);
        // setIsDeprecate("rejected");
      });
  };

  const onreload_Data = () => {
    setData({ ...data, loading: "pending" });
    axios
      .get(`${config.smsUrl}/cinventory/fixed_asset/list`)
      .then((res) => {
        const response = res.data;
        const dt = response ? response : {};
        const arr = dt.data ? dt.data : [];
        const dataArr = arr.map((item) => ({ ...item, id: item.id }));
        setData({ assets: dataArr, loading: "success" });
      })
      .catch((err) => {
        errorMessages(err, null, actions);
        setData({ ...data, loading: "error" });
      });
  };
  const handleDepreciateAsset = () => {
    setIsDeprecate("pending");
    const formData = new FormData();
    formData.append("fixed_asset_id", asset_id);
    axios
      .post(`${config.smsUrl}/accounting/fixed_asset/depreciate`, formData)
      .then(() => {
        setIsDeprecate("resolved");
        actions.snackbarActions.snackSuccess("Asset depreciation recorded");
        setOpenDepreciation(false);
      })
      .catch((err) => {
        errorMessages(err, null, actions);
        setIsDeprecate("rejected");
      });
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };
  const openDeleteDailog = (asset_id) => {
    setAssetId(asset_id);
    setDeleteFixedAsset(true);
  };

  const isLoading = loading === "pending";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  const { openBar, type, message } = snackbars;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, assets.length - page * rowsPerPage);

  const isDeprecatePending = isDeprecate === "pending";

  const buttons = (
    <>
      <button
        type="button"
        onClick={handleDepreciateAsset}
        disabled={isDeprecatePending}
        className="btn btn-sm sms-info-btn mr-4"
      >
        {isDeprecatePending ? "Saving..." : "Depreciate"}
      </button>
      <button
        type="button"
        onClick={handleCloseDepreciationDialog}
        className="btn btn-sm sms-gray-btn"
      >
        Cancel
      </button>
    </>
  );

  const components = [
    { label: "Fixed Asset List", path: "/accounting/fixedassets" },
    { label: "Depreciate Assets", path: "/accounting/depreciateassets" },
  ];

  const excelObj = {
    file_name: "Fixed Assets List excel",
    params: { download: 2 },
    url: "cinventory/fixed_asset/list/download",
  };

  const pdfObj = {
    file_name: "Fixed Assets List pdf",
    params: { download: 1 },
    url: "cinventory/fixed_asset/list/download",
  };

  const { exportExcel, isLoading: isLoadingExcel } = useExcelReports(
    actions,
    excelObj
  );
  const { exportPdf, isLoading: isLoadingPdf } = usePdfDownload(
    actions,
    pdfObj
  );
  const classes = useToolbarStyles();

  const pagination = (
      <CustomTablePagination
          colSpan={3}
          activePage={activePage}
          page={page}
          rowsPerPage={rowsPerPage}
          count={total_count}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          isTotalCount
      />
  );

  return (
    <div>
      <PageTitle title="Fixed Assets" />
      <SubHeader title="Accounting" subTitle="Fixed Assets">
        <FontAwesomeIcon icon={faClipboardList} />
      </SubHeader>
      <DisposeFixedAsset
        actions={actions}
        setIsSubmitted={setIsDeprecate}
        assetDetails={assetDetails}
        isSubmitted={isDeprecate}
        openDialog={openDepreciation}
        setOpenDialog={setOpenDepreciation}
      />
      <DeleteDialog
        message="delete"
        openDialog={isDeleteFixedAsset}
        handleClose={handlecloseDeleteForm}
        text="Fixed Asset"
        title="Delete Fixed Asset"
      >
        <button
          className="btn sms-info-btn btn-sm mr-3"
          onClick={handleDeleteFixedAsset}
        >
          Yes
        </button>
        <button
          className="btn sms-grey-btn btn-sm"
          onClick={handlecloseDeleteForm}
        >
          No
        </button>
      </DeleteDialog>

      <CustomDialog
        open={openView}
        title="View asset details"
        handleClose={handleCloseViewDialog}
        maxWidth="md"
      >
        <AssetDetailsTemplate>
          <AssetDetails {...assetDetails} />
          <AssetAccountingDetails {...assetDetails} />
        </AssetDetailsTemplate>
      </CustomDialog>
      <Container>
        <ReusableRouteTabs
          value={history.location.pathname}
          tabHeading={components}
        />
        <RightAlignedContainer>
          <Link to="/accounting/newfixedasset">
            <button type="button" className="btn btn-sm sms-info-btn mb-3">
              New Asset
            </button>
          </Link>
          <DataExportButton
            isLoading={isLoadingExcel || isLoadingPdf}
            downLoadPdf={exportPdf}
            exportCsv={exportExcel}
          />
        </RightAlignedContainer>
        <MainSnackbar
          open={openBar}
          message={message}
          variant={type}
          handleCloseBar={closeSnackbar}
        />
        <DateFilter
            {...{
              start_date,
              end_date,
              handleChangeDate,
              isSubmit: true,
            }}
        />
        <MuiPaper>
          <Toolbar className={clsx(classes.root)}>
            <div className=" col-md-4 col-lg-4 col-sm-12">
              <TextField
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                value={search}
                id="searchValue"
                placeholder="Search asset..."
              />
            </div>
            <div className={classes.spacer}></div>
          </Toolbar>
          <StickyHeadTable
            {...{
              width: "100%",
              page,
              rowsPerPage,
              handleChangePage,
              handleChangeRowsPerPage,
              data: assets,
              pagination,
              columns: headData.filter((item) => Boolean(item)),
            }}
          >
            {isLoading ? (
              <TableRow>
                <TableCell align="center" {...{ colSpan: headData.length }}>
                  <LoadingGif />
                </TableCell>
              </TableRow>
            ) : null}
            {isSuccess ? (
              assets.length > 0 ? (
                <>
                  {assets.slice(0, rowsPerPage).map((item, index) => {
                    const status = {
                      1: <FinishedStatus>Active</FinishedStatus>,
                      2: <ActiveStatus>Sale</ActiveStatus>,
                      3: <Processing>Donation</Processing>,
                      4:<ExpiredStatus>Salvage value reached</ExpiredStatus>
                    }

                    const buttons = {
                      1: (
                          <>
                            <button
                                type="button"
                                onClick={() => handleOpenDepreciationDialog(item)}
                                className="btn btn-sm sms-info-btn small-btn mr-3 mb-2"
                            >
                              Dispose
                            </button>
                            <button
                                onClick={() => handleOpenViewDialog(item)}
                                type="button"
                                className="btn btn-sm sms-info-btn small-btn mr-3 mb-2"
                            >
                              View
                            </button>
                            <button
                                onClick={() => openDeleteDailog(item.id)}
                                type="button"
                                className="btn btn-sm btn-danger small-btn mr-3 mb-2"
                            >
                              <FontAwesomeIcon icon={faTrash}/>
                            </button>
                          </>
                      ),
                      2: (
                          <>
                            <button
                                onClick={() => handleOpenViewDialog(item)}
                                type="button"
                                className="btn btn-sm sms-info-btn small-btn mr-3 mb-2"
                            >
                              View
                            </button>
                            <button
                                onClick={() => openDeleteDailog(item.id)}
                                type="button"
                                className="btn btn-sm btn-danger small-btn mr-3 mb-2"
                            >
                              <FontAwesomeIcon icon={faTrash}/>
                            </button>
                          </>
                      ),
                      3: (
                          <>
                            <button
                                onClick={() => handleOpenViewDialog(item)}
                                type="button"
                                className="btn btn-sm sms-info-btn small-btn mr-3 mb-2"
                            >
                              View
                            </button>
                            <button
                                onClick={() => openDeleteDailog(item.id)}
                                type="button"
                                className="btn btn-sm btn-danger small-btn mr-3 mb-2"
                            >
                              <FontAwesomeIcon icon={faTrash}/>
                            </button>
                          </>
                      ),
                      4: (
                          <>
                            <button
                                type="button"
                                onClick={() => handleOpenDepreciationDialog(item)}
                                className="btn btn-sm sms-info-btn small-btn mr-3 mb-2"
                            >
                              Dispose
                            </button>
                            <button
                                onClick={() => handleOpenViewDialog(item)}
                                type="button"
                                className="btn btn-sm sms-info-btn small-btn mr-3 mb-2"
                            >
                              View
                            </button>
                            <button
                                onClick={() => openDeleteDailog(item.id)}
                                type="button"
                                className="btn btn-sm btn-danger small-btn mr-3 mb-2"
                            >
                              <FontAwesomeIcon icon={faTrash}/>
                            </button>
                          </>
                      )
                    }
                    return (
                        <TableRow tabIndex={-1} key={item.id}>
                          <CustomTableCell>
                            {formatDate(item.date_purchased)}
                          </CustomTableCell>
                          <CustomTableCell>{item.asset_name}</CustomTableCell>
                          <CustomTableCell>{item.asset_cost}</CustomTableCell>
                          <CustomTableCell>{item.current_value}</CustomTableCell>
                          <CustomTableCell align="center">
                            {item.depreciation_rate}
                          </CustomTableCell>
                          <CustomTableCell align="center">
                            {status[item.status]}
                          </CustomTableCell>
                          <CustomTableCell align="right">
                            {buttons[item.status]}
                        </CustomTableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 43 * emptyRows }}>
                      <TableCell colSpan={headData.length} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell align="center" {...{ colSpan: headData.length }}>
                    No records found
                  </TableCell>
                </TableRow>
              )
            ) : null}
            {isError ? (
              <TableRow>
                <TableCell align="center" {...{ colSpan: headData.length }}>
                  The server did not return a valid response
                </TableCell>
              </TableRow>
            ) : null}
          </StickyHeadTable>
        </MuiPaper>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return { snackbars: state.snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FixedAssetList);
