import * as actions from "./billingActions"



export function billingProductsReducer(state, action) {
    const {products} = state
    console.log('actions', action.type)

    switch (action.type) {

        case actions.RETRIEVE_PRODUCTS:{

            let tempProducts = [];

            function checkProducts (){

                let productsRemovedBillIDs = [];

                if(!state.productsRemoved || state.productsRemoved.length < 1){

                    tempProducts = action.products;

                    return tempProducts;

                }

                for(let j = 0; j < state.productsRemoved.length; j++) {

                    productsRemovedBillIDs.push(state.productsRemoved[j]?.bill_id);

                }

                tempProducts = action.products.filter((product) => !productsRemovedBillIDs.includes(product?.bill_id))

                return [...state.productsRemoved, ...tempProducts];

            }

            const checkedProducts = checkProducts();

            action.calculateTotal(action?.services, tempProducts);

            return {...state, products: checkedProducts, productsPaid:action.products}
        }

        case actions.CHANGE_PRODUCT_CURRENCY_RATE : {
            const arr = products.map(item=>{
                const base_total = (action.exchangeRate ?  +action.value * +action.exchangeRate : 0) * +item.quantity
                if (item.bill_id === action.bill_id){
                    return {...item,rate:action.exchangeRate ?  +action.value * +action.exchangeRate : 0,currency_rate: action.value,
                        subTotal: base_total,isRate:true, currency_total: +action.value * +item.quantity}
                }
                return item
            })
            const prods = arr.filter(item=>!item.isExcluded)
            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate}));
            const unedited = arr.filter(item=>item.bill_id !== action.bill_id && !item.isRate).map(item=>({...item, rate: +item.rate}));
            action.calculateTotal(action.services, prods, action.exchangeRate, action?.bedBill);

            return {...state, products:arr, productsEdited:edited, productsPaid:unedited}

        }

        case actions.CHANGE_PRODUCT_RATE: {
            const arr = products.map(item=>{
                const base_total = (action.exchangeRate ?  +action.value / +action.exchangeRate : 0) * +item.quantity
                if (item.bill_id === action.bill_id){
                    return {...item,rate:action.value,currency_rate: action.exchangeRate ?  +action.value / +action.exchangeRate : 0,
                        subTotal: +item.quantity * +action.value,isRate:true, currency_total:base_total}
                }
                return item
            })
            const prods = arr.filter(item=>!item.isExcluded)
            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate}));
            const unedited = arr.filter(item=>item.bill_id !== action.bill_id && !item.isRate).map(item=>({...item, rate: +item.rate}));
            action.calculateTotal(action.services, prods, action.exchangeRate,action?.bedBill);
            return {...state, products:arr, productsPaid:unedited, productsEdited:edited}
        }

        case actions.CHANGE_PRODUCT_QUANTITY: {
            const arr = products.map(item=>{
                const base_total = (action.exchangeRate ?  +item.rate / +action.exchangeRate : 0) * +action.value
                if (item.bill_id === action.bill_id){
                    return {...item,quantity:action.value, currency_rate:action.exchangeRate ?  +item.rate / +action.exchangeRate : 0,
                        subTotal: +item.rate * +action.value, currency_total:base_total}
                }
                return item;
            })

            const unedited = arr.filter(item=>!item.isRate).map(item=>({...item, rate: +item.rate,quantity: +item.quantity}));
            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate,quantity: +item.quantity}));
            action.calculateTotal(action.services, arr, action.exchangeRate,action?.bedBill);
            return {...state, products:arr, productsPaid:unedited, productsEdited:edited}

        }

        case actions.DELETE_PRODUCT: {
            const removed = products.map(item =>{
                if(item.bill_id === action.bill_id){
                    return {...item, isExcluded:!item.isExcluded}
                }
                return item
            })
            const items = removed.map(item=>({...item,quantity: item.isExcluded ? 0 : item.quantity,
                subTotal:item.isExcluded ? 0 : item.subTotal}))
            const presc = items.filter(item => !item.isExcluded)
            const paidProducts = removed.filter(item=>!item.isRate).filter(item => !item.isExcluded)
            const removedItems = items.filter(item=>item.isExcluded)
            action.calculateTotal(action.services, presc, action.exchangeRate,action?.bedBill);
            return {...state, products:removed, productsPaid:paidProducts, productsRemoved:removedItems}
        }

        case actions.CALCULATE_PRODUCT_AMOUNT:{
            const arr = products.map(item=>{
                const currency_rate = {
                    'true': +item.rate * +action.rate,
                    'false': +item.rate / +action.rate
                    }
                return {...item,
                currency_rate: +action.rate ?   currency_rate[action.currency]:0, currency_total: (+action.rate ?  currency_rate[action.currency]: 0) * +item.quantity,
                subTotal:+item.rate * +item.quantity
                }})
            return {...state, products:arr}
        }
        default: {
            throw new Error(`Unsupported action type: ${action.type}`)
        }

    }

}

/*
* this combines the products reducer and the services reducer
* TL;DR the checkedProducts and checkedServices were failing antagonistically in their separate reducers */
export function billingProductsAndServicesReducer(state, action){
    const  {products, procedureData, bedBill} = state

    switch (action.type) {

        case actions.RETRIEVE_PRODUCTS_AND_SERVICES: {
            let tempProducts = [];

            let tempServices = [];

            function checkedProducts (){

                let productsRemovedBillIDs = [];

                if(state?.productsRemoved?.length < 1){

                    tempProducts = action.products;

                    return tempProducts;

                }

                for(let j = 0; j < state?.productsRemoved?.length; j++) {

                    productsRemovedBillIDs.push(state?.productsRemoved[j]?.bill_id);

                }

                tempProducts = action.products.filter((product) => !productsRemovedBillIDs.includes(product?.bill_id))

                return [...state?.productsRemoved, ...tempProducts];

            }

            function checkedServices (){

                let invoicesRemovedBillIDs = [];

                if(state?.invoicesRemoved?.length < 1){

                    tempServices = action.services;

                    return tempServices;

                }

                for(let j = 0; j < state?.invoicesRemoved?.length; j++) {

                    invoicesRemovedBillIDs.push(state?.invoicesRemoved[j]?.bill_id);

                }

                tempServices = action.services.filter((service) => !invoicesRemovedBillIDs.includes(service?.bill_id))

                return [...state?.invoicesRemoved, ...tempServices];

            }

            const updatedProducts = checkedProducts();

            const updatedServices = checkedServices();

            action.calculateTotal(updatedServices, updatedProducts, Number(action?.exchangeRate), action?.bed ?? null);

            return {...state, procedureData: updatedServices, invoicesPaid:tempServices, products: updatedProducts, productsPaid:tempProducts, bedBill:action.bed}
        }

        case actions.RETRIEVE_PRODUCTS:{

            let tempProducts = [];

            function checkProducts (){

                let productsRemovedBillIDs = [];

                if(!state?.productsRemoved || state?.productsRemoved?.length < 1){

                    tempProducts = action.products;

                    return tempProducts;

                }

                for(let j = 0; j < state?.productsRemoved?.length; j++) {

                    productsRemovedBillIDs.push(state.productsRemoved[j]?.bill_id);

                }

                tempProducts = action.products.filter((product) => !productsRemovedBillIDs.includes(product?.bill_id))

                return [...state?.productsRemoved, ...tempProducts];

            }

            const checkedProducts = checkProducts();

            action.calculateTotal(action?.services, tempProducts);

            return {...state, products: checkedProducts, productsPaid:tempProducts}
        }

        case actions.CHANGE_PRODUCT_CURRENCY_RATE : {
            const arr = products.map(item=>{
                const base_total = (action.exchangeRate ?  +action.value * +action.exchangeRate : 0) * +item.quantity
                if (item.bill_id === action.bill_id){
                    return {...item,rate:action.exchangeRate ?  +action.value * +action.exchangeRate : 0,currency_rate: action.value,
                        subTotal: base_total,isRate:true, currency_total: +action.value * +item.quantity}
                }
                return item
            })
            const prods = arr.filter(item=>!item.isExcluded)
            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate}));
            const unedited = arr.filter(item=>item.bill_id !== action.bill_id && !item.isRate).map(item=>({...item, rate: +item.rate}));
            action.calculateTotal(action.services, prods, action.exchangeRate, action?.bedBill);

            return {...state, products:arr, productsEdited:edited, productsPaid:unedited}

        }

        case actions.CHANGE_PRODUCT_RATE: {
            const arr = products.map(item=>{
                const base_total = (action.exchangeRate ?  +action.value / +action.exchangeRate : 0) * +item.quantity
                if (item.bill_id === action.bill_id){
                    return {...item,rate:action.value,currency_rate: action.exchangeRate ?  +action.value / +action.exchangeRate : 0,
                        subTotal: +item.quantity * +action.value,isRate:true, currency_total:base_total}
                }
                return item
            })
            const prods = arr.filter(item=>!item.isExcluded)
            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate}));
            const unedited = arr.filter(item=>item.bill_id !== action.bill_id && !item.isRate).map(item=>({...item, rate: +item.rate}));
            action.calculateTotal(action.services, prods, action.exchangeRate,action?.bedBill);
            return {...state, products:arr, productsPaid:unedited, productsEdited:edited}
        }

        case actions.CHANGE_PRODUCT_QUANTITY: {
            const arr = products.map(item=>{
                const base_total = (action.exchangeRate ?  +item.rate / +action.exchangeRate : 0) * +action.value
                if (item.bill_id === action.bill_id){
                    return {...item,quantity:action.value, currency_rate:action.exchangeRate ?  +item.rate / +action.exchangeRate : 0,
                        subTotal: +item.rate * +action.value, currency_total:base_total}
                }
                return item;
            })

            const unedited = arr.filter(item=>!item.isRate).map(item=>({...item, rate: +item.rate,quantity: +item.quantity}));
            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate,quantity: +item.quantity}));
            action.calculateTotal(action.services, arr, action.exchangeRate,action?.bedBill);
            return {...state, products:arr, productsPaid:unedited, productsEdited:edited}

        }

        case actions.DELETE_PRODUCT: {
            const removed = products.map(item =>{
                if(item.bill_id === action.bill_id){
                    return {...item, isExcluded:!item.isExcluded}
                }
                return item
            })
            const items = removed.map(item=>({...item,quantity: item.isExcluded ? 0 : item.quantity,
                subTotal:item.isExcluded ? 0 : item.subTotal}))

            const presc = items.filter(item => !item.isExcluded)

            const paidProducts = removed.filter(item=>!item.isRate).filter(item => !item.isExcluded)
            const removedItems = items.filter(item=>item.isExcluded)

            action.calculateTotal(action.services, presc, action.exchangeRate,action?.bedBill);
            return {...state, products:removed, productsPaid:paidProducts, productsRemoved:removedItems}
        }

        case actions.PAY_PRODUCT: {
            const paid = products.map(item =>{
                if(item.product_id === action.product_id && item.pay_status === 0){
                    return {...item, isPaid:action.value}
                }
                return item
            })
            const presc = paid.filter(item => item.isPaid)


            const paidProducts = paid.filter(item=>!item.isRate).filter(item => item.isPaid)


            action.calculateTotal(action.services, paid, action.exchangeRate,action?.bedBill);
            return {...state, products:paid, productsPaid:paidProducts}
        }

        case actions.CHECK_ALL_PRODUCTS: {
            const paid = products.map(item =>{
                if (item.pay_status === 0){
                    return {...item, isPaid:action.value}
                }
                return item
            })
            const presc = paid.filter(item => item.isPaid)

            const paidProducts = paid.filter(item=>!item.isRate).filter(item => item.isPaid)

            action.calculateTotal(action.services, paid, action.exchangeRate,action?.bedBill);
            return {...state, products:paid, productsPaid:paidProducts, checkAllProducts:action.value}
        }

        case actions.CALCULATE_PRODUCT_AMOUNT:{
            const arr = products.map(item=>{
                const currency_rate = {
                    'true': +item.rate * +action.rate ?? +action.exchangeRate,
                    'false': +item.rate / +action.rate ?? +action.exchangeRate
                }
                return {...item,
                    currency_rate: +action.rate ?  currency_rate[action.currency]:0,
                    currency_total: (+action.rate ?  currency_rate[action.currency]: 0) * +item.quantity,
                    subTotal:+item.rate * +item.quantity,
                    amount:+item.rate * +item.quantity
                }})
            return {...state, products:arr}
        }

        case actions.RETRIEVE_SERVICES:{

            let tempServices = [];
            function checkServices (){

                let invoicesRemovedBillIDs = [];

                if(!state?.invoicesRemoved || state?.invoicesRemoved?.length < 1){

                    tempServices = action.services;

                    return tempServices;

                }

                for(let j = 0; j < state?.invoicesRemoved?.length; j++) {

                    invoicesRemovedBillIDs.push(state?.invoicesRemoved[j]?.bill_id);

                }

                tempServices = action.services.filter((service) => !invoicesRemovedBillIDs.includes(service?.bill_id))

                return [...state?.invoicesRemoved, ...tempServices];

            }

            const checkedServices = checkServices();

            action.calculateTotal(tempServices, action.products);

            return {...state, procedureData: checkedServices, invoicesPaid:tempServices}
        }

        case actions.CHANGE_SERVICE_CURRENCY_RATE : {
            const arr = procedureData.map(item=>{
                const base_total = (action.exchangeRate ?  +action.value * +action.exchangeRate : 0) * +item.quantity
                if (item.bill_id === action.bill_id){
                    return {...item,rate: action.exchangeRate ?  +action.value * +action.exchangeRate : 0, currency_rate: action.value,
                        subTotal:base_total, isRate:true, currency_total: +action.value * +item.quantity}
                }
                return item
            })
            const procedures = arr.filter(item=>!item.isExcluded)

            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate}));
            const unedited = arr.filter(item=>item.bill_id !== action.bill_id).map(item=>({...item, rate: +item.rate}));

            action.calculateTotal(procedures,action.products, action.exchangeRate,action?.bedBill)
            return {...state, procedureData:arr, invoicesEdited:edited,invoicesPaid:unedited}
        }

        case actions.CHANGE_SERVICE_RATE: {
            const arr = procedureData.map(item=>{
                const base_total = (action.exchangeRate ?  +action.value / +action.exchangeRate: 0) * +item.quantity
                if (item.bill_id === action.bill_id){
                    return {...item,rate:action.value, currency_rate: action.exchangeRate ?  +action.value / +action.exchangeRate : 0,
                        subTotal: +item.quantity * +action.value, isRate:true, currency_total:base_total}
                }
                return item
            })
            const procedures = arr.filter(item=>!item.isExcluded)

            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate}));
            const unedited = arr.filter(item=>item.bill_id !== action.bill_id).map(item=>({...item, rate: +item.rate}));

            action.calculateTotal(procedures,action.products, action.exchangeRate,action?.bedBill)
            return {...state, procedureData:arr, invoicesEdited:edited,invoicesPaid:unedited}
        }

        case actions.DELETE_SERVICE: {
            const removed = procedureData.map(item =>{
                if(item.bill_id === action.bill_id){
                    return {...item, isExcluded:!item.isExcluded}
                }
                return item
            })
            const items = removed.map(item=>({...item,quantity: item.isExcluded ? 0 : item.quantity,
                subTotal:item.isExcluded ? 0 : item.subTotal})).filter(item => !item.isExcluded)

            const paidInvoices = removed.filter(item => !item.isExcluded && !item.isRate)
            const removedItems = removed.filter(item=>item.isExcluded)

            action.calculateTotal(items,action.products, action.exchangeRate,action?.bedBill)
            return {...state, procedureData:removed, invoicesPaid:paidInvoices, invoicesRemoved:removedItems}
        }

        case actions.PAY_SERVICE: {
            const paid = procedureData.map(item =>{
                if(item.service_id === action.service_id && item.pay_status === 0){
                    return {...item, isPaid:action.value}
                }
                return item
            })
            const items = paid.filter(item => item.isPaid)


            const paidInvoices = paid.filter(item => item.isPaid && !item.isRate)


            action.calculateTotal(paid, action.products, action.exchangeRate,action?.bedBill)
            return {...state, procedureData:paid, invoicesPaid:paidInvoices}
        }

        case actions.CHECK_ALL_SERVICES: {
            const paid = procedureData.map(item =>{
                if (item.pay_status === 0){
                    return {...item, isPaid:action.value}
                }
                return item
            })

            const paidInvoices = paid.filter(item => item.isPaid && !item.isRate)

            action.calculateTotal(paid,action.products, action.exchangeRate,action?.bedBill)
            return {...state, procedureData:paid, invoicesPaid:paidInvoices, checkAllServices:action.value}
        }

        case actions.CALCULATE_SERVICE_AMOUNT:{
            const proceduresArr = procedureData.map(item=>{
                const currency_rate = {
                    'true': +item.rate * +action.rate,
                    'false': +item.rate / +action.rate
                }
                return {...item,
                    currency_rate: +action.rate ?  currency_rate[action.currency]: 0, currency_total: (+action.rate ? currency_rate[action.currency] : 0) * +item.quantity,
                    subTotal:+item.rate * +item.quantity,
                    amount:+item.rate * +item.quantity
                }})
            return {...state, procedureData:proceduresArr}
        }

        case actions.PAY_BED: {
            const paid = bedBill.map(item =>{
                if(item.bill_id === action.bill_id && item.pay_status === 0){
                    return {...item, isPaid:action.value}
                }
                return item
            })
            action.calculateTotal(action.services, action.products, action.exchangeRate,paid);
            return {...state, bedBill:paid}
        }

        case actions.CHECK_ALL_BEDS: {
            const paid = bedBill.map(item =>{
                if (item.pay_status === 0){
                    return {...item, isPaid:action.value}
                }
                return item
            })
            action.calculateTotal(action.services, action.products, action.exchangeRate,paid);
            return {...state, bedBill:paid, checkAllBeds:action.value}
        }

        case actions.CALCULATE_BED_AMOUNT:{
            const arr = bedBill.map(item=>{
                const currency_rate = {
                    'true': +item.rate * +action.rate,
                    'false': +item.rate / +action.rate
                }
                return {...item,
                    currency_rate: +action.rate ?  currency_rate[action.currency]:0,
                    currency_total: (+action.rate ?  currency_rate[action.currency]: 0) * +item.quantity,
                    subTotal:+item.rate * +item.quantity,
                    amount:+item.rate * +item.quantity,
                }})
            return {...state, bedBill:arr}
        }

        default: {
            throw new Error(`Unsupported action type: ${action.type}`)
        }

    }

}