import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions,DialogContent,DialogTitle} from "../SmsDialog";


const PrintDialog = ({openDialog, handleClose,handleClick,message,text, handleCancel, sample, next,
                         preview,disabled, isPending = false}) =>{
    return(
        <Dialog open={openDialog} data-testid="print-dialog">
            <DialogTitle onClose={handleClose}>
                <div style={{fontSize:15}} className="text-center">
                     {message}
                </div>
            </DialogTitle>
            <DialogContent>
                {text}
            </DialogContent>
            {!sample && <DialogActions>
                <button id='yesBtn' disabled={isPending}  className="btn sms-info-btn btn-sm" onClick={handleClick}>{next ? next:'Yes'}</button>
                <button id='noBtn' disabled={disabled} className="btn sms-grey-btn btn-sm" onClick={handleCancel}>{preview ? preview:'No'}</button>
            </DialogActions>}
        </Dialog>
    )

};
export default PrintDialog;
