import React, { useCallback, useEffect, useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { connect } from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import { bindActionCreators } from "redux";
import CustomTable, {
    CustomTablePagination,
    useHiddenColumns,
} from "../Utils/Tables/CustomTable";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import { dateConvert, formatDateTime } from "../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {DateFilter} from "../Billing/utils/DateFilter";
import { useVersionsContext } from "../../Context/versions-context";
import {history} from "../../Helpers/history";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import {useDebounce} from "../Lab/useDebounce";
import {BillingSubheading} from "../Billing/utils/BillingSubheading";
import {ReturnPrescription} from "./ReturnPrescription";
import {ViewReturnedMedicine} from "./ViewReturnedMedicine";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import Label from "../Utils/FormInputs/Label";



/**
 * Component that renders the List of medicine returns
 * @param {*} param0
 * @returns
 */

const PrescriptionReturns = ({ actions, snackbars, match:{params}}) => {
    const {screen} = params
    const { subscription } = useVersionsContext();
    const version = subscription?.version;

    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [term, setTerm] = useState("");
    const [prescription, setPrescription] = useState([]);
    const [status, setStatus] = useState("idle");
    const [total_count, setTotalCount] = useState(0);
    const [date, setDate] = useState({
        start_date: dateConvert(),
        end_date: dateConvert(),
    });
    const [openReturns, setOpenReturns] = useState(false)
    const [openViewReturns, setOpenViewReturns] = useState(false)
    const [ids, setIds] = useState({})
    const [isSubmitted, setIsSubmitted]  = useState('idle')
    const [nurses, setNurses] = useState([])
    const [recorded_by, setRecordedBy ] = useState({value:'', label:''})

    const [debouncedInputValue] = useDebounce(term, 500)

    const isResolved = isSubmitted === 'resolved'

    useEffect(() => {
        axios.post(`${config.smsUrl}/permission/user_roles_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.user_roles_list ? [] :dt.user_roles_list;
            const arr = list.map(item=>({
                value:item.user_id,
                label:`${item.first_name} ${item.last_name}`
            }))
            setNurses(arr);
        }).catch((err) => {
            errorMessages(err, null, actions);
        })
    }, []);

    useEffect(()=>{
        setPage(0)
        setActivePage(1)
        setRowsPerPage(50)
        setDate({
            start_date: dateConvert(),
            end_date: dateConvert(),
        })
        setTerm('')
        setRecordedBy({value:'', label:''})
    },[screen])



    const { start_date, end_date } = date;


    useEffect(() => {
        const param_key = {
            '0':'created_by',
            '1':'received_by'
        }
        axios.get(`${config.smsUrl}/cpharmacy/medicine-return`,
            {params:{search:debouncedInputValue, start_date: start_date, end_date: end_date, page:activePage,
                    per_page:rowsPerPage, status:+screen, [`${param_key[screen]}`]:recorded_by?.value ? recorded_by?.value : ''}})
            .then((res) => {
                const data = res.data;
                const items = !data ? {} : data;
                const prescriptions = !items.medicine_returns ? [] : items.medicine_returns;
                const count = !prescriptions.total ? 0 : prescriptions.total;
                const arr = prescriptions.map((item) => ({
                    ...item,
                    date_returned: formatDateTime(item.created_at),
                    date_received: formatDateTime(item.received_at),
                    patient_name: `${item.patient_first_name ? item.patient_first_name : ''} ${item.patient_last_name ? item.patient_last_name : ''}`,
                    returned_by: `${item.created_by_first_name ? item.created_by_first_name : ''} ${item.created_by_last_name ? item.created_by_last_name : ''}`,
                    received_by: `${item.received_by_first_name ? item.received_by_first_name : ''} ${item.received_by_last_name ? item.received_by_last_name : ''}`
                }));
                setPrescription(arr);
                setTotalCount(count);
                setStatus("success");
            })
            .catch((err) => {
                logoutErrorMessage(err, null, actions);
                setStatus("error");
            });
    }, [activePage, debouncedInputValue, start_date, end_date, rowsPerPage, recorded_by?.value, screen, isResolved]);

    const handleChangeRecordedBy = (value) =>{
        setRecordedBy(value)
    }

    const handleChangeActivePage = (page) => {
        setActivePage(page);
    };

    const handler = (event) => {
        setTerm(event.target.value);
        setActivePage(1);
        setPage(0);
    };

    const handleChangeDate = (event) => {
        const { name, value } = event.target;
        setDate({ ...date, [name]: value });
        setActivePage(1);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };


    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const isLoading = status === "pending";
    const isSuccess = status === "success";
    const isRejected = status === "error";

    const { openBar, type, message } = snackbars;

    const headData = [
        {
            id: "count",
            numeric: false,
            disablePadding: false,
            label: "SL",
            hidden: false,
        },
        {
            id: "patient_number",
            numeric: false,
            disablePadding: false,
            label: "Patient number",
            hidden: false,
        },
        {
            id: "patient_name",
            numeric: false,
            disablePadding: false,
            label: "Patient name",
            hidden: false,
        },
        {
            id: "date_returned",
            numeric: false,
            disablePadding: false,
            label: "Date returned",
            hidden: false,
        },
        {
            id: "returned_by",
            numeric: false,
            disablePadding: false,
            label: "Returned by",
            hidden: false,
        },
        screen === '1' ? {
            id: "date_received",
            numeric: false,
            disablePadding: false,
            label: "Date received",
            hidden: false,
        }: null,

        screen === '1' ? {
            id: "received_by",
            numeric: false,
            disablePadding: false,
            label: "Received by",
            hidden: false,
        }: null,
        {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Action",
            hidden: false,
        },
    ].filter(item=>Boolean(item));

    const all_prescriptions = prescription.map((item, index) => {
        const count = (activePage - 1) * rowsPerPage + index + 1;
        const action = {
            '0': (
                <button onClick={()=>{
                    setOpenReturns(true)
                    setIds(item)
                }}
                    className="btn btn-sm sms-info-btn mr-1"
                    style={{marginBottom: "4px"}}
                >
                    Receive remaining medicine
                </button>
            ),
            '1': (
                <button
                    onClick={()=>{
                        setOpenViewReturns(true)
                        setIds(item)
                    }}
                    className="btn btn-sm sms-info-btn mr-1"
                    style={{marginBottom: "4px"}}
                >
                    View
                </button>
            )
        }
        return {
            ...item,
            count,
            action:action[screen],
        };
    });


    const { headCells, all_hidden, handleAllHeadCells, handleHeadCells } =
        useHiddenColumns(headData);


    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const components = [{label: 'Out-Patient Prescriptions', path: '/medicalAnalysis/out-patient',  tier: ["Basic", "Standard", "Premium"]},
        {label: 'In-Patient Prescriptions', path: '/medicalAnalysis/in-patient', tier: ["Standard", "Premium"]},
        {label: 'In-Patient Medicine Returns', path: `/in-patient-medicine-return/${screen}`, tier: ["Standard", "Premium"]},
        {label: 'Previous Prescriptions', path: '/medicalAnalysis/previous',  tier: ["Basic", "Standard", "Premium"]},
    ]



    const links = [
        {
            label:'Pending',
            active:screen === "0",
            route:'/in-patient-medicine-return/0',
            onClick:()=> history.push('/in-patient-medicine-return/0')
        },
        {
            label:'Received',
            active:screen === "1",
            route:'/in-patient-medicine-return/1',
            onClick:()=> history.push('/in-patient-medicine-return/1')
        },
    ]

    const title = {
        '0':'Pending Medicine Returns',
        '1':'Received Medicine Returns'
    }
    const label_text ={
        '0':'Returned by',
        '1':'Received by'
    }

    const recordedBy = (
        <Form.Group as={Row}>
            <Col xs={12} sm={4} md={3} lg={3} xl={3}>
                <Label name={label_text[screen]}/>
            </Col>
            <Col xs={12} sm={6}>
                <CustomSelect value={recorded_by} options={nurses} onChange={handleChangeRecordedBy}/>
            </Col>
        </Form.Group>
    )

    return (
        <div className="journals">
            <PageTitle title={title[screen]}/>
            <SubHeader title="Prescription" subTitle={title[screen]}>
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <div className='mui-tables'>
                <ReusableRouteTabs
                    value={history.location.pathname}
                    tabHeading={components}
                    version={version}
                />
                <BillingSubheading links={links}/>
                <ReturnPrescription {...{
                    actions, openDialog:openReturns, setOpenDialog:setOpenReturns, prescriptionIds:ids,
                    isSubmitted, setIsSubmitted
                }}/>
                <ViewReturnedMedicine {...{ actions, openDialog:openViewReturns, setOpenDialog:setOpenViewReturns, prescriptionIds:ids}}/>
                <div className='mb-2'>
                    <DateFilter
                        {...{
                            start_date,
                            end_date,
                            handleChangeDate,
                            isSubmit: true,
                        }}
                    />
                </div>

                <MainSnackbar
                    handleCloseBar={closeSnackBar}
                    message={message}
                    open={openBar}
                    variant={type}
                />
                <CustomTable
                    colSpan={7}
                    data={all_prescriptions}
                    headData={headCells}
                    {...{all_hidden, handleAllHeadCells, handleHeadCells, pagination}}
                    activePage={activePage}
                    handler={handler}
                    handleChangeNextPage={handleChangeActivePage}
                    total_count={total_count}
                    records={10}
                    customPage
                    term={term}
                    title={recordedBy}
                >
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={headCells.length} align="center">
                                    <img src="/images/smsloader.gif" alt="loader"/>
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {isSuccess ? (
                            all_prescriptions.length > 0 ? (
                                all_prescriptions.slice(0, rowsPerPage).map((bill) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={bill.bill_id}
                                            className="mui-table-row"
                                        >
                                            {headCells.map((head) => (
                                                <TableCell
                                                    id={head.id}
                                                    key={head.id}
                                                    hidden={head.hidden}
                                                >
                                                    {bill[head.id]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={headCells.length} align="center">
                                        No records found
                                    </TableCell>
                                </TableRow>
                            )
                        ) : null}
                        {isRejected ? (
                            <TableRow>
                                <TableCell colSpan={headCells.length} align="center">
                                    The server did not return a valid response
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </CustomTable>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionReturns);
