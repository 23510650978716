import React, { useCallback, useEffect, useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { connect } from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import * as billingActions from "../../../actions/BillingActions/BillingActions";
import { bindActionCreators } from "redux";
import CustomTable, {
    CustomTablePagination,
    useHiddenColumns,
} from "../../Utils/Tables/CustomTable";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { titleCase } from "../../Users/addUser";
import { dateConvert, formatDateTime } from "../../Utils/ConvertDate";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { logoutErrorMessage } from "../../../Helpers/ErrorMessages";
import moment from "moment";
import { DateFilter } from "../utils/DateFilter";
import { useVersionsContext } from "../../../Context/versions-context";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import {useDebounce} from "../../Lab/useDebounce";



/**
 * Component that renders the List of bills
 * @param {*} param0
 * @returns
 */
const BillingListTable = ({ actions, snackbars, headData, billStatus }) => {
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [term, setTerm] = useState("");
    const [billingList, setBillingList] = useState([]);
    const [status, setStatus] = useState("idle");
    const [total_count, setTotalCount] = useState(0);
    const [date, setDate] = useState({
        start_date: dateConvert(),
        end_date: dateConvert(),
    });
    const [debouncedValue] = useDebounce(term, 500)

    const convertHourts = (date) => {
        return moment().diff(moment(date), "days");
    };

    const { start_date, end_date } = date;

    useEffect(() => {
        setDate({ start_date: dateConvert(), end_date: dateConvert() });
        setActivePage(1);
        setPage(0);
    }, [billStatus]);

    const retrieveBillingList = useCallback(
        (s_date, e_date) => {
            const formData = new FormData();
            formData.append("search", debouncedValue);
            formData.append("status", billStatus);
            formData.append("patient_type", 1);
            formData.append("start_date", s_date);
            formData.append("end_date", e_date);
            formData.append("per_page", rowsPerPage);
            setStatus("pending");
            axios
                .post(`${config.smsUrl}/cbilling/bill_list/${activePage}`, formData)
                .then((res) => {
                    const data = res.data;
                    const bills = !data ? {} : data;
                    const all_bills = !bills.bill_list ? [] : bills.bill_list;
                    const count = !bills.total_count ? 0 : bills.total_count;
                    const arr = all_bills.map((item) => ({
                        ...item,
                        days: convertHourts(item.begin_datetime),
                    }));
                    setBillingList(arr);
                    setTotalCount(count);
                    setStatus("success");
                })
                .catch((err) => {
                    logoutErrorMessage(err, null, actions);
                    setStatus("error");
                });
        },
        [activePage, debouncedValue, billStatus, start_date, end_date, rowsPerPage]
    );

    useEffect(() => {
        retrieveBillingList(start_date, end_date);
        const interval = setInterval(() => {
            retrieveBillingList(start_date, end_date);
        }, 10000);

        return () => clearInterval(interval);
    }, [retrieveBillingList]);

    const handleChangeActivePage = (page) => {
        setActivePage(page);
    };

    const handler = (event) => {
        setTerm(event.target.value);
        setActivePage(1);
        setPage(0);
    };

    const handleChangeDate = (event) => {
        const { name, value } = event.target;
        setDate({ ...date, [name]: value });
        setActivePage(1);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const closeSnackBar = () => {
        actions.snackbarAction.hideSnackbar();
    };

    const isLoading = status === "pending";
    const isSuccess = status === "success";
    const isRejected = status === "error";

    const { openBar, type, message } = snackbars;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const all_bills = billingList.map((bill, index) => {
        const count = (activePage - 1) * rowsPerPage + index + 1;
        const bill_status = {
            0: (
                <>
                    <Link
                        to={{ pathname: `/newbill/${bill.visit_id}` }}
                        style={{
                            textDecoration: "none",
                            color: palette.type === "dark" ? "#fff" : "#3d3d3d",
                            marginRight: "20px",
                        }}
                    >
                        <button
                            className="btn btn-sm sms-info-btn"
                            style={{ marginBottom: "4px", width: "130px" }}
                        >
                            Create Receipt
                        </button>
                    </Link>
                    {/*<Link*/}
                    {/*    to={{pathname:  `/createinvoice/${bill.visit_id}`}}*/}
                    {/*    style={{*/}
                    {/*        textDecoration: "none",*/}
                    {/*        color: palette.type === "dark" ? "#fff" : "#3d3d3d"*/}
                    {/*    }}>*/}
                    {/*    <button className="btn btn-sm sms-gray-btn mr-1" style={{marginBottom:'4px', width:'130px'}}>Create Invoice*/}
                    {/*    </button>*/}
                    {/*</Link>*/}
                    <Link
                        to={{ pathname: `/viewbill/${bill.visit_id}` }}
                        style={{
                            textDecoration: "none",
                            color: palette.type === "dark" ? "#fff" : "#3d3d3d",
                        }}
                    >
                        <button
                            className="btn btn-sm sms-amber-btn ml-1"
                            style={{ marginBottom: "4px" }}
                        >
                            View
                        </button>
                    </Link>
                </>
            ),
            1: (
                <>
                    <Link
                        to={{ pathname: `/billingreceipt/${bill.visit_id}` }}
                        style={{
                            textDecoration: "none",
                            color: palette.type === "dark" ? "#fff" : "#3d3d3d",
                        }}
                    >
                        <button
                            className="btn btn-sm sms-info-btn mr-1"
                            style={{ marginBottom: "4px" }}
                        >
                            View
                        </button>
                    </Link>
                    {bill.days <= 1 ? (
                        <Link
                            to={{ pathname: `/refundbill/${bill.visit_id}` }}
                            style={{
                                textDecoration: "none",
                                color: palette.type === "dark" ? "#fff" : "#3d3d3d",
                                marginRight: "20px",
                            }}
                        >
                            <button
                                className="btn btn-sm sms-amber-btn"
                                style={{ marginBottom: "4px" }}
                            >
                                Refund
                            </button>
                        </Link>
                    ) : null}
                </>
            ),
            2: (
                <Link
                    to={{ pathname: `/billingreceipt/${bill.visit_id}` }}
                    style={{
                        textDecoration: "none",
                        color: palette.type === "dark" ? "#fff" : "#3d3d3d",
                    }}
                >
                    <button
                        className="btn btn-sm sms-info-btn mr-1"
                        style={{ marginBottom: "4px" }}
                    >
                        View
                    </button>
                </Link>
            ),
        };
        return {
            ...bill,
            count,
            date: formatDateTime(bill.begin_datetime),
            patient_name: titleCase(
                `${!bill.patient_firstname ? "" : bill.patient_firstname} ${
                    !bill.patient_lastname ? "" : bill.patient_lastname
                }`
            ),
            received_by: titleCase(
                `${!bill.received_by_first_name ? "" : bill.received_by_first_name} ${
                    !bill.received_by_last_name ? "" : bill.received_by_last_name
                }`
            ),
            action: bill_status[billStatus],
        };
    });


    const { headCells, all_hidden, handleAllHeadCells, handleHeadCells } =
        useHiddenColumns(headData);

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const file_name = {
        0:'Unpaid bills',
        1:'Paid bills by visit'
    }

    const obj = {
        file_name:file_name[billStatus],
        params: {
            search: term,
            status: billStatus,
            patient_type: 1,
            start_date: start_date,
            end_date: end_date,
        },
        url: "/cbilling/bill_list/export"
    };

    const { exportExcel, isLoading: isLoadingExcel } = useExcelReports(
        actions,
        {...obj, params:{...obj.params,  is_pdf: "false"}}
    );
    const { exportPdf, isLoading: isLoadingPdf } = usePdfDownload(
        actions,
        {...obj, params:{...obj.params,  is_pdf: "true"}}
    );

    return (
        <div className="journals">
                    <DateFilter
                        {...{
                            start_date,
                            end_date,
                            handleSubmit,
                            handleChangeDate,
                            isSubmit: true,
                        }}
                    />
                <RightAlignedContainer>
                    <div className='mb-2'>
                        <DataExportButton
                            isLoading={isLoadingExcel || isLoadingPdf}
                            downLoadPdf={exportPdf}
                            exportCsv={exportExcel}
                        />
                    </div>
                </RightAlignedContainer>

                <MainSnackbar
                    handleCloseBar={closeSnackBar}
                    message={message}
                    open={openBar}
                    variant={type}
                />
                <CustomTable
                    colSpan={7}
                    data={all_bills}
                    headData={headCells}
                    {...{ all_hidden, handleAllHeadCells, handleHeadCells, pagination }}
                    activePage={activePage}
                    handler={handler}
                    handleChangeNextPage={handleChangeActivePage}
                    total_count={total_count}
                    records={10}
                    customPage
                    term={term}
                >
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={headCells.length} align="center">
                                    <img src="/images/smsloader.gif" alt="loader" />
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {isSuccess ? (
                            all_bills.length > 0 ? (
                                all_bills.slice(0, rowsPerPage).map((bill) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={bill.bill_id}
                                            className="mui-table-row"
                                        >
                                            {headCells.map((head) => (
                                                <TableCell
                                                    id={head.id}
                                                    key={head.id}
                                                    hidden={head.hidden}
                                                >
                                                    {bill[head.id]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={headCells.length} align="center">
                                        No records found
                                    </TableCell>
                                </TableRow>
                            )
                        ) : null}
                        {isRejected ? (
                            <TableRow>
                                <TableCell colSpan={headCells.length} align="center">
                                    The server did not return a valid response
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </CustomTable>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar,
        billing: state.billing,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarAction: bindActionCreators(snackbarActions, dispatch),
            billingActions: bindActionCreators(billingActions, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingListTable);
