/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react'
import React, {useEffect, useState} from "react";
import {titleCase} from "../../Users/addUser";
import {useTheme} from "@material-ui/core/styles";
import {ItemIcon} from "../../Utils/Lists/CollapsibleList";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {usePatientInformation} from "./hooks/usePatientInformation";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {formatDateTime} from "../../Utils/ConvertDate";
import {detailsTable, paddingCell, smallTable, table, textWeight} from "../../../styles/tableStyles";

export const PatientInformation = ({patient_number,visit_id, children,title, isChronic=true, isResolved=false, ...props}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {patient, handleOpen, handleOpenDialog, open, openDialog} = usePatientInformation(patient_number, isResolved)

    const [visitDetails, setVisitDetails] = useState({})


    useEffect(()=>{
        if(!visit_id){
            return
        }
        axios.get(`${config.smsUrl}/cvisit/${visit_id}`).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            setVisitDetails(dt)
        }).catch(err=>{
        })
    },[visit_id]);

    const {patient_name, age, gender, phone, address} = patient
     
    return (
            <div className={`patient-information  ${palette.type === 'dark' && 'dark-patient-information'} `} {...props}>
                <h6 className='patient-personal-details'>{title ? title :  'PERSONAL DETAILS'}</h6>
                <div className="row ">
                    <div className="col-md-4">
                        <p>Patient Name: <strong>{titleCase(patient_name)}</strong></p>
                        <p>Age: <strong>{age}</strong></p>
                        {visitDetails.is_on_insurance === 1 ?
                            <p>Provider Name: <strong>{visitDetails?.insurance_provider_name}</strong></p>:null}
                    </div>
                    <div className="col-md-4">
                        <p>Gender: <strong>{titleCase(gender)}</strong></p>
                        <p>Phone: <strong>{phone}</strong></p>
                        {visitDetails.is_on_insurance === 1 ?
                            <p>Card  Holder: <strong>{visitDetails?.account_name}</strong></p> : null}
                    </div>
                    <div className="col-md-4">
                    <p>Address: <strong>{titleCase(address)}</strong></p>
                        <p>Payment Mode: <strong>{visitDetails.is_on_insurance === 1 ? 'CREDIT' : visitDetails.is_on_insurance === 0 ?  'CASH':''}</strong></p>
                    </div>

                </div>
                {children}
                {isChronic ? <div className="col-md-4 myclass">
                    <div>
                        <div className="collapse-container">
                            <ItemIcon onClick={handleOpen}>
                            {open ? <ExpandMoreIcon style={{fontSize:'1.1rem', fontWeight:'400'}}/>: <NavigateNextIcon style={{fontSize:'1.1rem', fontWeight:'400'}}/>}
                      </ItemIcon>
                      <span style={{fontSize:'14px',fontWeight:700,paddingLeft:'6px',paddingRight:'6px'}}>Chronic Illness</span>
                    </div>
                   
          
                  <Collapse in={open} timeout="auto" name="Chronic Illness" unmountOnExit>
                  <Row>
                      <Col sm={6}>
                        <div >
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Cardiac Disease"
                              checked={patient?.chronic_illness?.cardiac_disease}
                              name="cardiac_disease"
                  
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Sickle Cell Disease"
                              checked={patient?.chronic_illness?.sickle_cell_disease}
                              name="sickle_cell_disease"
                              
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Kidney Disease"
                              checked={patient?.chronic_illness?.kidney_disease}
                              name="kidney_disease"
                            
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Epilepsy (seizures)"
                              checked={patient?.chronic_illness?.epilepsy}
                              name="epilepsy"
                            
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Hypertension"
                              checked={patient?.chronic_illness?.hypertension}
                              name="hypertension"
                            
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Diabetes"
                              checked={patient?.chronic_illness?.diabetes}
                              name="diabetes"
                            
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="TB"
                              checked={patient?.chronic_illness?.tb}
                              name="tb"
                              
                            />
                          </Col>
                          {patient?.chronic_illness?.other_chronic_illness ?
                          <Col>
                            <Label
                              name="Others"
                              className="label-admission-prescription"
                            />
                            <Col>
                            
                            <p>Other Chronic illness: <strong>{patient?.chronic_illness?.other_chronic_illness}</strong>
                              </p>
                                    
                            </Col>
                          </Col>
                            : null    } 
                        </div>
                      </Col>
                    </Row>
                  </Collapse> 
                </div> 
                <div>
                  <div className="collapse-container">
                    <ItemIcon onClick={handleOpenDialog}>
                        {openDialog ? <ExpandMoreIcon style={{fontSize:'1.1rem', fontWeight:'400'}}/>: <NavigateNextIcon style={{fontSize:'1.1rem', fontWeight:'400'}} />}
                    </ItemIcon>         
                    <span style={{fontSize:'14px',fontWeight:700,paddingLeft:'6px',paddingRight:'6px'}}>Allergies</span>
                  </div>
                    {/* <CollapsibleList name="Allergies"  open={openDialog} handleOpen={()=>{setOpenDialog(!openDialog)}} >
                     */}
                  <Collapse in={openDialog} timeout="auto" name="Allergies" unmountOnExit>
                  <Col sm={6}>
                    <strong>
                      {!patient.allergies ? "" : patient?.allergies?.allergy}
                    </strong>
                  </Col>
                  </Collapse>
                  </div>
                </div>: null}
        </div>
        
    )
};


export const PatientDischargeInformation = ({patient_number, patient_admission_id}) => {
    const [patient, setPatient] = useState({})

    useEffect (() => {
        const formData = new FormData();
        formData.append('patient_number', patient_number)
        formData.append('patient_admission_id',patient_admission_id ? patient_admission_id : "");
        (async ()=>{
            try {
                const patientDetails = axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData);
                const admissionDetails = axios.post(`${config.smsUrl}/cadmission/retrieve_admission_details_by_id`, formData);
                const [patientResponse, admissionResponse] = await Promise.all([patientDetails, admissionDetails]);
                const patientData = patientResponse?.data;
                const admissionData = admissionResponse?.data;
                const dt = !admissionData.admission_list ? [] :admissionData.admission_list;
                const adm = !dt[0] ? {} : dt[0];
                setPatient({
                    ...patientDetails,
                    patient_name: `${!patientData.first_name ? "" : patientData.first_name} ${!patientData.last_name ? "" : patientData.last_name}`,
                    age: patientData.Age,
                    gender: patientData.gender,
                    address: patientData.address,
                    phone: patientData.phone_no,
                    admission_date:adm.admission_date ? adm.admission_date : '', ward: adm.ward_name ? adm.ward_name : '',
                    room: adm.room_name ? adm.room_name : '', bed_number:adm.bed_number ? adm.bed_number : '',
                })
            } catch (error) {
                // errorMessages(error, null, actions)
            }
        })()

    },[patient_number, patient_admission_id])


    const patientInfo = (
        <table
            css={[
                table,
                detailsTable,
                smallTable,
                paddingCell,
            ]}
        >
            <tbody>
            <tr>
                <td><span>Patient Number: </span> <span css={[textWeight]}>{patient_number}</span></td>
                <td><span>Admission Date: </span> <span css={[textWeight]}>{formatDateTime(patient.admission_date)}</span></td>

            </tr>
            <tr>
                <td><span>Patient Name: </span> <span css={[textWeight]}>{patient?.patient_name}</span> </td>
                <td><span>Ward: </span> <span css={[textWeight]}>{patient?.ward}</span> </td>
            </tr>
            <tr>
                <td><span>Gender: </span> <span css={[textWeight]}>{patient?.gender}</span> </td>
                <td><span>Room: </span> <span css={[textWeight]}>{patient?.room}</span> </td>
            </tr>
            <tr>
                <td><span>Age: </span> <span css={[textWeight]}>{patient.age}</span></td>
                <td><span>Bed:</span> <span css={[textWeight]}>{patient.bed_number}</span></td>
            </tr>
            <tr>
                <td><span>Contact: </span> <span css={[textWeight]}>{patient.phone_no}</span></td>
                <td><span>Address:</span> <span css={[textWeight]}>{patient.address}</span></td>
            </tr>
            </tbody>
        </table>

    )

    return (
        patientInfo

    )
};


