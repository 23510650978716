import React, { useEffect, useState } from "react";
import {
  CustomTablePagination,
  EnhancedSearchTableToolbar,
} from "../../Utils/Tables/CustomTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { Container, RightAlignedContainer } from "../../Utils/styledComponents";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { logoutErrorMessage } from "../../../Helpers/ErrorMessages";
import { LoadingGif } from "../../Utils/Loader";
import {
  CustomTableCell,
  MuiPaper,
  StickyHeadTable,
} from "../../Utils/Tables/StickyHeaderTable";
import PageTitle from "../../Utils/smsTitle";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import { CustomSelect } from "../../Utils/FormInputs/SelectInput";
import { DataExportButton } from "../../Utils/Buttons/DataExportationButton";
import {
  useExcelReports,
  usePdfDownload,
} from "../../Utils/ReusableComponents/useExcelExport";
import Grid from "@material-ui/core/Grid";
import {ExcelConsumptionData} from "./ExcelConsumptionData";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {useDebounce} from "../../Lab/useDebounce";

const headData = [
  { id: "count", numeric: false, disablePadding: false, label: "Sl" },
  {
    id: "product_name",
    numeric: false,
    disablePadding: false,
    label: "Product",
  },
  { id: "quantity_in", numeric: false, disablePadding: false, label: "Quantity In" },
  {
    id: "quantity_out",
    numeric: false,
    disablePadding: false,
    label: "Quantity Out",
  },
  // {
  //   id: "last_supplier_name",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Last Supplier",
  // },
  {
    id: "reorder_level",
    numeric: false,
    disablePadding: false,
    label: "Reorder Level",
  },
  {
    id: "available_stock",
    numeric: false,
    disablePadding: false,
    label: "Available Stock",
  },
];

const OutofStockReport = ({ actions, snackbars }) => {
  const departments = [
    { value: "1", label: "Store" },
    { value: "2", label: "Pharmacy" },
    { value: "3", label: "Laboratory" },
  ];
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState({
    stockList: [],
    loading: "idle",
    total: 0,
  });
  const [activePage, setActivePage] = useState(1);
  const [department, setDepartment] = useState({ value: 1, label: "Store" });
  const { loading, total, stockList } = data;
  const [openDialog, setOpenDialog] = useState(false)
  const [debouncedValue] = useDebounce(searchValue, 500)

  useEffect(() => {
    setActivePage(1);
    setPage(0);
  }, [department]);

  useEffect(() => {
    setData({ ...data, loading: "pending" });
    axios
      .post(`${config.smsUrl}/creport/out_of_stock/${activePage}`, null, {params:{search:debouncedValue, department_id:department?.value, per_page:rowsPerPage}})
      .then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        const arr = dt.out_of_stock ? dt.out_of_stock : [];
        const total_count = dt.total_count ? dt.total_count : 0;
        setData({
          ...data,
          loading: "success",
          stockList: arr,
          total: total_count,
        });
      })
      .catch((err) => {
        setData({ ...data, loading: "error" });
        logoutErrorMessage(err, null, actions);
      });
  }, [department, debouncedValue, activePage, rowsPerPage]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setActivePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setActivePage(1);
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const { openBar, type, message } = snackbars;

  const isLoading = loading === "pending";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  const pagination = (
    <CustomTablePagination
      colSpan={3}
      activePage={activePage}
      page={page}
      rowsPerPage={rowsPerPage}
      count={total}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isTotalCount
    />
  );

  const search_bar = (
    <EnhancedSearchTableToolbar term={searchValue} handler={handleSearch} />
  );
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, stockList.length - rowsPerPage);

  const file_name = `${department?.value} Out of Stock Report`;

  const department_name = {
    1: "store",
    2: "pharmacy",
    3: "lab",
  };

  const excelObj = {
    file_name: file_name,
    params: {
      department: department_name[department?.value],
      download_type: 2,
    },
    url: "creport/out_of_stock_pdf",
  };

  const pdfObj = {
    file_name: file_name,
    params: {
      department: department_name[department?.value],
      download_type: 1,
    },
    url: "creport/out_of_stock_pdf",
  };

  const { exportExcel, isLoading: isLoadingExcel } = useExcelReports(
    actions,
    excelObj
  );
  const { exportPdf, isLoading: isLoadingPdf } = usePdfDownload(
    actions,
    pdfObj
  );



  return (
    <div data-testid={`out-of-stock-stock-report`}>
      <PageTitle title="Out of stock report" />
      <SubHeader title="Reports" subTitle="Out of stock  stock report">
        <FontAwesomeIcon icon={faClipboardList} />
      </SubHeader>
      <CustomDialog open={openDialog} handleClose={()=>setOpenDialog(false)} title='Export consumption excel data'>
        <ExcelConsumptionData {...{actions, setOpenDialog}}/>
      </CustomDialog>
      <Container>
        <MainSnackbar
          variant={type}
          open={openBar}
          message={message}
          handleCloseBar={closeSnackbar}
        />
        <Grid container spacing={0}>
          <Grid item xs={12} md={5} lg={5}>
            <Form.Group as={Row}>
              <Col
                xs={12}
                sm={12}
                md={2}
                lg={2}
                xl={2}
                style={{ paddingLeft: 0 }}
              >
                <Label name="Department" />
              </Col>
              <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                <CustomSelect
                  value={department}
                  onChange={(value) => {
                    setDepartment(value)
                    setActivePage(1)
                    setPage(0)
                    setRowsPerPage(10)
                  }}
                  options={departments}
                />
              </Col>
            </Form.Group>
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <RightAlignedContainer>
              <div className="export-dropdown">
                <DataExportButton
                  isLoading={isLoadingExcel || isLoadingPdf}
                  title={file_name}
                  downLoadPdf={exportPdf}
                  exportCsv={exportExcel}
                />
              </div>

              <button
                onClick={()=>setOpenDialog(true)}
                disabled={isLoadingExcel || isLoadingPdf} style={{marginTop:'5px'}}
                className="btn sms-btn  small-btn  btn-sm"
              >
                Consumption Excel Data
              </button>
            </RightAlignedContainer>
          </Grid>
        </Grid>

        <MuiPaper>
          {search_bar}
          <StickyHeadTable
            {...{
              width: "100%",
              data: stockList,
              columns: headData.filter((item) => Boolean(item)),
              pagination,
              search_bar,
            }}
          >
            {isLoading ? (
              <TableRow>
                <TableCell align="center" {...{ colSpan: headData.length }}>
                  <LoadingGif />
                </TableCell>
              </TableRow>
            ) : null}
            {isSuccess ? (
              stockList.length > 0 ? (
                <>
                  {stockList.slice(0, rowsPerPage).map((row, index) => {
                    const count = (activePage - 1) * rowsPerPage + index + 1;
                    return (
                      <TableRow tabIndex={-1} key={index}>
                        {headData.map((col) =>
                          col.id === "count" ? (
                            <CustomTableCell>{count}</CustomTableCell>
                          ) : (
                            <CustomTableCell>{row[col.id]}</CustomTableCell>
                          )
                        )}
                      </TableRow>
                    );
                  })}
                  {/*{emptyRows > 0 && (*/}
                  {/*  <TableRow style={{ height: 5 * emptyRows }}>*/}
                  {/*    <TableCell colSpan={headData.length} />*/}
                  {/*  </TableRow>*/}
                  {/*)}*/}
                </>
              ) : (
                <TableRow>
                  <TableCell align="center" {...{ colSpan: headData.length }}>
                    No records found
                  </TableCell>
                </TableRow>
              )
            ) : null}
            {isError ? (
              <TableRow>
                <TableCell align="center" {...{ colSpan: headData.length }}>
                  The server did not return a valid response
                </TableCell>
              </TableRow>
            ) : null}
          </StickyHeadTable>
        </MuiPaper>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return { snackbars: state.snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OutofStockReport);
