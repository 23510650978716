/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import DatePicker from "react-datepicker";
import React from "react";

const timePicker = css`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  height: calc(1.6em + 0.75rem + 5px);
  border-radius: 0.7rem;
`

export function CustomTimePicker({time,handleChangeTime, ...props}) {
    console.log(time)
    return (
        <DatePicker
            selected={time}
            onChange={(date) => handleChangeTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            timeCaption="Time"
            dateFormat="h:mm aa"
            css={[timePicker]}
            placeholderText="--:--"
            {...props}
        />
    )

}

const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
};

export function CustomYearPicker({year, onChange, ...props}) {
        return (
            <DatePicker
                selected={year}
                onChange={yr=>onChange(yr)}
                renderYearContent={renderYearContent}
                showYearPicker
                dateFormat="yyyy"
                css={[timePicker]}
            />
        );

}