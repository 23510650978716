import React, {useEffect, useState} from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {CustomTablePagination, useHiddenColumns} from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../../Utils/smsTitle";
import {Link} from "react-router-dom";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {history} from "../../../Helpers/history";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import { formatDateTime} from "../../Utils/ConvertDate";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {ListPreloader, LoadingGif} from "../../Utils/Loader";
import { Container } from "../../DoctorsModule/doctorStyles";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useDebounce} from "../../Lab/useDebounce";
import {ViewMedicineReturn} from "../../MedicationandVisits/ViewMedicineReturn";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";



const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden: false},
    {id: 'admission_date', numeric: false, disablePadding: false, label: 'Admission Date', hidden: false},
    {id: 'patient_admission_id', numeric: false, disablePadding: false, label: 'Admission Id', hidden: false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden: false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden: false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden: false},
    {id: 'ward', numeric: false, disablePadding: false, label: 'Ward', hidden: false},
    {id: 'room', numeric: false, disablePadding: false, label: 'Room', hidden: false},
    {id: 'bed_number', numeric: false, disablePadding: false, label: 'Bed', hidden: false},
    {id: 'admitted_by', numeric: false, disablePadding: false, label: 'Admitted By', hidden: false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action', hidden: false},
];
 
const AdmissionList = ({actions, snackbars}) => {
    const [searchValue, setSearchValue] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [admission_list, setAdmissionList] = useState({});
    const [loading, setLoading] = useState('idle');
    const [patientTreatment, setPatientTreatment] = useState([])
    const [openTreatmentDialog, setOpenTreatmentDialog] = useState(false)
    const [debouncedValue] = useDebounce(searchValue,500)
    const [admission_id, setAdmissionId] = useState('')

    const obj = admission_list ? admission_list : {};
    const admissionList = !obj.admission_list ? [] : obj.admission_list;
    const total_count = !obj.total_count ? 0 : obj.total_count;

    useEffect(() => {
        const formData = new FormData();
        formData.append('search', debouncedValue);
        formData.append('per_page', rowsPerPage)
        setLoading('pending');
        axios.post(`${config.smsUrl}/cadmission/admission_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setAdmissionList(dt);
            setLoading('success')
        }).catch(err => {
            setLoading('error');
            logoutErrorMessage(err, null, actions)
        })
    }, [activePage, debouncedValue,  rowsPerPage]);


    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        setActivePage(1)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };


    const handleChangeNextPage = (value) => {
        setActivePage(value)
    };

    const handleDischargePatient = (item) => {
        axios.get(`${config.smsUrl}/cpharmacy/remaining-medicine/patient-admission/${item.patient_admission_id}`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const isReturned = dt.length > 0 && dt.every(item=>item?.medicine_return && item?.medicine_return?.status === 0)
            setPatientTreatment(dt);
            setAdmissionId(item.patient_admission_id)
            if (dt.length > 0 || isReturned){
                setOpenTreatmentDialog(true)
            }else {
                history.push(`/inpatientdischarge/${item.patient_number}/${item.visit_id}/${item.patient_admission_id}/nurse`)
            }
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells,
    } = useHiddenColumns(headData);



    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );


    const patient_admissions = admissionList.map((item, index)=>{
        return{
            ...item,
            count: ((activePage - 1) * rowsPerPage) + (index + 1),
            admission_date:item.admission_date ? formatDateTime(item.admission_date) : item.admission_date,
            patient_name:`${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`,
            admitted_by:`${!item.admitted_by_first_name ? "" : item.admitted_by_first_name} ${!item.admitted_by_last_name ? "" : item.admitted_by_last_name}`,
            action:(
                <>
                    <Link
                        to={{pathname: `/takeinpatientvitals/${item.patient_number}/${item.visit_id}/${item.patient_admission_id}`}}>
                        <button className="btn btn-sm sms-info-btn small-btn mr-1 mb-1">Triage</button>
                    </Link>
                    <Link
                        to={{pathname: `/previousvisits/${item.patient_number}/${item.visit_id}/2/${item.patient_admission_id}`}}>
                        <button className="btn btn-sm sms-btn small-btn mr-1 mb-1">Check up</button>
                    </Link>
                    <Link
                        to={{pathname: `/inpatienttreatment/${item.visit_id}/${item.patient_admission_id}/${item.patient_number}`}}>
                        <button className="btn btn-sm sms-amber-btn small-btn mr-1 mb-1">Treatment</button>
                    </Link>
                    <button onClick={()=>handleDischargePatient(item)}
                            className="btn btn-sm sms-gray-btn small-btn mr-1 mb-1">Discharge</button>
                </>
            )
        }
    })

    const {openBar, type, message} = snackbars;
    const components = [{label: 'Admitted Patients', path: `/patientroundlist`},
        {label: 'Discharged Patients', path: `/dischargelist`}];

    return (
        <div data-testid="admission-list" className='journals'>
            <PageTitle title="Admitted Patients"/>
            <SubHeader title="Medication and Rounds" subTitle="Admitted patients">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <CustomDialog open={openTreatmentDialog} handleClose={() => setOpenTreatmentDialog(false)} title='Return Remaining Medicine' maxWidth='sm'>
                <ViewMedicineReturn {...{actions, openDialog:openTreatmentDialog,patient_admission_id:admission_id,
                setOpenDialog:setOpenTreatmentDialog,treatment:patientTreatment, isDialog:true}}/>
            </CustomDialog>
            <Container>
                <div className="mui-tables">
                    <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                    {/* <div className="text-right">
                        <button onClick={handleRefresh} className="btn btn-sm sms-info-btn mb-1">Refresh</button>
                    </div> */}
                    <CustomTable title="Admitted Patients" customPage
                                handleChangeNextPage={handleChangeNextPage}
                                headData={headCells} handler={handleSearch} records={10} total_count={total_count}
                                activePage={activePage}  all_hidden={all_hidden} handleAllHeadCells={handleAllHeadCells} 
                                handleHeadCells={handleHeadCells}
                                 data={admissionList} colSpan={headData.length}  term={searchValue} pagination={pagination}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><LoadingGif/></TableCell>
                            </TableRow>:null}
                            {isSuccess ?  patient_admissions.length > 0 ? patient_admissions.slice(0, rowsPerPage)
                                .map((item, index) => {

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.patient_admission_id}
                                            className='mui-table-row '
                                        >
                                            {headCells.map((head, idx)=>(
                                                <TableCell  key={idx} hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow> : null }
                        </TableBody>
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionList);
